import { generateAllContactRoomFromExcludeMethods } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

export const useContactRoom = ({ contactRoomId }: { tenantId: string; contactRoomId: string }) => {
  const { currentUser } = useCurrentUser();
  // NOTE: 同一PCで別テナントにログインした際にキャッシュが残ってしまうので、tenantIdをキーにしている
  const keyObj = {
    endpoint: `/api/contact-room/get-contact-room`,
    tenantId: currentUser.tenantId,
    contactRoomId,
  } as const;

  return useSWR(keyObj, async ({ endpoint, contactRoomId }) => {
    const response = await apiClient.get(endpoint, { id: contactRoomId });

    return generateAllContactRoomFromExcludeMethods(response.data.contactRoom);
  });
};
