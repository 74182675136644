import { Box, ListItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography, UserIcon } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";

type NextNotice = "NextNotice";
type Props = ListRowProps & {
  employeesOrNotice: Array<Employee | NextNotice>;
  selectedEmployeeIds: string[];
  onSelect: (employee: Employee) => void;
};

export const CheckboxRowRenderer: FC<Props> = ({
  employeesOrNotice,
  selectedEmployeeIds,
  onSelect,
  ...props
}) => {
  const employeeOrNotice = employeesOrNotice[props.index] as (typeof employeesOrNotice)[number];

  if (employeeOrNotice === "NextNotice") {
    return (
      <Box
        key={props.key}
        style={props.style} // 動的に計算されるtopなどのプロパティが入る
      >
        <ListItem alignItems="flex-start">
          <CenterBox style={{ width: "100%", textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              これ以上の候補を表示できません。
              <br />
              検索条件をより具体的にしてください。
            </Typography>
          </CenterBox>
        </ListItem>
      </Box>
    );
  }

  const employee = employeeOrNotice;

  return (
    <Box
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
    >
      <ListItem alignItems="flex-start">
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={selectedEmployeeIds.includes(employee.id)}
              onChange={() => onSelect(employee)}
              name={employee.id}
              value={employee.id}
            />
          }
          label={
            <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
              <UserIcon
                username={employee.getName()}
                profileIconImageUrl={employee.profileIconImageUrl}
                size="extraSmall"
                circular
              />
              <Typography variant="caption" color="textSecondary" noWrap>
                {employee.getName()}
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
