import { z } from "zod";

import { PhaseSchema } from "../inputTypeSchemas/PhaseSchema";

/////////////////////////////////////////
// SPACE SCHEMA
/////////////////////////////////////////

export const SpaceSchema = z.object({
  phase: PhaseSchema,
  id: z.string(),
  name: z.string(),
  tenantId: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Space = z.infer<typeof SpaceSchema>;

/////////////////////////////////////////
// SPACE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const SpaceOptionalDefaultsSchema = SpaceSchema.merge(
  z.object({
    id: z.string().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type SpaceOptionalDefaults = z.infer<typeof SpaceOptionalDefaultsSchema>;

export default SpaceSchema;
