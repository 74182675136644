import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react";

import { EmployeeList } from "./parts";

import { IconButton, TextFieldDeprecated } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

/**
 * FIXME: emailsではなくidを渡すように修正する。中途向けの名残でSlackUserを特定するためにemailを使っていたという経緯がある
 */
type Props = {
  notAllowEmails: string[];
  selectedEmails: string[];
  onSelectEmails: (emails: string[]) => void;
  // 入社者の関係者を上部に固定するために渡す
  targetNewHireForFixingMemberOnTop?: Employee;
  excludeRoles?: Role[];
  isLoading: boolean;
};

const siftEmployees = (employees: Employee[], newHire?: Employee) => {
  if (!newHire) return employees;

  const generatedEmployees: Employee[] = [];
  // バディとサポートメンバーは先頭に移動させる
  employees.forEach((employee) => {
    if (newHire.getMentorAndSupportMemberIds().includes(employee.id)) {
      return generatedEmployees.unshift(employee);
    }
    return generatedEmployees.push(employee);
  });

  return generatedEmployees;
};

export const SelectMultipleAcceptanceEmployeesForm: FC<Props> = ({
  notAllowEmails,
  selectedEmails,
  onSelectEmails,
  targetNewHireForFixingMemberOnTop,
  excludeRoles,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState<string>();

  const { data: acceptanceEmployees } = useAcceptanceEmployees();

  const selectableEmployees = (acceptanceEmployees || []).filter(
    (v) => !excludeRoles?.includes(v.role)
  );

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();
  const displayEmployees = useMemo(
    () =>
      filterObjectByPartialMatch<Employee>({
        objects: selectableEmployees,
        searchText: searchText || "",
        getProperties: [
          (employee: Employee) => employee.getName(),
          (employee: Employee) => employee.email,
        ],
      }),
    [filterObjectByPartialMatch, searchText, selectableEmployees]
  );

  const changeSearchValue = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return (
    <>
      <Box p={"8px"}>
        <TextFieldDeprecated
          autoFocus
          fullWidth
          variant="standard"
          placeholder={"ユーザー名・メールアドレスで検索"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => changeSearchValue(e.target.value)}
          endAdornment={<IconButton size="sm" color="grey" icon="search" />}
        />
      </Box>
      <EmployeeList
        employees={siftEmployees(
          displayEmployees.filter((v) => !notAllowEmails.includes(v.email)),
          targetNewHireForFixingMemberOnTop
        )}
        selectedEmployees={selectableEmployees.filter((v) => selectedEmails.includes(v.email))}
        onSelect={(employees) => onSelectEmails(employees.map((v) => v.email))}
        isMultiple
        isLoading={isLoading}
      />
    </>
  );
};
