import { Box, FormControlLabel, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

import { useViewModelV2 } from "./hooks/useViewModelV2";

import {
  ContactRoomListItemV2,
  NewGraduatePCContactRoomItem,
} from "~/components/domains/contactRooms";

import {
  Badge,
  Checkbox,
  Icon,
  IconButton,
  Loading,
  TextFieldDeprecated,
  Tooltip,
  Typography,
} from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useCurrentUser } from "~/hooks/employee";
import { captureException } from "~/util";

export const NewGraduatePCContactMessages: FC = () => {
  const {
    currentContactRooms,
    isDisplayOnlyMyCandidates,
    contactRoomUnreadCountMap,
    selectedContactRoomId,
    tabs,
    isLoading,

    handleChangeIsDisplayOnlyMyCandidates,
    handleOnClickContactRoomListItem,
    isLoadingAllContactRooms,
    contactRoomIdToLatestMessageMap,
    isLoadingUnreadMessageCountList,
    contactRoomIdToPinnedFlagMap,

    keywordsString,
    keywordsStringSearchErrorText,
    onChangeKeywordsString,

    validConditionsCount,
    handleOnClickOpenNewGraduateSearchModal,
  } = useViewModelV2();

  return (
    <StyledContainerBox>
      <Box display="flex" height="100vh">
        <StyledBox width="360px" bgcolor="white" display="flex" flexDirection="column">
          <TabContext value={"message"}>
            <Box width="360px" pl="24px" pt="40px" pb="16px">
              <StyledTabList indicatorColor="primary" textColor="primary">
                {tabs.map((tab) => (
                  <StyledTab
                    key={tab.type}
                    label={
                      <Typography variant="body2" bold noWrap>
                        {tab.label}
                      </Typography>
                    }
                    value={tab.type}
                  />
                ))}
              </StyledTabList>
              <ContactRoomListController
                isLoadingAllContactRooms={isLoadingAllContactRooms}
                keywordsString={keywordsString}
                keywordsStringSearchErrorText={keywordsStringSearchErrorText || ""}
                onChangeKeywordsString={onChangeKeywordsString}
                validConditionsCount={validConditionsCount}
                handleOnClickOpenNewGraduateSearchModal={handleOnClickOpenNewGraduateSearchModal}
                isDisplayOnlyMyCandidates={isDisplayOnlyMyCandidates}
                handleChangeIsDisplayOnlyMyCandidates={handleChangeIsDisplayOnlyMyCandidates}
              />
            </Box>
            <Box height="100%">
              {isLoading ? (
                <Loading size={"small"} />
              ) : (
                <AutoSizer>
                  {(size) => (
                    <VirtualizedList
                      height={size.height}
                      width={size.width}
                      overscanRowCount={10} // 先にレンダリングしておくリストの数
                      rowCount={currentContactRooms.length}
                      rowHeight={80}
                      rowRenderer={(props) => {
                        const contactRoom = currentContactRooms[
                          props.index
                        ] as (typeof currentContactRooms)[number];
                        const isPinned = contactRoomIdToPinnedFlagMap.get(contactRoom.id);
                        const latestMessage = contactRoomIdToLatestMessageMap.get(contactRoom.id);

                        const unreadCount = contactRoomUnreadCountMap.get(contactRoom.id);
                        // NOTE: コンタクトルームは存在するのに、未読情報が存在しない場合はSentryで通知を行う
                        if (unreadCount === undefined && !isLoadingUnreadMessageCountList) {
                          captureException({
                            error: new Error("未読情報が存在していません"),
                            tags: {
                              type: "NewGraduatePCContactMessages",
                            },
                            extras: {
                              contactRoomId: contactRoom.id,
                              contactRoomUnreadCountMap,
                              contactRoomIdToUnreadCountMapSize: contactRoomUnreadCountMap.size,
                            },
                          });
                        }
                        return (
                          <Box
                            key={props.key}
                            style={props.style} // 動的に計算されるtopなどのプロパティが入る
                          >
                            <ContactRoomListItemV2
                              key={props.key}
                              isSelected={
                                !!selectedContactRoomId && contactRoom.id === selectedContactRoomId
                              }
                              onClickContactRoomListItem={() => {
                                handleOnClickContactRoomListItem(contactRoom.id);
                              }}
                              contactRoom={contactRoom}
                              unreadCount={unreadCount || 0}
                              latestMessage={latestMessage}
                              isPinned={isPinned}
                            />
                          </Box>
                        );
                      }}
                      noRowsRenderer={NoRowsRenderer}
                    />
                  )}
                </AutoSizer>
              )}
            </Box>
          </TabContext>
        </StyledBox>
        <Box width="100%">
          {selectedContactRoomId && (
            <NewGraduatePCContactRoomItem contactRoomId={selectedContactRoomId} />
          )}
        </Box>
      </Box>
    </StyledContainerBox>
  );
};

const ContactRoomListController: FC<{
  isLoadingAllContactRooms: boolean;
  keywordsString: string;
  keywordsStringSearchErrorText: string;
  onChangeKeywordsString: (value: string) => void;
  validConditionsCount: number;
  handleOnClickOpenNewGraduateSearchModal: () => void;
  isDisplayOnlyMyCandidates: boolean;
  handleChangeIsDisplayOnlyMyCandidates: () => void;
}> = ({
  isLoadingAllContactRooms,
  keywordsString,
  keywordsStringSearchErrorText,
  onChangeKeywordsString,
  validConditionsCount,
  handleOnClickOpenNewGraduateSearchModal,
  isDisplayOnlyMyCandidates,
  handleChangeIsDisplayOnlyMyCandidates,
}) => {
  const { currentUser } = useCurrentUser();

  return (
    <>
      {isLoadingAllContactRooms ? (
        <Box height="51px" pt="16px">
          <Loading size="small" />
        </Box>
      ) : (
        <Box mt="16px" display={"flex"} pr="8px">
          <StyledTextField
            autoFocus
            fullWidth
            variant="outlined"
            value={keywordsString}
            error={!!keywordsStringSearchErrorText}
            helperText={keywordsStringSearchErrorText}
            placeholder="氏名またはメールアドレス"
            onChange={(e) => onChangeKeywordsString(e.target.value)}
            endAdornment={<Icon size="ssm" color="grey" icon="search" />}
          />
          <Box ml="16px" display={"flex"} gridGap="8px" alignItems={"center"}>
            <StyledBadge
              color="primary"
              badgeContent={
                validConditionsCount ? (
                  <Typography bold variant="caption">
                    {validConditionsCount}
                  </Typography>
                ) : undefined
              }
            >
              <StyledIconButton
                icon="filter"
                size="md"
                color={validConditionsCount ? "primary" : "grey"}
                onClick={handleOnClickOpenNewGraduateSearchModal}
              />
            </StyledBadge>
          </Box>
          <Box ml="16px" display={"flex"} gridGap="8px" alignItems={"center"}>
            {currentUser.isAdmin() && <ButtonToBulkMessage />}
          </Box>
        </Box>
      )}
      {currentUser.isAdmin() && (
        <CheckBoxIsFilterMyCandidates
          isDisplayOnlyMyCandidates={isDisplayOnlyMyCandidates}
          handleChangeIsDisplayOnlyMyCandidates={handleChangeIsDisplayOnlyMyCandidates}
        />
      )}
    </>
  );
};

const ButtonToBulkMessage: FC = () => {
  return (
    <Box
      p="4px"
      height="32px"
      onClick={() => {
        window.open("/contact_rooms/contact_messages_orders/new", "_blank");
      }}
    >
      <Tooltip title="新規メッセージ作成">
        <StyledIcon icon="edit" size="md" color="primary" />
      </Tooltip>
    </Box>
  );
};

const CheckBoxIsFilterMyCandidates: FC<{
  isDisplayOnlyMyCandidates: boolean;
  handleChangeIsDisplayOnlyMyCandidates: () => void;
}> = ({ isDisplayOnlyMyCandidates, handleChangeIsDisplayOnlyMyCandidates }) => {
  return (
    <FormControlLabel
      style={{ margin: "16px 0px 0px" }}
      control={
        <Checkbox
          style={{ padding: 0, marginRight: "8px" }}
          checked={isDisplayOnlyMyCandidates}
          onChange={handleChangeIsDisplayOnlyMyCandidates}
        />
      }
      label={
        <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
          <Typography variant="body2" color="textSecondary" noWrap>
            担当候補者を絞り込む
          </Typography>
        </Box>
      }
    />
  );
};

const NoRowsRenderer = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="body1" align="center">
      {`該当するコンタクトはありません`}
    </Typography>
  </Box>
);

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: 100vh;
`;

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiOutlinedInput-root {
    height: 40px;
  }
  .MuiOutlinedInput-input {
    padding: 8px 12px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }
`;

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[2]};
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  .MuiTabs-flexContainer {
    display: inherit;
  }
  &.MuiTabs-root {
    min-height: 36px;
    font-size: 14px;
    padding-top: 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    min-height: 36px;
    margin-right: 40px;
    font-size: 14px;
    align-items: flex-start;
  }
  .MuiTypography-root {
    width: 100%;
    text-transform: none; // タブが自動で大文字になるのを防ぐ
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledBadge = styled(Badge)`
  & > .MuiBadge-badge {
    width: 19px;
    height: 13px;
    border-radius: 100vh;
  }
  & .MuiTypography-caption {
    font-size: 10px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 3px;
  }
`;
