import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";

import { Draggable, DropResult } from "react-beautiful-dnd";

import styled from "styled-components";

import { TextQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/TextQuestionFormUIMemo";
import { Action, State } from "../../_share_in_create_edit/reducer";

import { useCheckBoxQuestionFormHandler } from "../hooks/form/useCheckBoxQuestionFormHandler";
import { useFileQuestionForm } from "../hooks/form/useFileQuestionForm";
import { useQuestionFormCommonHandler } from "../hooks/form/useQuestionFormCommonHandler";
import { useRadioQuestionFormHandler } from "../hooks/form/useRadioQuestionFormHandler";
import { useTextQuestionFormHandler } from "../hooks/form/useTextQuestionFormHandler";
import { useDuplicationHandler } from "../hooks/useDuplicationHandler";

import { CheckBoxQuestionForm } from "./CheckBoxQuestionForm";
import { FileQuestionForm } from "./FileQuestionForm";
import { RadioQuestionForm } from "./RadioQuestionForm";

import { DnDDroppable, DnDProvider } from "~/components/uiParts";
import { useOnnFormTaskResultActionCondition } from "~/hooks/scenario/useOnnFormTaskResultActionCondition";

export const OnnFormTaskQuestions: FC<{
  onnFormTaskId: string;
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ onnFormTaskId, state, dispatch }) => {
  const { data: existingActionConditions = [] } = useOnnFormTaskResultActionCondition({
    onnTaskId: onnFormTaskId,
  });

  const onChangeExperiencesOrder = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      dispatch({
        type: "CHANGE_QUESTION_ORDER",
        payload: {
          questionId: (state.questions[sourceIndex] as (typeof state.questions)[number]).id,
          sourceIndex,
          destinationIndex,
        },
      });
    },
    [dispatch, state]
  );

  const { onChangeWordLimit, onClickIsWordLimit } = useTextQuestionFormHandler(dispatch);

  const {
    onChangeTitle,
    onChangeIsRequired,
    onChangeQuestionOptionType,
    onClickDelete,
    onChangeOptionOrder,
  } = useQuestionFormCommonHandler(dispatch);

  const {
    onClickDuplicationTextQuestion,
    onClickDuplicationFileQuestion,
    onClickDuplicationRadioQuestion,
    onClickDuplicationCheckBoxQuestion,
  } = useDuplicationHandler(dispatch);

  const { onChangeFile } = useFileQuestionForm(dispatch);

  const { onChangeRadioOptionLabel, onClickDeleteRadioOption, onClickAddRadioOption } =
    useRadioQuestionFormHandler(dispatch);

  const { onChangeCheckBoxOptionLabel, onClickDeleteCheckBoxOption, onClickAddCheckBoxOption } =
    useCheckBoxQuestionFormHandler(dispatch);

  return (
    <DnDProvider onDragEnd={onChangeExperiencesOrder}>
      <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
        <Box my="32px" display="flex" flexDirection="column" gridRowGap="32px">
          {state.questions.map((question, i) => {
            return (
              // NOTE: ドラッグ可能な範囲とドラッグするコンポートが異なるため、ライブラリの方を直接利用する
              <Draggable draggableId={question.id} index={i} key={question.id}>
                {(provided, snapshot) => (
                  <StyledDnDEffect
                    $isDragging={snapshot.isDragging}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    {question.type === "TEXT" && (
                      <TextQuestionFormUIMemo
                        isRequired={question.isRequired}
                        onChangeTitle={(e) =>
                          onChangeTitle({ questionId: question.id, title: e.currentTarget.value })
                        }
                        onChangeIsRequired={() =>
                          onChangeIsRequired({
                            questionId: question.id,
                            isRequired: !question.isRequired,
                          })
                        }
                        onChangeOptionType={(e) => {
                          onChangeQuestionOptionType({
                            questionId: question.id,
                            isRequired: question.isRequired,
                            questionTitle: question.title,
                            taskOptionType: e.target.value,
                            question: question,
                          });
                        }}
                        onClickDelete={() => onClickDelete({ questionId: question.id })}
                        onClickDuplication={() => onClickDuplicationTextQuestion(question)}
                        disabledOptionType={question.disabledOptionType}
                        questionDragHandleProps={provided.dragHandleProps}
                        titleTextField={{
                          value: question.title,
                          helperText: question.titleHelperText,
                          error: question.isTitleError,
                        }}
                        isWordLimit={question.isWordLimit}
                        wordLimit={question.wordLimit}
                        onChangeWordLimit={(limit) =>
                          onChangeWordLimit({ questionId: question.id, limit })
                        }
                        onClickIsWordLimit={() =>
                          onClickIsWordLimit({
                            questionId: question.id,
                            isWordLimit: question.isWordLimit,
                          })
                        }
                      />
                    )}
                    {question.type === "RADIO" && (
                      <RadioQuestionForm
                        question={question}
                        onChangeTitle={onChangeTitle}
                        onChangeIsRequired={onChangeIsRequired}
                        onClickDelete={onClickDelete}
                        onClickDeleteOption={onClickDeleteRadioOption}
                        onClickAddOption={onClickAddRadioOption}
                        onChangeRadioOptionLabel={onChangeRadioOptionLabel}
                        onChangeQuestionOptionType={onChangeQuestionOptionType}
                        onClickDuplication={onClickDuplicationRadioQuestion}
                        onChangeOptionOrder={onChangeOptionOrder}
                        disabledOptionType={question.disabledOptionType}
                        questionDragHandleProps={provided.dragHandleProps}
                        existingActionConditions={existingActionConditions}
                      />
                    )}
                    {question.type === "FILE" && (
                      <FileQuestionForm
                        onnFormTaskId={onnFormTaskId}
                        question={question}
                        onChangeTitle={onChangeTitle}
                        onChangeIsRequired={onChangeIsRequired}
                        onChangeQuestionOptionType={onChangeQuestionOptionType}
                        onClickDelete={onClickDelete}
                        onClickDuplicationFileQuestion={onClickDuplicationFileQuestion}
                        onChangeFile={onChangeFile}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                    {question.type === "CHECK_BOX" && (
                      <CheckBoxQuestionForm
                        question={question}
                        onChangeTitle={onChangeTitle}
                        onChangeIsRequired={onChangeIsRequired}
                        onClickDelete={onClickDelete}
                        onClickDeleteOption={onClickDeleteCheckBoxOption}
                        onClickAddOption={onClickAddCheckBoxOption}
                        onChangeCheckBoxOptionLabel={onChangeCheckBoxOptionLabel}
                        onChangeQuestionOptionType={onChangeQuestionOptionType}
                        onClickDuplication={onClickDuplicationCheckBoxQuestion}
                        onChangeOptionOrder={onChangeOptionOrder}
                        disabledOptionType={question.disabledOptionType}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                  </StyledDnDEffect>
                )}
              </Draggable>
            );
          })}
        </Box>
      </DnDDroppable>
    </DnDProvider>
  );
};

const StyledDnDEffect = styled.div<{ $isDragging: boolean }>`
  box-shadow: ${({ $isDragging }) => ($isDragging ? "0px 0px 10px #40404033" : "none")};
  background: #fff;
  border-radius: 8px;
`;
