import { ConditionField } from "@onn/common";
import { useState } from "react";

import { apiClient } from "~/libs";

// TODO: カスタムcsvDLの非同期化がリリース後に削除
export const useDownloadNewGraduatesCSV = () => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * idや条件をもとに複数newGraduateのCSVファイルをダウンロードする
   */
  const downloadNewGraduatesCSV = async ({
    employeeIds,
    conditionFields,
    scenarioIdSelections,
  }: {
    employeeIds: string[];
    conditionFields: ConditionField[];
    scenarioIdSelections: string[];
  }) => {
    setIsLoading(true);
    await apiClient.post(
      "/api/employee/get-new-graduates-csv",
      {
        employee_ids: employeeIds,
        condition_fields: conditionFields,
        scenario_id_selections: scenarioIdSelections,
      },
      {
        keepalive: false,
        fileDownload: {
          fileName: "候補者情報一覧.csv",
          isCSV: true,
        },
      }
    );
    setIsLoading(false);
  };

  return { downloadNewGraduatesCSV, isLoading };
};
