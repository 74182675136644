import { Box } from "@material-ui/core";
import { getHowLongAgo, NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { Icon, IconButton, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  isPinned: boolean;
  handleClickPin: () => void;
};

export const NewGraduateSummary: FC<Props> = ({ newGraduate, isPinned, handleClickPin }) => {
  const lastActiveTimeText = useMemo(
    () => (newGraduate.lastActiveTime ? getHowLongAgo(newGraduate.lastActiveTime) : "なし"),
    [newGraduate.lastActiveTime]
  );

  const iconType: "email" | "lineChat" | undefined = useMemo(() => {
    const displayType = newGraduate.getUserDisplayType();

    switch (displayType) {
      case "line":
        return "lineChat";
      case "email":
        return "email";
      case "isUnauthenticatedAndUnSynchronized":
        return undefined;
      default: {
        const _exhaustiveCheck: never = displayType;
        return _exhaustiveCheck;
      }
    }
  }, [newGraduate]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <UserIcon
        username={newGraduate.getName()}
        profileIconImageUrl={newGraduate.profileIconImageUrl || ""}
        size="semiMedium"
        backgroundColor={newGraduate.uid ? "primary" : "grey"}
      />
      <Box
        mt={1}
        gridGap={2}
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="center"
      >
        <Typography variant="body1" color="textPrimary" bold noWrap>
          {newGraduate.getName()}
        </Typography>
        <IconButton
          icon="pin"
          size="sm"
          color={isPinned ? "primary" : "lightGrey"}
          onClick={handleClickPin}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <StyledTypography variant="caption" noWrap disablePreWrap>
          最終アクセス：{lastActiveTimeText}
        </StyledTypography>
        {iconType && <StyledIcon icon={iconType} color="inherit" size="sm" />}
      </Box>
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    color: ${(props) => props.theme.palette.grey[300]};
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 4px;
`;
