import { useContext } from "react";

import {
  ContactContextForAdminSite,
  ContactContextForPortal,
} from "~/components/providers/ContactProvider";

/**
 * 管理画面でコンタクトルームのコンテキストを取得するhooks
 */
export const useContactContextForAdminSite = () => {
  const contactContext = useContext(ContactContextForAdminSite);

  return {
    ...contactContext,
    totalNotificationCount: contactContext.totalUnreadCount,
  };
};

/**
 * ポータルで候補者のコンタクトルームのコンテキストを取得するhooks
 */
export const useContactContextForPortal = () => {
  const contactContext = useContext(ContactContextForPortal);

  return contactContext;
};
