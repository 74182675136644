import { z } from "zod";

import {
  flatConditionFields,
  recruitmentProcessConditionFields,
  recruitmentProcessRelatedOnnEventFields,
  recruitmentStatusConditionFields,
} from "./conditionFields";

export const recruitmentProcessConditionFieldSchema = z.array(
  z.union([
    z.enum(recruitmentProcessConditionFields),
    z.array(z.enum(recruitmentProcessRelatedOnnEventFields)),
  ])
);

// TODO: このスキーマは、newGraduatesCSVOutputConditionドメインにあった方が適切なので移動する
// nonempty()を使ったスキーマから方を導出すると、空配列を禁止する厳しすぎる型になる。
// 型導出用とバリデーションスキーマで分けている
export const csvOutputCondition = z.object({
  employee_ids: z.array(z.string()),
  condition_fields: z.array(
    z.union([
      z.enum(flatConditionFields),
      z.string().regex(/^custom_employee_information_field\/.+$/),
      z.object({ recruitment_status: z.array(z.enum(recruitmentStatusConditionFields)) }),
      z.object({
        recruitment_process: recruitmentProcessConditionFieldSchema,
      }),
    ])
  ),
  scenario_id_selections: z.array(z.string()),
});

export const getNewGraduatesCSVSchema = z.object({
  body: z.object({
    employee_ids: csvOutputCondition.shape.employee_ids.nonempty(),
    /**
     * 出力するCSVの項目条件を指定する
     * - 配列の順序で、CSVの各列の順序を指定する
     */
    condition_fields: csvOutputCondition.shape.condition_fields.nonempty(),
    scenario_id_selections: csvOutputCondition.shape.scenario_id_selections,
  }),
});
