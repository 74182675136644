import { Employee, NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { useCallback, useMemo } from "react";

import { NewGraduateSelectionForm } from "~/components/domains/employees";
import { Typography } from "~/components/uiParts";
import { FormWithTitlePaper } from "~/components/uiParts/FormWithTitlePaper";

import { useModal } from "~/hooks/modal";

type Props = {
  selectedEmployees: Employee[];
  selectEmployees: (newEmployee: Employee[]) => void;
  removeEmployee: (employeeId: string) => void;
  errorText?: string;
};

export const ReceiverForm = ({
  selectedEmployees,
  selectEmployees,
  removeEmployee,
  errorText,
}: Props): JSX.Element => {
  const { handleModal } = useModal();
  const selectedEmployeeIdSet = useMemo(
    () => new Set(selectedEmployees.map((e) => e.id)),
    [selectedEmployees]
  );

  const openAddNewGraduateWithCSVModal = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: selectedEmployees,
        registeredEmployeeIds: [],
        onSubmit: (employees: Employee[]) => selectEmployees(employees),
        canUseNotRegistered: true,
      },
    });
  }, [handleModal, selectedEmployees, selectEmployees]);

  const openAddNewGraduateModal = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        filter: (employee: NewGraduateToDisplayForAdmin) => {
          if (!employee.canUseNotificationFeatures()) return false;
          if (selectedEmployeeIdSet.has(employee.id)) return false;

          return true;
        },
        onSubmit: (employees: Employee[]) => selectEmployees(employees),
        openCsvUploadModal: openAddNewGraduateWithCSVModal,
      },
    });
  }, [handleModal, openAddNewGraduateWithCSVModal, selectEmployees, selectedEmployeeIdSet]);

  return (
    <FormWithTitlePaper
      title={`配信対象(${selectedEmployees.length})`}
      rowGap={24}
      form={
        <>
          <NewGraduateSelectionForm
            employees={selectedEmployees}
            openAddNewGraduateModal={openAddNewGraduateModal}
            openAddNewGraduateWithCSVModal={openAddNewGraduateWithCSVModal}
            onClickDeleteButton={(e) => removeEmployee(e.id)}
          />
          {errorText && (
            <Typography variant="caption" color="error">
              {errorText}
            </Typography>
          )}
        </>
      }
    />
  );
};
