import { Box, Stack } from "@mui/material";
import { BusinessHoursSetting } from "@onn/common";
import { xor } from "lodash";
import React, { FC, useCallback } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

import { DayOfWeek } from "../../../../../../function/src/.prisma/client";

import { Paper, Typography } from "~/components/uiParts";
import { DaysOfWeeksMenuItems } from "~/components/uiParts/DaysOfWeeksMenuItems";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";
import { getTimeMenuItems } from "~/util/getTimeMenuItems";

export type BusinessHoursSettingsEditorStatInputState = {
  daysOfWeeks: BusinessHoursSetting["daysOfWeeks"];
  fromDate: string;
  untilDate: string;
};

const timeMenuItems = getTimeMenuItems("30_MINUTE");
const fromDateMenuItems = timeMenuItems.slice(0, -1); // untilが23:30までなので23:30を削除している
const untilDateMenuItems = timeMenuItems.slice(1); // untilなので0:00を削除している

type Props = {
  form: UseFormReturn<BusinessHoursSettingsEditorStatInputState>;
};

export const BusinessHoursSettingsEditor: FC<Props> = ({ form }) => {
  const { daysOfWeeks } = form.watch();
  const { control, formState } = form;

  const handleClickDaysOfWeeksItems = useCallback(
    (daysOfWeek: DayOfWeek) => {
      form.setValue("daysOfWeeks", xor(daysOfWeeks, [daysOfWeek]), { shouldValidate: true });
    },
    [daysOfWeeks, form]
  );

  return (
    <StyledPaper>
      <Stack rowGap="24px">
        <Stack rowGap="16px">
          <Typography variant="body2" bold>
            曜日
          </Typography>
          <DaysOfWeeksMenuItems onClick={handleClickDaysOfWeeksItems} values={daysOfWeeks} />
          {formState.errors.daysOfWeeks && (
            <Typography variant="caption" color="error">
              {formState.errors.daysOfWeeks.message}
            </Typography>
          )}
        </Stack>
        <Stack rowGap="16px">
          <Typography variant="body2" bold>
            営業時間帯
          </Typography>
          <Box display="flex" columnGap="8px" alignItems="start">
            <Controller
              name="fromDate"
              control={control}
              render={({ field, fieldState }) => (
                <StyledSelectForm
                  icon="clock"
                  menuItems={fromDateMenuItems}
                  selected={field.value}
                  onChange={field.onChange}
                  errorText={fieldState.error?.message}
                />
              )}
            />
            <Box pt="8px">
              <Typography color="textSecondary">〜</Typography>
            </Box>
            <Controller
              name="untilDate"
              control={control}
              render={({ field, fieldState }) => (
                <StyledSelectForm
                  icon="clock"
                  menuItems={untilDateMenuItems}
                  selected={field.value}
                  onChange={field.onChange}
                  errorText={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    border-radius: 8px;
    width: 100%;
    padding: 16px 24px;
    box-shadow: none;
    ${(props) => `border: solid 1px ${props.theme.palette.grey[100]};`}
  }
`;

const StyledSelectForm = styled(SelectFormV2)`
  .MuiInputBase-formControl {
    min-width: 142px;
    height: 40px;
  }
`;
