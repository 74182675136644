import {
  onnEventEvaluationFieldAccessControlTypeSchema,
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  onnEventEvaluationRanksInputSchema,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import { z } from "zod";

const evaluationBaseFieldSchema = z.object({
  id: z.string(),
  label: z.string().min(1, { message: "入力してください" }),
  accessControlType: onnEventEvaluationFieldAccessControlTypeSchema,
  isSavedField: z.boolean(),
});

export const evaluationTextFieldSchema = z
  .object({
    type: z.literal(OnnEventEvaluationTextField.type),
    hasInputTemplate: z.boolean(),
    inputTemplate: z.string().optional(),
  })
  .merge(evaluationBaseFieldSchema);

const evaluationFileFieldSchema = z
  .object({
    type: z.literal(OnnEventEvaluationFileField.type),
  })
  .merge(evaluationBaseFieldSchema);

const evaluationSingleTypeFieldSchema = z
  .object({
    type: z.literal(OnnEventEvaluationSingleSelectField.type),
    options: z.array(
      // NOTE: useFieldArray使用時に、keyNameを指定し忘れるとidが上書きされて予期せぬバグに繋がるので、idではなくoptionIdを使用する
      // lint等でミスを起こさないような仕組みを入れたい
      z.object({
        optionId: z.string(),
        label: z.string().min(1, { message: "入力してください" }),
      })
    ),
  })
  .merge(evaluationBaseFieldSchema);

const evaluationMultipleTypeFieldSchema = z
  .object({
    type: z.literal(OnnEventEvaluationMultipleSelectField.type),
    options: z.array(
      // NOTE: useFieldArray使用時に、keyNameを指定し忘れるとidが上書きされて予期せぬバグに繋がるので、idではなくoptionIdを使用する
      // lint等でミスを起こさないような仕組みを入れたい
      z.object({
        optionId: z.string(),
        label: z.string().min(1, { message: "入力してください" }),
      })
    ),
  })
  .merge(evaluationBaseFieldSchema);

export const generateFormSchema = () => {
  const fieldSchema = z.discriminatedUnion("type", [
    evaluationTextFieldSchema,
    evaluationFileFieldSchema,
    evaluationSingleTypeFieldSchema,
    evaluationMultipleTypeFieldSchema,
  ]);

  return z.object({
    evaluationRanks: onnEventEvaluationRanksInputSchema,
    evaluationFields: z.array(fieldSchema),
  });
};
