import { Employee } from "./Employee";

/**
 * @deprecated この型は廃止予定です。代わりに ./NewGraduateToDisplayForAdmin を使ってください。
 */
export type EmployeeToDisplay = Employee & {
  employeeTagIds: string[];
};

/**
 * @deprecated plainToInstanceは廃止予定です。代わりに ./NewGraduateToDisplayForAdmin を使ってください。
 */
export const plainToInstance = (
  // delete を使うために Optional にしている
  plain: Optional<ExcludeMethods<EmployeeToDisplay>, "employeeTagIds">
): EmployeeToDisplay => {
  const employeeTagIds = plain.employeeTagIds ?? [];

  delete plain.employeeTagIds;

  return Object.assign(Employee.plainToInstance(plain), { employeeTagIds });
};
