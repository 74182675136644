import { ContactRoom } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = ({ tenantId, requestUserId }: { tenantId: string; requestUserId: string }) => {
  return {
    path: "/api/portal/contact-rooms",
    tenantId,
    requestUserId,
  } as const;
};

export const useContactRoomForPortal = () => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      requestUserId: currentUser.id,
    }),
    async ({ path }) => {
      const response = await apiClient.get(path);

      return response.data ? new ContactRoom(response.data) : null;
    }
  );
};
