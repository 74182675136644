import { OnnTaskTypes } from "@onn/common";
import { TaskOptionTypesKeys } from "@onn/common/domain/OnnTask";

import {
  CHECK_BOX_QUESTION_FOR_FORM,
  FIlE_QUESTION_FOR_FORM,
  QuestionForState,
  RADIO_QUESTION_FOR_FORM,
  TEXT_QUESTION_FOR_FORM,
} from "../types";

export type State = {
  selectedOnnTaskTypes: keyof typeof OnnTaskTypes;
  questions: QuestionForState[];
  isAllowEditAnswer: boolean;
  onnTaskTitleTextField: {
    value: string;
    error: boolean;
    helperText: string;
    isDirty: boolean;
  };
  onnTaskDescriptionTextField: {
    value: string;
    error: boolean;
    helperText: string;
    isDirty: boolean;
  };
};

export type OnnTaskOverView =
  | {
      type: "CHANGE_ONN_TASK_TYPE";
      payload: {
        onnTaskTypes: keyof typeof OnnTaskTypes;
      };
    }
  | {
      type: "TOGGLE_IS_ALLOW_ANSWERED_ONCE";
    }
  | {
      type: "CHANGE_ONN_TASK_TITLE";
      payload: {
        onnTaskTitle: string;
        error: boolean;
        helperText: string;
      };
    }
  | {
      type: "CHANGE_ONN_TASK_DESCRIPTION";
      payload: {
        onnTaskDescription: string;
        error: boolean;
        helperText: string;
      };
    };

type FileQuestionAction = {
  type: "ADD_ATTACHMENT_FILE_TO_FILE_QUESTION";
  payload: {
    questionId: string;
    sampleFile: File;
    sampleFilePath: string;
    sampleFilePathForPreview: string;
  };
};

type RadioQuestionAction =
  | {
      type: "RADIO_QUESTION_ADD_OPTION";
      payload: {
        questionId: string;
        optionId: string;
      };
    }
  | {
      type: "RADIO_QUESTION_DELETE_OPTION";
      payload: {
        questionId: string;
        optionId: string;
      };
    }
  | {
      type: "RADIO_QUESTION_UPDATE_OPTION";
      payload: {
        questionId: string;
        optionId: string;
        text: string;
      };
    };

type CheckBoxQuestionAction =
  | {
      type: "CHECKBOX_QUESTION_ADD_OPTION";
      payload: {
        questionId: string;
        optionId: string;
      };
    }
  | {
      type: "CHECKBOX_QUESTION_UPDATE_OPTION";
      payload: {
        questionId: string;
        optionId: string;
        text: string;
      };
    }
  | {
      type: "CHECKBOX_QUESTION_DELETE_OPTION";
      payload: {
        questionId: string;
        optionId: string;
      };
    };

type TextQuestionAction =
  | {
      type: "TEXT_QUESTION_UPDATE_WORD_LIMIT";
      payload: {
        questionId: string;
        wordLimit: string;
      };
    }
  | {
      type: "TEXT_QUESTION_UPDATE_IS_WORD_LIMIT";
      payload: {
        questionId: string;
        isWordLimit: boolean;
      };
    };

export type Action =
  | {
      type: "ADD_QUESTION";
      payload: {
        questionId: string;
      };
    }
  | OnnTaskOverView
  | {
      type: "CHANGE_QUESTION_ORDER";
      payload: {
        questionId: string;
        sourceIndex: number;
        destinationIndex: number;
      };
    }
  | {
      type: "CHANGE_ONN_TASK_REQUIRED";
      payload: {
        questionId: string;
        isRequired: boolean;
      };
    }
  | {
      type: "DELETE_QUESTION";
      payload: {
        questionId: string;
      };
    }
  | {
      type: "DUPLICATION_QUESTION";
      payload: {
        question: QuestionForState;
        fromQuestionId: string;
      };
    }
  | {
      type: "CHANGE_QUESTION_OPTION_TYPE";
      payload: {
        questionId: string;
        taskOptionType: TaskOptionTypesKeys;
        question: QuestionForState;
      };
    }
  | {
      type: "CHANGE_QUESTION_TITLE";
      payload: {
        questionId: string;
        title: string;
        isError: boolean;
        helperText: string;
      };
    }
  | {
      type: "CHANGE_OPTION_ORDER";
      payload: {
        questionId: string;
        sourceIndex: number;
        destinationIndex: number;
      };
    }
  | RadioQuestionAction
  | CheckBoxQuestionAction
  | FileQuestionAction
  | TextQuestionAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_QUESTION":
      return {
        ...state,
        questions: [
          ...state.questions,
          {
            id: action.payload.questionId,
            type: "TEXT",
            title: "",
            isRequired: true,
            disabledOptionType: false,
            isTitleError: false,
            titleHelperText: "",
            isDirtyTitle: false,
            isWordLimit: false,
          },
        ],
      };
    case "CHANGE_QUESTION_ORDER":
      return {
        ...state,
        questions: (() => {
          const newOrderQuestions = [...state.questions];
          newOrderQuestions.splice(action.payload.sourceIndex, 1);
          newOrderQuestions.splice(
            action.payload.destinationIndex,
            0,
            state.questions[action.payload.sourceIndex] as (typeof state.questions)[number]
          );
          return newOrderQuestions;
        })(),
      };
    case "CHANGE_ONN_TASK_TYPE":
      return { ...state, selectedOnnTaskTypes: action.payload.onnTaskTypes };
    case "TOGGLE_IS_ALLOW_ANSWERED_ONCE":
      return { ...state, isAllowEditAnswer: !state.isAllowEditAnswer };
    // タスクのタイトルを更新
    case "CHANGE_ONN_TASK_TITLE":
      return {
        ...state,
        onnTaskTitleTextField: {
          ...state.onnTaskTitleTextField,
          value: action.payload.onnTaskTitle,
          error: action.payload.error,
          helperText: action.payload.helperText,
          // 一度でも変更されたらisDirtyをtrueにする
          isDirty: true,
        },
      };
    case "CHANGE_ONN_TASK_DESCRIPTION":
      return {
        ...state,
        onnTaskDescriptionTextField: {
          ...state.onnTaskDescriptionTextField,
          value: action.payload.onnTaskDescription,
          error: action.payload.error,
          helperText: action.payload.helperText,
          // 一度でも変更されたらisDirtyをtrueにする
          isDirty: true,
        },
      };
    // 設問のタイプ変更
    case "CHANGE_QUESTION_OPTION_TYPE":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId) {
            const question = {
              ...action.payload.question,
              id: action.payload.questionId,
              type: action.payload.taskOptionType,
            } as QuestionForState;
            return question;
          }
          return q;
        }),
      };

    // 設問のタイトル変更
    case "CHANGE_QUESTION_TITLE":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId) {
            return {
              ...q,
              title: action.payload.title,
              isTitleError: action.payload.isError,
              titleHelperText: action.payload.helperText,
              // 一度でも変更されたらisDirtyをtrueにする
              isDirtyTitle: true,
            };
          }
          return q;
        }),
      };

    // 単一選択設問：選択肢追加
    case "RADIO_QUESTION_ADD_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "RADIO") {
            const currentQuestion = q as RADIO_QUESTION_FOR_FORM;
            const question: RADIO_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: [...currentQuestion.options, { id: action.payload.optionId, text: "" }],
            };
            return question;
          }
          return q;
        }),
      };

    // 単一選択設問：選択肢削除
    case "RADIO_QUESTION_DELETE_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "RADIO") {
            const currentQuestion = q as RADIO_QUESTION_FOR_FORM;
            const question: RADIO_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: currentQuestion.options.filter((o) => o.id !== action.payload.optionId),
            };
            return question;
          }
          return q;
        }),
      };

    // 単一選択設問：選択肢更新
    case "RADIO_QUESTION_UPDATE_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "RADIO") {
            const currentQuestion = q as RADIO_QUESTION_FOR_FORM;
            const question: RADIO_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: currentQuestion.options.map((o) => {
                if (o.id === action.payload.optionId) {
                  return { ...o, text: action.payload.text };
                }
                return o;
              }),
            };
            return question;
          }
          return q;
        }),
      };

    // 複数選択設問：選択肢追加
    case "CHECKBOX_QUESTION_ADD_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "CHECK_BOX") {
            const currentQuestion = q as CHECK_BOX_QUESTION_FOR_FORM;
            const question: CHECK_BOX_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: [...currentQuestion.options, { id: action.payload.optionId, text: "" }],
            };
            return question;
          }
          return q;
        }),
      };
    // 複数選択設問：選択肢削除
    case "CHECKBOX_QUESTION_DELETE_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "CHECK_BOX") {
            const currentQuestion = q as CHECK_BOX_QUESTION_FOR_FORM;
            const question: CHECK_BOX_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: currentQuestion.options.filter((o) => o.id !== action.payload.optionId),
            };
            return question;
          }
          return q;
        }),
      };

    // 複数選択設問：選択肢追加
    case "CHECKBOX_QUESTION_UPDATE_OPTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "CHECK_BOX") {
            const currentQuestion = q as CHECK_BOX_QUESTION_FOR_FORM;
            const question: CHECK_BOX_QUESTION_FOR_FORM = {
              ...currentQuestion,
              options: currentQuestion.options.map((o) => {
                if (o.id === action.payload.optionId) {
                  return { ...o, text: action.payload.text };
                }
                return o;
              }),
            };
            return question;
          }
          return q;
        }),
      };
    // ファイル設問：サンプルファイルの追加
    case "ADD_ATTACHMENT_FILE_TO_FILE_QUESTION":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "FILE") {
            const question = {
              ...q,
              sampleFile: action.payload.sampleFile,
              sampleFilePath: action.payload.sampleFilePath,
              sampleFilePathForPreview: action.payload.sampleFilePathForPreview,
            } as FIlE_QUESTION_FOR_FORM;
            return question;
          }
          return q;
        }),
      };
    // 設問の必須/任意の切り替え
    case "CHANGE_ONN_TASK_REQUIRED":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId) {
            return { ...q, isRequired: action.payload.isRequired };
          }
          return q;
        }),
      };
    // 設問の削除
    case "DELETE_QUESTION":
      return {
        ...state,
        questions: state.questions.filter((q) => q.id !== action.payload.questionId),
      };
    // 設問の複製
    case "DUPLICATION_QUESTION":
      return {
        ...state,
        questions: state.questions.flatMap((question) => {
          // NOTE: 複製する場合は、複製元の設問の下に複製した設問を追加するためflatMapを使用して複製した設問を差し込んでいる
          if (question.id !== action.payload.fromQuestionId) return question;
          return [question, action.payload.question];
        }),
      };

    // 設問の選択肢入れ替え
    case "CHANGE_OPTION_ORDER":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (
            q.id === action.payload.questionId &&
            (q.type === "CHECK_BOX" || q.type === "RADIO")
          ) {
            const newOrderOptions = [...q.options];
            newOrderOptions.splice(action.payload.sourceIndex, 1);
            newOrderOptions.splice(
              action.payload.destinationIndex,
              0,
              q.options[action.payload.sourceIndex] as (typeof newOrderOptions)[number]
            );

            return { ...q, options: newOrderOptions };
          } else {
            return q;
          }
        }),
      };
    case "TEXT_QUESTION_UPDATE_WORD_LIMIT":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "TEXT") {
            const currentQuestion = q as TEXT_QUESTION_FOR_FORM;
            const question: TEXT_QUESTION_FOR_FORM = {
              ...currentQuestion,
              wordLimit: action.payload.wordLimit,
            };
            return question;
          }
          return q;
        }),
      };
    case "TEXT_QUESTION_UPDATE_IS_WORD_LIMIT":
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.id === action.payload.questionId && q.type === "TEXT") {
            const currentQuestion = q as TEXT_QUESTION_FOR_FORM;
            const question: TEXT_QUESTION_FOR_FORM = {
              ...currentQuestion,
              isWordLimit: action.payload.isWordLimit,
            };
            return question;
          }
          return q;
        }),
      };
  }
};
