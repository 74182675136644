import { useEffect, useMemo } from "react";

import { useFilterAndSortContactRooms } from "./filter/useFilterAndSortContactRooms";

import { useLatestContactMessage } from "~/hooks/contactMessage";
import { useContactContextForAdminSite } from "~/hooks/contactMessage/useContactContext";
import { useMutateAllLatestContactMessageByContactRoomId } from "~/hooks/contactMessage/useLatestContactMessage/useLatestContactMessage";
import { useContactRoomsWithLimit } from "~/hooks/contactRoom/useContactRoomsWithLimit";
import { useCurrentUser } from "~/hooks/employee";

const useShowContactRooms = () => {
  const {
    // 自分自身が対象となっているルームは取得しない
    // 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ
    contactRoomsWithoutCurrentUser: _allContactRooms,
    isLoadingContactRooms: isLoadingAllContactRooms,
  } = useContactContextForAdminSite();

  const allContactRooms = useMemo(() => _allContactRooms, [_allContactRooms]);
  const { data: limitContactRooms, isLoading: isLoadingContactRooms } = useContactRoomsWithLimit();

  // 初回表示を速くするために、最初は100件分のコンタクトルームを表示する。全件のコンタクトルームが表示された後は切り替える。
  const showContactRooms = useMemo(() => {
    if (!isLoadingAllContactRooms) return allContactRooms;
    if (limitContactRooms) return limitContactRooms;
    return [];
  }, [allContactRooms, limitContactRooms, isLoadingAllContactRooms]);

  return { showContactRooms, isLoadingContactRooms, isLoadingAllContactRooms };
};

const useShowLatestContactMessages = () => {
  const { currentUser } = useCurrentUser();

  const { data: allLatestContactMessages = [], isLoading: isLoadingAllLatestMessages } =
    useLatestContactMessage({
      tenantId: currentUser.tenantId,
    });
  const { mutateByContactRoomId } = useMutateAllLatestContactMessageByContactRoomId({
    tenantId: currentUser.tenantId,
  });

  const { data: limitLatestContactMessages = [], isLoading: isLoadingLatestMessages } =
    useLatestContactMessage({
      tenantId: currentUser.tenantId,
      limit: 100,
    });

  // 初回表示を速くするために、最初は100件分のコンタクトルームを表示する。全件のコンタクトルームが表示された後は切り替える。
  const showLatestContactMessages = useMemo(() => {
    if (!isLoadingAllLatestMessages) return allLatestContactMessages;
    if (limitLatestContactMessages) return limitLatestContactMessages;
    return [];
  }, [allLatestContactMessages, isLoadingAllLatestMessages, limitLatestContactMessages]);

  return { isLoadingLatestMessages, showLatestContactMessages, mutateByContactRoomId };
};

export const useContactRoomListV2 = ({
  searchedNewGraduateIds,
  isDisplayOnlyMyCandidates,
}: {
  searchedNewGraduateIds: Set<string>;
  isDisplayOnlyMyCandidates: boolean;
}) => {
  const { currentUser } = useCurrentUser();

  const { showContactRooms, isLoadingContactRooms, isLoadingAllContactRooms } =
    useShowContactRooms();

  const { contactRoomUnreadCountMap, isLoadingUnreadMessageCountList } =
    useContactContextForAdminSite();

  const { showLatestContactMessages, isLoadingLatestMessages, mutateByContactRoomId } =
    useShowLatestContactMessages();

  // 最新メッセージのコンタクトルームごとの更新処理を listener に登録する
  const { registerOnChangeUnreadCountInfoHandler } = useContactContextForAdminSite();
  useEffect(() => {
    registerOnChangeUnreadCountInfoHandler(mutateByContactRoomId);

    return () => {
      registerOnChangeUnreadCountInfoHandler(undefined);
    };
  }, [registerOnChangeUnreadCountInfoHandler, mutateByContactRoomId]);

  const { currentContactRooms } = useFilterAndSortContactRooms({
    contactRooms: showContactRooms,
    currentUser,
    isDisplayOnlyMyCandidates,
    searchedNewGraduateIds,
  });

  // NOTE: contactRoomIdから最新のメッセージを取得するためのMap
  const contactRoomIdToLatestMessageMap = useMemo(
    () =>
      new Map(
        showLatestContactMessages.map((latestContactMessage) => [
          latestContactMessage.contactRoomId,
          latestContactMessage,
        ])
      ),
    [showLatestContactMessages]
  );

  return {
    currentContactRooms,

    /** Map<コンタクトルームID, 未読数> */
    contactRoomUnreadCountMap,
    isLoadingUnreadMessageCountList,
    isLoadingContactRooms,
    latestContactMessages: showLatestContactMessages,
    contactRoomIdToLatestMessageMap,
    isLoadingLatestMessages,
    isLoadingAllContactRooms,
  };
};
