import { z } from "zod";

import { csvOutputCondition } from "../api";

export const newGraduatesCSVOutputConditionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  executeEmployeeId: z.string(),
  condition: z.object({
    employeeIds: z.array(z.string()),
    conditionFields: csvOutputCondition.shape.condition_fields,
    scenarioIdSelections: z.array(z.string()),
  }),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type INewGraduatesCSVOutputConditionSchema = z.infer<
  typeof newGraduatesCSVOutputConditionSchema
>;
