import { ConditionField, NewGraduatesCSVOutputCondition } from "@onn/common";
import { useState } from "react";

import { useSnackbar } from "../shared";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { useCurrentUser } from "./useCurrentUser";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useDownloadNewGraduatesCSVAsync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  /**
   * idや条件をもとに複数newGraduateのCSVファイルをダウンロードする
   */
  const downloadNewGraduatesCSVAsync = async ({
    employeeIds,
    conditionFields,
    scenarioIdSelections,
  }: {
    employeeIds: string[];
    conditionFields: ConditionField[];
    scenarioIdSelections: string[];
  }) => {
    try {
      setIsLoading(true);

      // NOTE: cloudTasksのタスクのサイズ制限が1MBなので、csv出力に必要なパラメータをGCSに保存しておく
      const csvOutputConditionFilePath = await uploadNewGraduatesCSVOutputConditionFile({
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
        executeEmployeeId: currentUser.id,
        employeeIds,
        conditionFields,
        scenarioIdSelections,
      });

      await apiClient.post("/api/employee/get-new-graduates-csv-async", {
        csvOutputConditionFilePath,
      });
      enqueueSnackbar("候補者情報CSVの作成を開始しました。完了後にメールでお送りします。", {
        variant: "success",
      });
    } catch (_) {
      enqueueSnackbar("候補者情報CSVの作成に失敗しました。時間をおいて再度お試しください。", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadNewGraduatesCSVAsync, isLoading };
};

const uploadNewGraduatesCSVOutputConditionFile = async ({
  tenantId,
  spaceId,
  executeEmployeeId,
  employeeIds,
  conditionFields,
  scenarioIdSelections,
}: {
  tenantId: string;
  spaceId: string;
  executeEmployeeId: string;
  employeeIds: string[];
  conditionFields: ConditionField[];
  scenarioIdSelections: string[];
}) => {
  const newGraduatesCSVOutputCondition = NewGraduatesCSVOutputCondition.create({
    tenantId,
    executeEmployeeId,
    condition: {
      employeeIds,
      conditionFields,
      scenarioIdSelections,
    },
  });
  const fileName = newGraduatesCSVOutputCondition.generateCsvOutputConditionFileName();
  const filePath = NewGraduatesCSVOutputCondition.generateCsvOutputConditionFilePath({
    spaceId,
    tenantId,
    fileName,
  });

  await fileAPIAdapter.upload({
    path: filePath,
    file: new File([newGraduatesCSVOutputCondition.serialize()], fileName, {
      type: "application/json",
    }),
  });

  return filePath;
};
