import { Box } from "@material-ui/core";
import { Employee, NewGraduate, OnnFormTask, OnnFormTaskAnswer } from "@onn/common";
import React, { FC, useCallback, useEffect } from "react";

import { OnnTaskTable, TableRow } from "./OnnTaskTable/OnnTaskTable";

import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnClickCancelDelivery } from "~/hooks/onnFormTaskAnswer/useOnClickCancelDelivery";
import { useOnnFormTasksAnswersForAdmin } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersForAdmin";
import { useQuery } from "~/hooks/shared";

const answerStatusToLabel = {
  answered: "回答済み",
  notAnswered: "未着手",
  expired: "期限切れ",
  notDelivered: "未配信",
} as const;

type Props = {
  newGraduate: NewGraduate;
};

export const OnnTaskTab: FC<Props> = ({ newGraduate }) => {
  const { query } = useQuery();
  const { handleModal } = useModal();

  const onnTaskId = query.get("onnTaskId");
  useEffect(() => {
    if (onnTaskId) {
      handleModal({
        name: "answerOnnTaskOnBehalfModal",
        args: {
          onnTaskId,
          employeeId: newGraduate.id,
          mode: "VIEW",
        },
      });
    }
  }, [handleModal, newGraduate.id, onnTaskId]);

  const { data: taskInfo = [], isLoading } = useOnnFormTasksAnswersForAdmin({
    newGraduateId: newGraduate.id,
  });
  const { currentUser } = useCurrentUser();

  const { convertToTableRowData } = useConvertToTableRowData();
  const { onClickCancelDelivery } = useOnClickCancelDelivery();
  if (isLoading) {
    return <Loading size="large" />;
  }
  return (
    <Box pt={5}>
      <OnnTaskTable
        isShowThreeDots={currentUser.isRegularAcceptanceEmployee()}
        rows={convertToTableRowData(taskInfo, newGraduate)}
        cancelDelivery={(onnTaskId) => {
          const target = taskInfo.find((info) => info.onnTask.id === onnTaskId);
          if (!target) {
            return;
          }
          onClickCancelDelivery(target.onnFormTaskAnswer, target.onnTask.title, newGraduate);
        }}
        onClickRow={
          currentUser.isRegularAcceptanceEmployee()
            ? ({ onnTaskId }) => {
                handleModal({
                  name: "answerOnnTaskOnBehalfModal",
                  args: {
                    onnTaskId,
                    employeeId: newGraduate.id,
                    mode: "VIEW",
                  },
                });
              }
            : undefined
        }
      />
    </Box>
  );
};

const useConvertToTableRowData = () => {
  const convertToTableRowData = useCallback(
    (
      taskInfo: {
        onnTask: OnnFormTask;
        onnFormTaskAnswer: OnnFormTaskAnswer;
        answerStatus: "answered" | "notAnswered" | "expired" | "notDelivered";
      }[],
      employee: Employee
    ): TableRow[] => {
      return taskInfo.map(({ onnTask, onnFormTaskAnswer, answerStatus }) => {
        return {
          onnTaskId: onnTask.id,
          onnTaskTitle: onnTask.title,
          newGraduateId: onnFormTaskAnswer.employeeId,
          newGraduateName: employee.getName(),
          newGraduateIconUrl: employee.profileIconImageUrl,
          onnTaskStatus: answerStatusToLabel[answerStatus],
          onnTaskDueDate: onnTask.deadlineDate || undefined,
        };
      });
    },
    []
  );

  return { convertToTableRowData };
};
