import { Box } from "@material-ui/core";
import {
  AllContactRoom,
  ContactMessage,
  ContactMessageDraft,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  getHowLongAgo,
} from "@onn/common";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { ContactMessageTemplateMenu } from "../parts";
import { useContactMessageTemplatesMenuHandling } from "../parts/ContactMessageTemplateMenu/useContactMessageTemplatesMenuHandling";
import { LineStampPopover } from "../parts/LineStampPopover";

import {
  ContactMessagesForDisplay,
  MessageListView,
} from "../parts/MessageListView/MessageListView";

import { useContactMessagesAndLogs } from "./useContactMessagesOrLogs";

import { useSPNewGraduateDetailModal } from "~/components/domains/employees/hooks/useSPNewGraduateDetailModal";
import {
  Button,
  UserIconWithLabel as CommonUserIconWithLabel,
  Icon,
  IconButton,
  Loading,
  TextareaAutosizeAttachableFile,
  Toggle,
  Typography,
  UserIcon,
  UserIconMenu,
} from "~/components/uiParts";
import { useOptimisticCreateMessage } from "~/hooks/contactMessage";
import { usePostLineStampMessage } from "~/hooks/contactMessage/usePostLineStampMessage";
import { useContactRoom, useUpdateReadLogOfContactRoom } from "~/hooks/contactRoom";
import { useCurrentUser } from "~/hooks/employee";
import { useNewGraduate } from "~/hooks/employee/useNewGraduate";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { useFileUrl } from "~/hooks/file";
import { useDebouncedCallback, useKeyboardHeight } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";

import { NotFound } from "~/pages/NotFound";
import { useContactMessageDraft } from "~/pages/contactMessages/hooks/useContactMessageDraft";

type Props = {
  contactRoomId: string;
  contactRoomIdToPinnedFlagMap: Map<string, boolean>;
  onClickBack: () => void;
};

export const NewGraduateSPContactRoomItem: FC<Props> = (props) => {
  const contactRoomId = props.contactRoomId;
  const { currentUser } = useCurrentUser();

  const { contactMessageDraft, saveContactMessageDraft, isLoadingDraft } = useContactMessageDraft({
    selectedContactRoomId: contactRoomId,
  });

  const { contactRoomAndMessages, isLoading: isLoadingContactRoomAndMessages } =
    useContactMessagesAndLogs({
      contactRoomId,
    });
  const { data: contactRoom, isLoading: isLoadingContactRooms } = useContactRoom({
    tenantId: currentUser.tenantId,
    contactRoomId,
  });

  const { switchSpaceTemporary } = useCurrentSpace();

  useEffect(() => {
    if (!contactRoom?.spaceId) return;

    switchSpaceTemporary(contactRoom.spaceId);
  }, [switchSpaceTemporary, contactRoom]);

  if (isLoadingContactRoomAndMessages || isLoadingContactRooms || isLoadingDraft)
    return <Loading size={"large"} fullHeight />;

  if (!contactRoomAndMessages.contactRoom || !contactRoom) return <NotFound />;

  return (
    <SPContactRoomItem
      {...props}
      contactRoom={contactRoom}
      contactMessages={
        contactRoomAndMessages.messages as (ContactMessage & {
          creatorName: string;
          creatorImageUrl: string | undefined;
        })[]
      }
      saveContactMessageDraft={saveContactMessageDraft}
      contactMessageDraft={
        contactMessageDraft ??
        ContactMessageDraft.create({
          tenantId: currentUser.tenantId,
          createdUserId: currentUser.id,
          contactRoomId: contactRoom.id,
          text: "",
        })
      }
    />
  );
};

type SPContactRoomItemProps = {
  contactRoom: AllContactRoom;
  contactMessages: ContactMessagesForDisplay[];
  contactMessageDraft: ContactMessageDraft;
  contactRoomIdToPinnedFlagMap: Map<string, boolean>;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  onClickBack: () => void;
};

const SPContactRoomItem: FC<SPContactRoomItemProps> = ({
  contactRoom,
  contactMessages,
  contactMessageDraft,
  contactRoomIdToPinnedFlagMap,
  saveContactMessageDraft,
  onClickBack,
}) => {
  const { keyboardHeight } = useKeyboardHeight();
  const { handleOpenSPNewGraduateDetailModal } = useSPNewGraduateDetailModal();

  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();

  const { postMessage, isSending, isFileSending, messageId } = useOptimisticCreateMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
  });

  const [newMessage, setNewMessage] = useState("");
  const [isOfficialName, setIsOfficialName] = useState(false);
  useEffect(() => {
    setNewMessage(contactMessageDraft?.text ?? "");
  }, [contactMessageDraft]);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  const [messageListRef, setMessageListRef] = useState<HTMLDivElement>();

  // NOTE: コンタクトルームのemployeeIdに紐付くユーザーは必ずnewGraduate
  const { data: newGraduate, isLoading: isEmployeeLoading } = useNewGraduate({
    newGraduateId: contactRoom.employeeId,
  });

  const { data: employeeInformation, isLoading: isLoadingEmployeeInformation } =
    useEmployeeInformation(contactRoom.employeeId);

  const isDataLoading = isEmployeeLoading || isLoadingEmployeeInformation;

  const { updateReadLogOfContactRoom } = useUpdateReadLogOfContactRoom();

  // コンタクトルームに紐付くユーザーが削除されているかどうか
  const isRoomTargetEmployeeDeleted = contactRoom.employee && contactRoom.employee.deleted;

  useEffect(() => {
    const fn = async () => {
      await updateReadLogOfContactRoom(currentUser.id, contactRoom.id);
    };

    fn();
    // ページアクセス時のみの実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, contactRoom.id]);

  useEffect(() => {
    if (!messageListRef) {
      return;
    }

    messageListRef.scrollTop = messageListRef.scrollHeight;
    // messageに変更があったときに一番したまでスクロールする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListRef, contactMessages]);

  const handleSubmit = useCallback(async () => {
    setLineStampPopoverOpen(false);
    if (contactMessageDraft) {
      saveContactMessageDraft(
        new ContactMessageDraft({
          ...contactMessageDraft,
          text: "",
          tenantId: currentUser.tenantId,
        })
      );
    }
    await postMessage({
      newMessageText: newMessage,
      newMessageFiles,
      employee: currentUser,
      isOfficialName,
    });
    setNewMessageFiles([]);
  }, [
    contactMessageDraft,
    currentUser,
    newMessage,
    newMessageFiles,
    postMessage,
    saveContactMessageDraft,
    isOfficialName,
  ]);

  const messageListViewJSXMemo = useMemo(
    () => (
      <MessageListView
        newGraduate={newGraduate || undefined}
        tenantName={tenant.tenantName}
        isRoomTargetEmployeeDeleted={isRoomTargetEmployeeDeleted}
        contactRoom={contactRoom}
        contactMessages={contactMessages}
        isFileSending={isFileSending}
        messageId={messageId}
        isContactRoomClosed={contactRoom.isClosed}
      />
    ),
    [
      contactMessages,
      contactRoom,
      isFileSending,
      isRoomTargetEmployeeDeleted,
      messageId,
      newGraduate,
      tenant.tenantName,
    ]
  );

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 500);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessage(e.target.value);
      handleDebounceCallback(() => {
        if (!contactMessageDraft) return;
        saveContactMessageDraft(
          new ContactMessageDraft({
            id: contactMessageDraft.id,
            contactRoomId: contactMessageDraft.contactRoomId,
            createdUserId: contactMessageDraft.createdUserId,
            text: e.target.value,
            tenantId: currentUser.tenantId,
          })
        );
      });
    },
    [contactMessageDraft, currentUser.tenantId, handleDebounceCallback, saveContactMessageDraft]
  );

  const employeeStatusText = useMemo(() => {
    return newGraduate?.lastActiveTime ? getHowLongAgo(newGraduate.lastActiveTime) : "なし";
  }, [newGraduate]);

  const [lineStampPopoverOpen, setLineStampPopoverOpen] = useState(false);
  const messageAreaRef = useRef<HTMLDivElement>(null);

  const { trigger: postLineStampMessage } = usePostLineStampMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
    employee: currentUser,
  });

  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    contactMessageDraft,
    setNewMessage,
    saveContactMessageDraft,
    currentMessage: newMessage,
  });

  const isPinned = contactRoomIdToPinnedFlagMap.get(contactRoom.id) ?? false;

  const { data: logoUrl } = useFileUrl(`public/uploads/logo/${currentUser.tenantId}`);

  if (isDataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Loading size="large" />
      </Box>
    );
  }

  // NOTE: LINEコンタクトルームの場合、ブロックされているかどうかの判定
  const isUnFollow = "lineUser" in contactRoom && contactRoom.lineUser.isUnFollow;

  // NOTE: メッセージを送信できる設定かどうか
  const canSendMessage = !isRoomTargetEmployeeDeleted && !isUnFollow && !contactRoom.isClosed;

  return (
    <StyledContainerBox
      width="100%"
      display="flex"
      flexDirection="column"
      $keyboardHeight={keyboardHeight}
    >
      <StyledHeaderBox p="8px 16px" bgcolor="white" zIndex={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gridGap={16}
        >
          <StyledBoxIconWrapper onClick={onClickBack}>
            <Icon icon="goBack" size="md" color="grey" />
          </StyledBoxIconWrapper>
          <Box flex="1 1 auto">
            <CommonUserIconWithLabel
              name={newGraduate?.getName() ?? ""}
              size={"small"}
              iconPath={newGraduate?.profileIconImageUrl ?? ""}
              secondaryText={`最終アクセス: ${employeeStatusText}`}
              isPinned={isPinned}
            />
          </Box>
          {newGraduate && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridGap: 8,
              }}
            >
              <StyledBoxIconWrapper
                onClick={() =>
                  handleOpenSPNewGraduateDetailModal(
                    newGraduate,
                    contactRoom,
                    employeeInformation || undefined
                  )
                }
              >
                <Icon icon="info" size="md" color="grey" />
              </StyledBoxIconWrapper>
            </Box>
          )}
        </Box>
      </StyledHeaderBox>

      <Box
        flex="1 1 auto"
        display="flex"
        flexDirection="column"
        gridGap="32px"
        paddingY="40px"
        paddingX="8px"
        overflow="scroll"
        // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
        // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
        {...{ ref: (node: HTMLDivElement) => setMessageListRef(node) }}
      >
        {messageListViewJSXMemo}
      </Box>
      {canSendMessage && (
        <Box mt="auto" p="16px" bgcolor="white" {...{ ref: messageAreaRef }}>
          <TextareaAutosizeAttachableFile
            ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
            value={newMessage}
            fullWidth
            placeholder={`${contactRoom.getHonorificRoomName()}へのメッセージを入力してください`}
            onChange={onChange}
            minRows={1}
            maxRows={5}
            accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
            onChangeFiles={(newFiles: (File | Pick<File, "name">)[]) => {
              setNewMessageFiles(
                newFiles.filter((v): v is File => {
                  return v instanceof File;
                })
              );
            }}
            attachedFiles={newMessageFiles}
            footerLeftActions={
              <>
                {/* LINEスタンプ送信ボタン */}
                {newGraduate?.selectedAuthenticationFlowType === "line" && (
                  <Toggle pressed={lineStampPopoverOpen} onPressedChange={setLineStampPopoverOpen}>
                    <Icon icon="emojiSmile" size="sm" color="inherit" />
                  </Toggle>
                )}
                {/* メッセージテンプレートボタン */}
                <IconButton
                  icon="document"
                  size="sm"
                  color="grey"
                  borderRadius="regular"
                  onClick={contactMessageTemplatesMenuHandling.handleOpen}
                />
              </>
            }
            footerButtons={[
              <UserIconMenu
                key="userIconMenu"
                username={!isOfficialName ? currentUser.getName() : tenant.tenantName}
                imageUrl={!isOfficialName ? currentUser.profileIconImageUrl : logoUrl}
                title="送信元アカウント"
                menuItemBoxes={[
                  {
                    content: (
                      <Typography variant="body2" color="textPrimary">
                        企業アカウント
                      </Typography>
                    ),
                    onClick: () => setIsOfficialName(true),
                  },
                  {
                    content: (
                      <Box display="flex" alignItems="center" gridGap={4}>
                        <UserIcon
                          username={currentUser.getName()}
                          profileIconImageUrl={currentUser.profileIconImageUrl}
                          size="extraSmall"
                          circular
                        />
                        <Typography variant="body2" color="textPrimary" noWrap>
                          {currentUser.getName()}
                        </Typography>
                      </Box>
                    ),
                    onClick: () => setIsOfficialName(false),
                  },
                ]}
              />,
              <Button
                key="submit"
                onClick={handleSubmit}
                borderRadius="regular"
                variant="contained"
                color="primary"
                disabled={!newMessage.trim() || isSending}
              >
                送信
              </Button>,
            ]}
          />
          <LineStampPopover
            anchorEl={messageAreaRef.current}
            open={lineStampPopoverOpen}
            onClose={() => {
              setLineStampPopoverOpen(false);
            }}
            onSelect={async (lineStamp) => {
              setLineStampPopoverOpen(false);
              await postLineStampMessage({ stickerId: lineStamp.stickerId, isOfficialName });
            }}
          />
        </Box>
      )}
      {/*  メッセージのテンプレート管理用メニュー */}
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)<{ $keyboardHeight: number }>`
  position: absolute;
  top: 0;
  background-color: ${(props) => props.theme.palette.grey[50]};
  height: 100vh ${(props) => (props.$keyboardHeight > 0 ? `-${props.$keyboardHeight}px` : "")};
`;

const StyledBoxIconWrapper = styled(Box)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`;

const StyledHeaderBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;
