import { AllContactRoom, ContactRoomUnreadCountInfo } from "@onn/common";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeyedMutator } from "swr";

import { useContactRoomsV2 } from "~/hooks/contactRoom";
import { useContactRoomsWithLimit } from "~/hooks/contactRoom/useContactRoomsWithLimit";
import { useContactRoomUnreadCountV2 } from "~/hooks/contactRoomUnreadCount";
import { useCurrentUser } from "~/hooks/employee";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

const unreadCountInfoRepository = new UnreadCountInfoRepository();

export const ContactContextForAdminSite = createContext<{
  /** Map<コンタクトルームID, 未読数> */
  contactRoomUnreadCountMap: Map<string, number>;
  totalUnreadCount: number;
  isLoadingUnreadMessageCountList: boolean;
  contactRooms: AllContactRoom[];
  contactRoomsWithoutCurrentUser: AllContactRoom[];
  isLoadingContactRooms: boolean;
  lastUpdatedAt: Date;
  contactRoomsMutate: KeyedMutator<AllContactRoom[]>;
  registerOnChangeUnreadCountInfoHandler: (
    handler: ((contactRoomId: string) => void) | undefined
  ) => void;
}>({
  contactRoomUnreadCountMap: new Map(),
  totalUnreadCount: 0,
  isLoadingUnreadMessageCountList: true,
  contactRooms: [],
  contactRoomsWithoutCurrentUser: [],
  isLoadingContactRooms: true,
  lastUpdatedAt: new Date(),
  contactRoomsMutate: async () => [],
  registerOnChangeUnreadCountInfoHandler: () => {},
});

export const ContactProviderForAdminSite: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    isLoadingAllUnreadCountsForEmployee,
    accessibleUnreadCounts = [],

    contactRooms = [],
    isLoadingContactRooms,
    contactRoomsMutate,
    contactRoomsWithoutCurrentUser,
  } = useFetch();
  const {
    unreadCountMap,
    totalUnreadCount,
    lastUpdatedAt,
    registerOnChangeUnreadCountInfoHandler,
  } = useUnreadCountsForDisplay(accessibleUnreadCounts);

  return (
    <ContactContextForAdminSite.Provider
      value={{
        contactRoomUnreadCountMap: unreadCountMap,
        isLoadingUnreadMessageCountList: isLoadingAllUnreadCountsForEmployee,
        totalUnreadCount,
        contactRoomsWithoutCurrentUser,
        contactRooms,
        isLoadingContactRooms,
        lastUpdatedAt,
        contactRoomsMutate,
        registerOnChangeUnreadCountInfoHandler,
      }}
    >
      {children}
    </ContactContextForAdminSite.Provider>
  );
};

const useFetch = () => {
  const { currentUser } = useCurrentUser();

  const { data: allUnreadCountsForEmployee, isLoading: isLoadingAllUnreadCountsForEmployee } =
    useContactRoomUnreadCountV2();

  // NOTE: 暫定対応
  const {
    data: contactRooms,
    isLoading: isLoadingContactRooms,
    mutate: contactRoomsMutate,
  } = useContactRoomsV2({
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom: true,
  });
  const { data: limitContactRooms } = useContactRoomsWithLimit();

  const targetContactRooms = useMemo(() => {
    if (contactRooms) {
      return contactRooms;
    }
    if (limitContactRooms) {
      return limitContactRooms;
    }
    return [];
  }, [contactRooms, limitContactRooms]);

  // 担当者・フォロワーになっているコンタクトルームに紐付く未読情報
  // 担当・フォロワーになっていないコンタクトルームの未読情報もfirestoreで管理しているため
  const accessibleUnreadCounts = useMemo(() => {
    return allUnreadCountsForEmployee
      ? allUnreadCountsForEmployee.filter((v) => {
          return targetContactRooms
            ? targetContactRooms.find((contactRoom) => contactRoom.id === v.contactRoomId)
            : undefined;
        })
      : [];
  }, [targetContactRooms, allUnreadCountsForEmployee]);

  const contactRoomsWithoutCurrentUser = useMemo(
    () =>
      targetContactRooms ? targetContactRooms.filter((v) => v.employeeId !== currentUser.id) : [],
    [targetContactRooms, currentUser.id]
  );

  return {
    isLoadingAllUnreadCountsForEmployee,
    accessibleUnreadCounts,
    contactRooms,
    isLoadingContactRooms,
    contactRoomsMutate,
    contactRoomsWithoutCurrentUser,
  };
};

const useUnreadCountsForDisplay = (init: { contactRoomId: string; unreadCount: number }[]) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(new Date());
  const [onChangedUnreadCountInfo, setOnChangedUnreadCountInfo] = useState<
    ((contactRoomId: string) => void | Promise<void>) | undefined
  >(undefined);

  const registerOnChangeUnreadCountInfoHandler = useCallback(
    (handler: ((contactRoomId: string) => void) | undefined) => {
      // NOTE: setState に関数を渡すと更新用関数として解釈されてしまうが、関数を値としてsetしたい。
      // そのため、関数を返す更新用関数にしている。(() => handler の形)
      setOnChangedUnreadCountInfo(() => handler);
    },
    []
  );

  const { currentUser } = useCurrentUser();

  const [unreadCountsForDisplay, setUnreadCountsForDisplay] = useState<
    {
      contactRoomId: string;
      unreadCount: number;
    }[]
  >([]);

  useEffect(() => {
    // useState呼び出し時には init は空配列になっているため、こちらで初期化する
    setUnreadCountsForDisplay(init);
  }, [init]);

  const updateCount = (updatedInfo: ContactRoomUnreadCountInfo) => {
    setUnreadCountsForDisplay((counts) =>
      counts.map((v) =>
        v.contactRoomId === updatedInfo.contactRoomId
          ? {
              contactRoomId: updatedInfo.contactRoomId,
              unreadCount: updatedInfo.unreadCount,
            }
          : v
      )
    );
  };

  const totalUnreadCount = useMemo(() => {
    return unreadCountsForDisplay.reduce((acc, crr) => {
      acc += crr.unreadCount;
      return acc;
    }, 0);
  }, [unreadCountsForDisplay]);

  useEffect(() => {
    if (unreadCountsForDisplay.length < 0) return;
    const unsubscribeFunctions = unreadCountInfoRepository.listenUnreadCountInfo({
      contactRoomIds: unreadCountsForDisplay.map((v) => v.contactRoomId),
      employeeId: currentUser.id,
      tenantId: currentUser.tenantId,
      onChangedUnreadCountInfo: (updatedInfo) => {
        updateCount(updatedInfo);
        setLastUpdatedAt(new Date());
        onChangedUnreadCountInfo?.(updatedInfo.contactRoomId);
      },
    });
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // 必要以上にlistenが走らないようにしている
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCountsForDisplay.length, onChangedUnreadCountInfo]);

  const unreadCountMap = useMemo(() => {
    return new Map(unreadCountsForDisplay.map((v) => [v.contactRoomId, v.unreadCount]));
  }, [unreadCountsForDisplay]);

  return {
    unreadCountMap,
    lastUpdatedAt,
    totalUnreadCount,
    registerOnChangeUnreadCountInfoHandler,
  };
};
