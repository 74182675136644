import { OnnEventEvaluationRank } from "@onn/common";
import useSWR from "swr";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const generateKey = (arg: { spaceId: string }) =>
  ["/api/onn-event-evaluation-ranks", arg.spaceId] as const;

export const useOnnEventEvaluationRanks = () => {
  const { currentSpace } = useCurrentSpace();

  return useSWR(
    generateKey({
      spaceId: currentSpace.id,
    }),
    async ([endpoint]) => {
      const response = await apiClient.get(endpoint);
      return response.data.map((d) => {
        return {
          onnEventId: d.onnEventId,
          onnEventEvaluationRanks: d.onnEventEvaluationRanks.map(
            (e) => new OnnEventEvaluationRank(e)
          ),
        };
      });
    }
  );
};
