import { zodResolver } from "@hookform/resolvers/zod";
import {
  NewGraduateToDisplayForAdmin,
  RecruitmentStatus,
  RecruitmentStatusRelation,
  Scenario,
} from "@onn/common";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  scenarioId: z.string(),
  recruitmentStatusId: z.string(),
  offerAcceptanceDeadline: z.date().optional(),
});

export const useAddScenarioToEmployeeForm = ({
  newGraduate,
  scenariosWithRecruitmentStatuses,
  offerAcceptanceDeadline,
  inactiveScenarioRelations,
  onSubmit,
}: {
  newGraduate: NewGraduateToDisplayForAdmin;
  scenariosWithRecruitmentStatuses: {
    scenario: Scenario;
    recruitmentStatuses: RecruitmentStatus[];
  }[];
  offerAcceptanceDeadline?: Date;
  inactiveScenarioRelations: Array<{
    scenario: Scenario;
    recruitmentStatusRelation: RecruitmentStatusRelation;
  }>;
  onSubmit: (args: {
    scenarioId: string;
    recruitmentStatusId: string;
    offerAcceptanceDeadline?: Date;
  }) => Promise<void>;
}) => {
  const form = useForm<{
    scenarioId: string;
    recruitmentStatusId: string;
    offerAcceptanceDeadline?: Date;
  }>({
    defaultValues: { offerAcceptanceDeadline },
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const scenarioOptions = useMemo(() => {
    return scenariosWithRecruitmentStatuses
      .filter(
        ({ scenario }) =>
          // 候補者に設定しているシナリオは選択肢から除外する
          !newGraduate.scenarios.find(
            (scenarioOfNewGraduate) => scenario.id === scenarioOfNewGraduate.scenarioId
          ) &&
          // 候補者に非アクティブなシナリオは選択肢から除外する
          !inactiveScenarioRelations.find((relation) => relation.scenario.id === scenario.id)
      )
      .map(({ scenario }) => {
        return {
          value: scenario.id,
          name: scenario.name,
        };
      });
  }, [inactiveScenarioRelations, newGraduate.scenarios, scenariosWithRecruitmentStatuses]);

  const selectedScenarioId = form.watch("scenarioId");
  const selectedScenario = useMemo(
    () =>
      scenariosWithRecruitmentStatuses.find(({ scenario }) => scenario.id === selectedScenarioId),
    [scenariosWithRecruitmentStatuses, selectedScenarioId]
  );

  const recruitmentStatusOptions = useMemo(() => {
    if (!selectedScenario) return [];

    return selectedScenario.recruitmentStatuses.map((recruitmentStatus) => ({
      value: recruitmentStatus.id,
      name: recruitmentStatus.label,
    }));
  }, [selectedScenario]);

  const selectedRecruitmentStatusId = form.watch("recruitmentStatusId");
  const isSelectedJobOfferType = useMemo(() => {
    const selectedRecruitmentStatus = selectedScenario?.recruitmentStatuses.find(
      (status) => status.id === selectedRecruitmentStatusId
    );

    if (!selectedRecruitmentStatus) return false;

    return selectedRecruitmentStatus.isJobOffer();
  }, [selectedRecruitmentStatusId, selectedScenario?.recruitmentStatuses]);

  const onChangeScenario = useCallback(() => {
    form.resetField("recruitmentStatusId");
    form.resetField("offerAcceptanceDeadline");
  }, [form]);

  const onChangeRecruitmentStatus = useCallback(() => {
    form.resetField("offerAcceptanceDeadline");
  }, [form]);

  return {
    ...form,
    scenarioOptions,
    recruitmentStatusOptions,
    isSelectedJobOfferType,
    handleSubmit: form.handleSubmit(
      async ({ scenarioId, recruitmentStatusId, offerAcceptanceDeadline }) => {
        await onSubmit({ scenarioId, recruitmentStatusId, offerAcceptanceDeadline });
      }
    ),
    onChangeScenario,
    onChangeRecruitmentStatus,
  };
};
