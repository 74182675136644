import { v4 } from "uuid";

import { Employee } from "../../Employee";

import { ReadLog } from "../ReadLog/ReadLog";

import { IContactRoom } from "./schema";

export class ContactRoom implements IContactRoom {
  readonly id: string;
  readonly tenantId: string;
  spaceId?: string;
  employeeId: string; //入社者のid
  title: string;
  readLogs: ReadLog[];
  type: "LINE_USER" | "EMAIL_USER";
  targetId?: string;
  isClosed: boolean;

  createdEmployeeId?: string;
  updatedEmployeeId?: string;
  createdAt: Date;
  updatedAt: Date;
  messageUpdatedAt: Date;

  constructor(init: ExcludeMethods<ContactRoom>) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.employeeId = init.employeeId;
    this.title = init.title;
    this.readLogs = init.readLogs;
    this.type = init.type;
    this.targetId = init.targetId;
    this.isClosed = init.isClosed;

    this.createdEmployeeId = init.createdEmployeeId;
    this.updatedEmployeeId = init.updatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
    this.messageUpdatedAt = init.messageUpdatedAt;
  }

  update(
    currentUserId: string | undefined,
    newObject: Partial<
      Pick<ContactRoom, "title" | "employeeId" | "readLogs" | "spaceId" | "isClosed">
    >
  ): this {
    if (newObject.title) {
      this.title = newObject.title;
    }
    if (newObject.employeeId) {
      this.employeeId = newObject.employeeId;
    }
    if (newObject.readLogs) {
      this.readLogs = newObject.readLogs;
    }
    if (newObject.spaceId) {
      this.spaceId = newObject.spaceId;
    }
    if (newObject.isClosed != undefined) {
      this.isClosed = newObject.isClosed;
    }

    // システムが更新するときはemployeeIdは存在しない
    if (currentUserId) {
      this.updatedEmployeeId = currentUserId;
    }
    this.updatedAt = new Date();

    return this;
  }

  updateReadLog(employeeId: string): this {
    const readLog = this.readLogs.find((readLog) => readLog.employeeId === employeeId);

    let newReadLogs: ReadLog[] = [];
    if (readLog) {
      newReadLogs = this.readLogs.map((readLog) => {
        if (readLog.employeeId === employeeId) return readLog.update();
        return readLog;
      });
    } else {
      newReadLogs = [...this.readLogs, ReadLog.createByEmployeeId(employeeId)];
    }

    return this.update(employeeId, {
      readLogs: newReadLogs,
    });
  }

  updateToLine(employee: Employee): ContactRoom {
    return ContactRoom.create({
      ...this,
      employeeId: employee.id,
      spaceId: employee.spaceId,
      type: "LINE_USER",
      targetId: employee.lineUserId,
      updatedAt: new Date(),
    });
  }

  updateMessageUpdatedAt(): ContactRoom {
    return new ContactRoom({
      ...this,
      messageUpdatedAt: new Date(),
    });
  }

  canNotify(employee: Employee) {
    if (this.type === "LINE_USER") {
      return employee.canNotifyWithLine();
    }

    return employee.canNotifyWithEmail();
  }

  isAccessible({
    currentUser,
    newGraduate,
  }: {
    currentUser: Employee;
    newGraduate: Employee | undefined;
  }): boolean {
    if (!newGraduate) return false;

    if (this.employeeId !== newGraduate.id) {
      // 事前条件としてのアクセス権限検証
      // 想定外のため、falseではなくエラーを返す
      throw new Error(
        `アクセス権限検証しようとしているコンタクトルームと候補者が一致しません, ${this.employeeId}, ${currentUser.id}`
      );
    }

    if (this.tenantId !== currentUser.tenantId) return false;
    if (currentUser.isOnlyInterviewer()) return false;

    if (currentUser.isAdmin()) return true;
    if (newGraduate.id === currentUser.id) return true;

    const isFollower = newGraduate.supportMemberEmployeeIds.includes(currentUser.id);
    const isMentor = newGraduate.mentorUserId === currentUser.id;
    return isFollower || isMentor;
  }

  public static create(params: Optional<ExcludeMethods<ContactRoom>, "id">): ContactRoom {
    return new ContactRoom({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static createForEmail({
    tenantId,
    currentUserId,
    employeeId,
    spaceId,
  }: {
    tenantId: string;
    currentUserId: string;
    employeeId: string;
    spaceId?: string;
  }): ContactRoom {
    return ContactRoom.create({
      tenantId,
      employeeId,
      title: "メッセージルーム",
      type: "EMAIL_USER",
      readLogs: [],
      createdAt: new Date(),
      createdEmployeeId: currentUserId,
      updatedAt: new Date(),
      messageUpdatedAt: new Date(),
      updatedEmployeeId: currentUserId,
      spaceId,
      isClosed: false,
    });
  }

  public static createForLine(params: {
    tenantId: string;
    targetId: string;
    employeeId: string;
    spaceId?: string;
  }): ContactRoom {
    return ContactRoom.create({
      tenantId: params.tenantId,
      employeeId: params.employeeId,
      title: "コンタクトルーム",
      readLogs: [],
      createdAt: new Date(),
      createdEmployeeId: "",
      updatedAt: new Date(),
      messageUpdatedAt: new Date(),
      updatedEmployeeId: "",
      type: "LINE_USER",
      targetId: params.targetId,
      spaceId: params.spaceId,
      isClosed: false,
    });
  }

  checkIsNewHire(employeeId: string) {
    return this.employeeId === employeeId;
  }

  static plainToInstance(init: ExcludeMethods<ContactRoom>): ContactRoom {
    return new ContactRoom({
      ...init,
      readLogs: init.readLogs.map((v) => ReadLog.plainToInstance(v)),
    });
  }
}
