import { Box, Divider } from "@material-ui/core";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import { EmployeeFilter } from "../EmployeeFilter";
import { EmployeeTagFilter } from "../EmployeeTagFilter";
import { RecruitmentStatusFilter } from "../RecruitmentStatusFilter";
import { SelectList } from "../SelectList";

import { Loading, SearchForm } from "~/components/uiParts";

import { useAllNewcomers, useCurrentUser, useFilterNewGraduates } from "~/hooks/employee";
import { useEmployeeTags } from "~/hooks/employeeTag";

type Props = {
  selectedEmployees: NewGraduateToDisplayForAdmin[];
  setSelectedEmployees: (employees: NewGraduateToDisplayForAdmin[]) => void;
  filter?: (employee: NewGraduateToDisplayForAdmin) => boolean;
  height?: number;
  includeRejectedAndWithdrew: boolean;
  isAcrossSpace?: boolean;
};

export const NewGraduateSelectionCore = ({
  selectedEmployees,
  setSelectedEmployees,
  filter,
  height,
  includeRejectedAndWithdrew,
  isAcrossSpace,
}: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { data: allNewcomers = [], isLoading: isLoadingAllNewComers } = useAllNewcomers({
    isAcrossSpace,
  });
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  const {
    filteredEmployees,
    allMentorIds,
    selectedRecruitmentStatuses,
    selectedMentorIds,
    selectedTagIds,
    setSelectedRecruitmentStatusIds,
    setSelectedMentorIds,
    searchValue,
    setSearchValue,
    setSelectedTagIds,
  } = useFilterNewGraduates(allNewcomers, filter);

  const heightOfList = useMemo(() => {
    const heightOfListItem = 60;
    if (!height) return heightOfListItem * 8;
    const firstDividerHeight = 24 + 1 + 24; // 縦方向のpaddingが16px
    const filterHeight = 40;
    const selectAllHeight = 24 + 24 + 24; // 縦方向のpaddingが16px
    const lastDividerHeight = 24 + 1; // 上向きのpaddingが16px
    const heightOfOtherElements =
      firstDividerHeight + filterHeight + selectAllHeight + lastDividerHeight;
    return height - heightOfOtherElements;
  }, [height]);

  if (isLoadingAllNewComers || isLoadingEmployeeTagsData) {
    return (
      <Box height={height}>
        <Loading size="large" />
      </Box>
    );
  }
  return (
    <Box height={height}>
      <Box py={3}>
        <Divider />
      </Box>
      <Box display="flex" gridGap={"16px"} height="40px">
        <Box width="20%">
          <RecruitmentStatusFilter
            selectedRecruitmentStatusIds={selectedRecruitmentStatuses.map((status) => status.id)}
            setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
            includeRejectedAndWithdrew={includeRejectedAndWithdrew}
          />
        </Box>
        <Box width="20%">
          <EmployeeTagFilter
            isLoading={isLoadingEmployeeTagsData}
            employeeTags={employeeTagsData?.employeeTags || []}
            selectedTagIds={selectedTagIds}
            onChange={(selectedTagIds: string[]) => {
              setSelectedTagIds(selectedTagIds);
            }}
          />
        </Box>
        <Box width="20%">
          <EmployeeFilter
            currentUser={currentUser}
            selectableEmployeeIds={allMentorIds}
            selectedEmployeeIds={selectedMentorIds}
            onChange={(selectedEmployeeIds: string[]) => {
              setSelectedMentorIds(selectedEmployeeIds);
            }}
            defaultLabel="担当者"
          />
        </Box>
        <Box width="40%">
          <StyledSearchFormWrapper>
            <SearchForm
              placeholder="ユーザー・メールアドレスで検索"
              variant="outlined"
              fullWidth
              searchValue={searchValue}
              onSearchValue={(value) => {
                setSearchValue(value);
              }}
            />
          </StyledSearchFormWrapper>
        </Box>
      </Box>
      <StyledSelectListContainer display="flex" flexDirection="column">
        <SelectList
          employees={filteredEmployees}
          selectedEmployees={selectedEmployees}
          onSelect={(employees: NewGraduateToDisplayForAdmin[]) => {
            setSelectedEmployees(employees);
          }}
          height={heightOfList}
        />
      </StyledSelectListContainer>
      <Box pt={3}>
        <Divider />
      </Box>
    </Box>
  );
};

const StyledSelectListContainer = styled(Box)`
  .MuiList-root {
    padding: 0;
  }
`;

const StyledSearchFormWrapper = styled(Box)`
  .MuiInputBase-root {
    height: 42px;
  }
`;
