import { format } from "date-fns";
import SuperJSON from "superjson";
import { v4 } from "uuid";

import { ConditionField } from "../api";

import {
  INewGraduatesCSVOutputConditionSchema,
  newGraduatesCSVOutputConditionSchema,
} from "./schema";

export class NewGraduatesCSVOutputCondition implements INewGraduatesCSVOutputConditionSchema {
  private static validator = newGraduatesCSVOutputConditionSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly executeEmployeeId: string;
  readonly condition: {
    employeeIds: string[];
    conditionFields: ConditionField[];
    scenarioIdSelections: string[];
  };

  readonly createdAt: Date;

  constructor(init: {
    id: string;
    tenantId: string;
    executeEmployeeId: string;
    condition: {
      employeeIds: string[];
      conditionFields: ConditionField[];
      scenarioIdSelections: string[];
    };
    createdAt: Date;
  }) {
    const parsedInit = NewGraduatesCSVOutputCondition.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.executeEmployeeId = parsedInit.executeEmployeeId;
    this.condition = parsedInit.condition;
    this.createdAt = parsedInit.createdAt;
  }

  public static create(
    params: Omit<ExcludeMethods<NewGraduatesCSVOutputCondition>, "id" | "createdAt" | "updatedAt">
  ) {
    return new NewGraduatesCSVOutputCondition({
      ...params,
      id: v4(),
      createdAt: new Date(),
    });
  }

  public serialize() {
    return SuperJSON.stringify(this);
  }

  public static deserialize(json: string) {
    return SuperJSON.parse<NewGraduatesCSVOutputCondition>(json);
  }

  generateCsvOutputConditionFileName() {
    return `${this.executeEmployeeId}-${format(new Date(), "yyyyMMddHHmmssSSS")}.json`;
  }

  static generateCsvOutputConditionFilePath({
    tenantId,
    spaceId,
    fileName,
  }: {
    tenantId: string;
    spaceId: string;
    fileName: string;
  }) {
    return `tenants/${tenantId}/new_graduates_with_recruitment_processes_csv_output_condition/${spaceId}/${fileName}`;
  }
}
