import { Stack } from "@mui/material";
import { OnnEventEvaluationTextField } from "@onn/common";
import React, { FC, useEffect } from "react";
import { useController } from "react-hook-form";

import { FieldValues } from "../../../hooks/form/FieldValues";

import { FieldLabelArea } from "./_shared/FieldLabelArea";

import { TextareaAutosize } from "~/components/uiParts";

export const OnnEventEvaluationTextFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: OnnEventEvaluationTextField;
  isDisabled: boolean;
  onResize?: () => void;
}> = ({ onnEventEvaluationField, fieldIndex, isDisabled, onResize }) => {
  const { field: valueField } = useController<
    FieldValues,
    `onnEventEvaluationValues.${number}.value`
  >({
    name: `onnEventEvaluationValues.${fieldIndex}.value`,
  });

  // FIXME: レンダリング回数が増えたり、resize が useEffect x setTimeout でしかできなくなるため、できれば他の方法を検討したい
  useEffect(() => {
    setTimeout(() => {
      onResize?.();
    }, 0); // onResize そのまま実行だとなぜかサイズ確定前になってしまうため１ループ遅らせる目的で setTimeout を使用
  }, [onResize]);

  return (
    <Stack spacing={"8px"}>
      <FieldLabelArea onnEventEvaluationField={onnEventEvaluationField} />
      <TextareaAutosize
        fullWidth
        value={valueField.value}
        placeholder={`入力してください`}
        onChange={(e) => {
          valueField.onChange(e.target.value);
          onResize?.();
        }}
        onResize={() => {
          onResize?.();
        }}
        minRows={4}
        disabled={isDisabled}
      />
    </Stack>
  );
};
