import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useState } from "react";

import styled from "styled-components";

import { SelectMultipleAcceptanceEmployeesForm } from "~/components/domains/employees/SelectAcceptanceEmployeesForm";

import { Button, Loading, Modal } from "~/components/uiParts";
import { useAllEmployeesWithDeleted, useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";

import { mixin } from "~/util";

type Props = {
  open: boolean;
  admins: Employee[];
  onCancel: () => void;
  onSubmit: (adminIds: string[]) => Promise<void>;
};

export const AddAdminsModal: FC<Props> = ({ open, admins, onCancel, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { isLoading: isEmployeeLoading, data: allEmployeesWithDeleted = [] } =
    useAllEmployeesWithDeleted(currentUser.tenantId);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    const existedAllEmployees = selectedEmails.flatMap(
      (email) => allEmployeesWithDeleted.find((v) => v.email === email) ?? []
    );

    if (existedAllEmployees.some((existedEmployee) => existedEmployee.deleted)) {
      enqueueSnackbar("削除されたユーザーを追加することはできません", {
        variant: "error",
      });
      return setIsLoading(false);
    }

    onSubmit([...admins.map((v) => v.id), ...existedAllEmployees.map((v) => v.id)])
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [selectedEmails, onSubmit, admins, allEmployeesWithDeleted, enqueueSnackbar, onCancel]);

  const Content = isEmployeeLoading ? (
    <Loading size="large" />
  ) : (
    <Box pr={3} pb={1} pl={3} width="100%" display="inline-block">
      <SelectMultipleAcceptanceEmployeesForm
        notAllowEmails={admins.map((v) => v.email)}
        selectedEmails={selectedEmails}
        onSelectEmails={setSelectedEmails}
        excludeRoles={[Role.ONLY_INTERVIEWER, Role.MEMBER]}
        isLoading={isEmployeeLoading}
      />
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isEmpty(selectedEmails) || isLoading}
      >
        {!isLoading ? "追加する" : "追加中"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="通知設定" content={Content} footer={footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
