import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationRank = z.infer<typeof OnnEventEvaluationRankSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankOptionalDefaultsSchema = OnnEventEvaluationRankSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventEvaluationRankOptionalDefaults = z.infer<
  typeof OnnEventEvaluationRankOptionalDefaultsSchema
>;

export default OnnEventEvaluationRankSchema;
