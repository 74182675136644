import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { useOfferAcceptanceDeadlineForm } from "./useOfferAcceptanceDeadlineForm";

import { Button, DatePickerV2, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";
type Props = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
  employeeId: string;
  offerAcceptanceDeadline?: Date;
};

export const EditOfferAcceptanceDeadlineModal: FC<Props> = ({
  open,
  onCancel,
  onSave,
  employeeId,
  offerAcceptanceDeadline,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isLoading, isSubmitting },
  } = useOfferAcceptanceDeadlineForm({
    employeeId,
    offerAcceptanceDeadline,
    onSubmit: () => {
      onSave();
      onCancel();
    },
  });

  const content = (
    <Stack gap="32px">
      <Box display="flex" justifyContent="center">
        <Typography variant="body1" color="textPrimary">
          内定承諾期日を設定してください。
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Controller
          name="offerAcceptanceDeadline"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledDatePicker
              fullWidth
              placeholder="日程を選択"
              error={!!error}
              value={value || null}
              onChange={(date) => {
                onChange(date);
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );

  const footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!isDirty || !isValid || isLoading || isSubmitting}
        isLoading={isLoading || isSubmitting}
      >
        保存
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="内定承諾期日設定"
      content={content}
      footer={footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    width: 400px;
    padding: 8px 12px 8px 12px;
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;
