import { AllContactRoom, Employee } from "@onn/common";
import { intersectionBy } from "lodash";
import { useMemo } from "react";

import { useFilterByIsInvited } from "./useFilterByIsInvited";
import { useFilterByNewGraduateIds } from "./useFilterByNewGraduateIds";

import { useSortContactRoomsV2 } from "~/components/domains/contactRooms/hooks";

type Props = {
  contactRooms: AllContactRoom[];
  currentUser: Employee;
  isDisplayOnlyMyCandidates: boolean;
  searchedNewGraduateIds: Set<string>;
};

/**
 * コンタクトルーム一覧のフィルターとソートをする
 */
export const useFilterAndSortContactRooms = ({
  contactRooms,
  currentUser,
  isDisplayOnlyMyCandidates,
  searchedNewGraduateIds,
}: Props) => {
  const { sortContactRooms: sortContactRoomsV2 } = useSortContactRoomsV2();

  const { filterByIsInvited } = useFilterByIsInvited();
  const { filterByNewGraduateIds } = useFilterByNewGraduateIds();

  /**
   * 検索フィルターと選考ステータスフィルターの積集合
   */
  const filteredContactRooms = useMemo(
    () =>
      intersectionBy(
        filterByIsInvited(contactRooms),
        filterByNewGraduateIds(contactRooms, searchedNewGraduateIds)
      ),
    [contactRooms, filterByIsInvited, filterByNewGraduateIds, searchedNewGraduateIds]
  );

  const currentContactRooms = useMemo(() => {
    // FIXME: 並び替えをバックエンドで行うため、sortContactRoomsV2 の利用をやめる
    // 現在はリアルタイム更新時にバックエンドに再リクエストすると重すぎるため、フロントエンドで並び替えをしている
    const tempResult = sortContactRoomsV2(filteredContactRooms);
    if (isDisplayOnlyMyCandidates) {
      return tempResult.filter((room) => {
        return (
          room.employee?.mentorUserId === currentUser.id ||
          room.employee?.supportMemberEmployeeIds?.includes(currentUser.id)
        );
      });
    } else {
      return tempResult;
    }
  }, [sortContactRoomsV2, filteredContactRooms, isDisplayOnlyMyCandidates, currentUser.id]);

  return {
    currentContactRooms,
  };
};
