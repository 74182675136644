import { AnyOnnEventEvaluationField } from "@onn/common";
import React, { FC } from "react";

import { OnnEventEvaluationFileFieldAndValue } from "./onnEventEvaluationFieldAndValue/OnnEventEvaluationFileFieldAndValue";
import { OnnEventEvaluationMultipleSelectFieldAndValue } from "./onnEventEvaluationFieldAndValue/OnnEventEvaluationMultipleSelectFieldAndValue";
import { OnnEventEvaluationSingleSelectFieldAndValue } from "./onnEventEvaluationFieldAndValue/OnnEventEvaluationSingleSelectFieldAndValue";
import { OnnEventEvaluationTextFieldAndValue } from "./onnEventEvaluationFieldAndValue/OnnEventEvaluationTextFieldAndValue";

import { useCurrentUser } from "~/hooks/employee";

export const OnnEventEvaluationFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: AnyOnnEventEvaluationField;
  setIsUploadingFile: (isUploadingFile: boolean) => void;
  isUploadingFile: boolean;
  onResize?: () => void;
}> = ({ fieldIndex, onnEventEvaluationField, setIsUploadingFile, isUploadingFile, onResize }) => {
  const { currentUser } = useCurrentUser();
  const isDisabled = currentUser.isAdmin()
    ? false
    : !onnEventEvaluationField.isEditable(currentUser);
  switch (onnEventEvaluationField.type) {
    case "TextField":
      return (
        <OnnEventEvaluationTextFieldAndValue
          fieldIndex={fieldIndex}
          onnEventEvaluationField={onnEventEvaluationField}
          isDisabled={isDisabled}
          onResize={onResize}
        />
      );
    case "FileField":
      return (
        <OnnEventEvaluationFileFieldAndValue
          fieldIndex={fieldIndex}
          onnEventEvaluationField={onnEventEvaluationField}
          setIsUploadingFile={setIsUploadingFile}
          isUploadingFile={isUploadingFile}
          onResize={onResize}
        />
      );
    case "SingleSelectField":
      return (
        <OnnEventEvaluationSingleSelectFieldAndValue
          fieldIndex={fieldIndex}
          onnEventEvaluationField={onnEventEvaluationField}
          isDisabled={isDisabled}
        />
      );
    case "MultipleSelectField":
      return (
        <OnnEventEvaluationMultipleSelectFieldAndValue
          fieldIndex={fieldIndex}
          onnEventEvaluationField={onnEventEvaluationField}
          isDisabled={isDisabled}
        />
      );
    default: {
      const _exhaustiveCheck: never = onnEventEvaluationField;
      return _exhaustiveCheck;
    }
  }
};
