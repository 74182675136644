import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { PortalListItem, Typography } from "~/components/uiParts";
import { useContactContextForPortal } from "~/hooks/contactMessage/useContactContext";

export const ContactRoomsIndex: FC = () => {
  const { contactRoom, unReadCount } = useContactContextForPortal();

  return (
    <Box pt="40px" px="24px" pb="40px">
      <Typography variant="h3" color="textPrimary">
        Contact
      </Typography>
      <Typography variant="caption" color="textPrimary">
        {/* TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf) */}
        聞きたいことや相談したいことがある方は、こちらから人事・受け入れ担当者へ問い合わせましょう。
      </Typography>
      <Box mt="24px">
        {contactRoom && (
          <Box key={contactRoom.id} mt="24px">
            <PortalListItem
              title="会社とのメッセージ"
              to={`/portal/contact_rooms/${contactRoom.id}`}
              badgeLabel={0 < unReadCount ? unReadCount.toString() : undefined}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
