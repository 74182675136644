import { Employee, NewGraduateToDisplayForAdmin, RecruitmentStatus } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";

import { captureException } from "~/util";

/**
 * 新卒候補者をフィルターするhooks
 */
export const useFilterNewGraduates = (
  newcomers: NewGraduateToDisplayForAdmin[],
  filter?: (employee: NewGraduateToDisplayForAdmin) => boolean
) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const [selectedRecruitmentStatuses, setSelectedRecruitmentStatuses] = useState<
    RecruitmentStatus[]
  >([]);
  const setSelectedRecruitmentStatusIds = useCallback(
    (recruitmentStatusIds: string[]) => {
      const filteredRecruitmentStatuses = recruitmentStatusIds.flatMap((id) => {
        const recruitmentStatus = recruitmentStatuses.find((status) => status.id === id);
        if (!recruitmentStatus) {
          captureException({
            error: new Error(
              `新卒候補者・内定者フィルターでエラーが起きました。recruitmentStatusId: ${id} is not found`
            ),
            tags: { type: "useFilterNewGraduates" },
          });

          return [];
        }
        return recruitmentStatus;
      });

      setSelectedRecruitmentStatuses(filteredRecruitmentStatuses);
    },
    [recruitmentStatuses]
  );

  const [selectedMentorIds, setSelectedMentorIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const allMentorIds = useMemo(
    () => [...new Set(newcomers?.flatMap((employee) => employee.mentorUserId ?? []))],
    [newcomers]
  );

  const isNotMatchByStatuses: (_e: NewGraduateToDisplayForAdmin) => boolean = useCallback(
    (employee) => {
      if (isEmpty(selectedRecruitmentStatuses)) return false;

      const recruitmentStatus = selectedRecruitmentStatuses.find((status) =>
        employee.scenarios.some((scenario) => scenario.recruitmentStatusId === status.id)
      );
      return !recruitmentStatus;
    },
    [selectedRecruitmentStatuses]
  );

  const isNotMatchedByFilter: (_e: NewGraduateToDisplayForAdmin) => boolean = useCallback(
    (employee) => {
      if (!filter) return false;

      return !filter(employee);
    },
    [filter]
  );

  const isNotMatchBySearchValue: (_e: NewGraduateToDisplayForAdmin) => boolean = useCallback(
    (employee) => {
      if (!searchValue) return false;

      return !(employee.getName().includes(searchValue) || employee.email.includes(searchValue));
    },
    [searchValue]
  );

  const isNotMatchByTagIds: (_e: NewGraduateToDisplayForAdmin) => boolean = useCallback(
    (employee) => {
      if (isEmpty(selectedTagIds)) return false;

      const employeeTagIdsSet = new Set(employee.employeeTagIds ?? []);
      return !selectedTagIds.some((tagId) => employeeTagIdsSet.has(tagId));
    },
    [selectedTagIds]
  );

  const isNotMatchByMentorUserId: (_e: Employee) => boolean = useCallback(
    (employee) => {
      if (isEmpty(selectedMentorIds)) return false;

      return !selectedMentorIds.includes(employee.mentorUserId ?? "");
    },
    [selectedMentorIds]
  );

  const filteredEmployees = useMemo(() => {
    return newcomers?.filter((employee) => {
      const isNotMatched =
        isNotMatchByStatuses(employee) ||
        isNotMatchedByFilter(employee) ||
        isNotMatchBySearchValue(employee) ||
        isNotMatchByTagIds(employee) ||
        isNotMatchByMentorUserId(employee);
      // trueが返ってきたら短絡評価し、後続の評価を行わない
      // フィルター条件がsetされていない場合は、各関数はskipとしてfalseを返す

      return !isNotMatched;
    });
  }, [
    newcomers,
    isNotMatchByStatuses,
    isNotMatchedByFilter,
    isNotMatchBySearchValue,
    isNotMatchByTagIds,
    isNotMatchByMentorUserId,
  ]);

  return {
    allMentorIds,
    filteredEmployees,
    selectedRecruitmentStatuses,
    selectedMentorIds,
    selectedTagIds,
    setSelectedRecruitmentStatuses,
    setSelectedRecruitmentStatusIds,
    setSelectedMentorIds,
    searchValue,
    setSearchValue,
    setSelectedTagIds,
  };
};
