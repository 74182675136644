import { LineUser } from "../../Line";
import { ContactRoomWithEmployee } from "../ContactRoomWithEmployee/ContactRoomWithEmployee";

// Lineユーザーコンタクトルーム向けのクラス
export class LineContactRoom extends ContactRoomWithEmployee {
  readonly kind = "LineContactRoom" as const;
  lineUser: LineUser;

  constructor({
    initSuper,
    lineUser,
  }: {
    initSuper: ConstructorParameters<typeof ContactRoomWithEmployee>[0];
    lineUser: LineUser;
  }) {
    super(initSuper);
    this.lineUser = lineUser;
  }

  // ルーム名を取得するメソッド
  public getRoomName() {
    if (this.employee) {
      return this.employee.getName();
    }
    return this.lineUser.displayName;
  }

  public getHonorificRoomName(): string {
    return `${this.getRoomName()}さん`;
  }

  // ルームアイコンを取得するメソッド
  public getRoomImageUrl(): string | undefined {
    if (this.employee) {
      return this.employee.profileIconImageUrl;
    }
    return this.lineUser.pictureUrl;
  }
}
