import { ContactMessageDraft } from "@onn/common";

import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useCurrentUser } from "~/hooks/employee";

export const useContactMessageDraft = ({
  selectedContactRoomId,
}: {
  selectedContactRoomId?: string;
}) => {
  const { currentUser } = useCurrentUser();

  const {
    contactMessageDrafts,
    isLoading: isLoadingDraft,
    saveContactMessageDraft,
  } = useContactMessageDraftByCreatedUserId(currentUser.id);

  const contactMessageDraft = selectedContactRoomId
    ? contactMessageDrafts.find(({ contactRoomId }) => contactRoomId === selectedContactRoomId) ??
      ContactMessageDraft.create({
        tenantId: currentUser.tenantId,
        createdUserId: currentUser.id,
        contactRoomId: selectedContactRoomId,
        text: "",
      })
    : undefined;

  return { contactMessageDrafts, isLoadingDraft, saveContactMessageDraft, contactMessageDraft };
};
