import { Box } from "@material-ui/core";
import { Stack, StackProps } from "@mui/material";
import {
  BriefingSessionEvent,
  Employee,
  NewGraduate,
  NewInterviewEvent,
  NextPlan,
  OnnEvent,
  OnnEventDeterminedDate,
  OnnEventSlotDate,
} from "@onn/common";

import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, memo, useCallback, useContext, useMemo } from "react";

import { EditOnnEventEvaluationDrawerContext } from "~/components/domains/events/EditOnnEventEvaluation/EditOnnEventEvaluationDrawer/providers/EditOnnEventEvaluationDrawerProvider";

import { Icon, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type Props = {
  newGraduate: NewGraduate;
  acceptanceEmployeeIdToEmployeeMap: Map<string, Employee>;
  onSaveNextPlan: () => void;
  nextPlan?: NextPlan;
};

export const NewGraduateNextPlan: FC<Props> = memo(
  ({ newGraduate, acceptanceEmployeeIdToEmployeeMap, onSaveNextPlan, nextPlan }) => {
    const { handleModal } = useModal();
    const { currentUser } = useCurrentUser();
    const isAdmin = currentUser.isAdmin();
    const isRegularAcceptanceEmployee = currentUser.isRegularAcceptanceEmployee();

    const { open: openEvaluationDrawer } = useContext(EditOnnEventEvaluationDrawerContext);

    const openAnswerEventOnBehalfModal = useCallback(
      ({ onnEvent }: { onnEvent: BriefingSessionEvent | NewInterviewEvent }) => {
        handleModal({
          name: "answerEventOnBehalfModal",
          args: {
            onnEvent,
            fixedOption: {
              selectedEmployeeId: newGraduate.id,
            },
            mode: {
              type: "create",
            },
            slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
            onSave: onSaveNextPlan,
          },
        });
      },
      [handleModal, newGraduate.id, onSaveNextPlan]
    );

    const openEditEventOnBehalfModal = useCallback(
      ({
        onnEvent,
        briefingSessionCategoryId,
        onnEventSlotDateId,
      }: {
        onnEvent: BriefingSessionEvent | NewInterviewEvent;
        briefingSessionCategoryId?: string;
        onnEventSlotDateId: string;
      }) => {
        handleModal({
          name: "answerEventOnBehalfModal",
          args: {
            onnEvent,
            fixedOption: {
              selectedEmployeeId: newGraduate.id,
            },
            mode: {
              type: "edit",
              onnEventSlotDateId,
              briefingSessionCategoryId,
            },
            slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
            onSave: onSaveNextPlan,
          },
        });
      },
      [handleModal, newGraduate.id, onSaveNextPlan]
    );

    const openEvaluationModal = useCallback(
      ({ onnEventId }: { onnEventId: string }) => {
        openEvaluationDrawer({
          onnEventId,
          newGraduateId: newGraduate.id,
        });
      },
      [newGraduate.id, openEvaluationDrawer]
    );

    const openEditOfferAcceptanceDeadlineModal = useCallback(
      ({ offerAcceptanceDeadline }: { offerAcceptanceDeadline?: Date }) => {
        handleModal({
          name: "editOfferAcceptanceDeadlineModal",
          args: {
            employeeId: newGraduate.id,
            offerAcceptanceDeadline,
            onSave: onSaveNextPlan,
          },
        });
      },
      [handleModal, newGraduate.id, onSaveNextPlan]
    );

    const content = useMemo(() => {
      if (!nextPlan) {
        return null;
      }

      switch (nextPlan.type) {
        case "AnswerOfEvent": {
          const { onnEvent } = nextPlan;
          const isDisplayOpenAnswerEventOnBehalf =
            onnEvent.isNewInterviewEvent() || onnEvent.isBriefingSessionEvent();
          const handleClick: StackProps["onClick"] =
            isAdmin && isDisplayOpenAnswerEventOnBehalf
              ? (e) => {
                  e.preventDefault();
                  openAnswerEventOnBehalfModal({ onnEvent });
                }
              : undefined;
          return (
            <Stack
              rowGap="8px"
              overflow="hidden"
              style={{ cursor: handleClick ? "pointer" : undefined }}
              onClick={handleClick}
            >
              <Typography variant="caption" bold color="textPrimary" noWrap>
                {nextPlan.onnEvent.title}
              </Typography>
              {isDisplayOpenAnswerEventOnBehalf ? (
                <Box display="flex" alignItems="center" gridColumnGap="4px">
                  <Icon icon="warning" size="md" color="secondary" />
                  <Typography variant="caption" bold color="secondary">
                    詳細を設定
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant="caption"
                  bold
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  未設定
                </Typography>
              )}
            </Stack>
          );
        }
        case "StartOfEvent": {
          const eventStartDate = getDisplayEventStartDate({
            onnEvent: nextPlan.onnEvent,
            onnDeterminedDate: nextPlan.onnDeterminedDate,
            onnEventSlotDate: nextPlan.onnEventSlotDate,
          });
          const assigneeId = getDisplayAssigneeId({
            onnEvent: nextPlan.onnEvent,
            onnEventSlotDate: nextPlan.onnEventSlotDate,
          });
          const assignee = assigneeId ? acceptanceEmployeeIdToEmployeeMap.get(assigneeId) : null;
          const { onnEvent, onnEventSlotDate } = nextPlan;
          const isDisplayOpenAnswerEventOnBehalf =
            (onnEvent.isNewInterviewEvent() || onnEvent.isBriefingSessionEvent()) &&
            !!onnEventSlotDate;
          const handleClick: StackProps["onClick"] =
            isAdmin && isDisplayOpenAnswerEventOnBehalf
              ? (e) => {
                  e.preventDefault();
                  openEditEventOnBehalfModal({
                    onnEvent,
                    briefingSessionCategoryId:
                      onnEventSlotDate.briefingSessionCategoryId || undefined,
                    onnEventSlotDateId: onnEventSlotDate.id,
                  });
                }
              : undefined;
          return (
            <Stack
              rowGap="8px"
              overflow="hidden"
              style={{ cursor: handleClick ? "pointer" : undefined }}
              onClick={handleClick}
            >
              <Typography variant="caption" bold color="textPrimary" noWrap>
                {nextPlan.onnEvent.title}
              </Typography>
              {eventStartDate && (
                <Typography variant="caption" color="textPrimary">
                  {format(eventStartDate, "MM/dd(E) HH:mm", { locale: ja })}
                </Typography>
              )}
              <UserIcon
                username={assignee ? assignee.getName() : ""}
                profileIconImageUrl={assignee?.profileIconImageUrl}
                size="extraSmall"
                circular
              />
            </Stack>
          );
        }
        case "EvaluationOfEvent": {
          const assigneeId = getDisplayAssigneeId({
            onnEvent: nextPlan.onnEvent,
            onnEventSlotDate: nextPlan.onnEventSlotDate,
          });
          const assignee = assigneeId ? acceptanceEmployeeIdToEmployeeMap.get(assigneeId) : null;
          const handleClick: StackProps["onClick"] = (e) => {
            e.preventDefault();
            openEvaluationModal({
              onnEventId: nextPlan.onnEvent.id,
            });
          };

          return (
            <Stack
              rowGap="8px"
              overflow="hidden"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <Typography variant="caption" bold color="textPrimary" noWrap>
                {nextPlan.onnEvent.title}
              </Typography>
              <Box display="flex" alignItems="center" gridColumnGap="4px">
                <UserIcon
                  username={assignee ? assignee.getName() : ""}
                  profileIconImageUrl={assignee?.profileIconImageUrl}
                  size="extraSmall"
                  circular
                />
                <Typography variant="caption" bold color="secondary">
                  評価を入力
                </Typography>
              </Box>
            </Stack>
          );
        }
        case "OfferAcceptance": {
          return (
            <Stack
              rowGap="8px"
              overflow="hidden"
              style={{ cursor: "pointer" }}
              onClick={
                isRegularAcceptanceEmployee
                  ? (e) => {
                      e.preventDefault();
                      openEditOfferAcceptanceDeadlineModal({
                        offerAcceptanceDeadline: nextPlan.deadline ? nextPlan.deadline : undefined,
                      });
                    }
                  : undefined
              }
            >
              <Typography variant="caption" bold color="textPrimary">
                内定承諾期日
              </Typography>
              {nextPlan.deadline ? (
                <Typography variant="caption" color="textPrimary">
                  {format(nextPlan.deadline, "MM/dd(E)", { locale: ja })}
                </Typography>
              ) : (
                <Box display="flex" alignItems="center" gridColumnGap="4px">
                  <Icon icon="warning" size="md" color="secondary" />
                  <Typography variant="caption" bold color="secondary">
                    内定承諾期日を設定
                  </Typography>
                </Box>
              )}
            </Stack>
          );
        }
        default: {
          const _exhaustiveCheck: never = nextPlan;
          return null;
        }
      }
    }, [
      acceptanceEmployeeIdToEmployeeMap,
      isAdmin,
      isRegularAcceptanceEmployee,
      nextPlan,
      openAnswerEventOnBehalfModal,
      openEditEventOnBehalfModal,
      openEditOfferAcceptanceDeadlineModal,
      openEvaluationModal,
    ]);

    return (
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      >
        {content}
      </Box>
    );
  }
);

const getDisplayEventStartDate = ({
  onnEvent,
  onnDeterminedDate,
  onnEventSlotDate,
}: {
  onnEvent: OnnEvent;
  onnDeterminedDate: OnnEventDeterminedDate;
  onnEventSlotDate: OnnEventSlotDate | null;
}): Date | null => {
  switch (onnEvent.type) {
    case "normal": {
      const candidateDate = onnEvent.getCandidateDatesById(onnDeterminedDate.candidateDateId);

      return candidateDate?.from ?? null;
    }
    case "briefing_session":
    case "new_interview": {
      return onnEventSlotDate?.from ?? null;
    }

    default: {
      const _exhaustiveCheck: never = onnEvent.type;
      return null;
    }
  }
};

const getDisplayAssigneeId = ({
  onnEvent,
  onnEventSlotDate,
}: {
  onnEvent: OnnEvent;
  onnEventSlotDate: OnnEventSlotDate | null;
}): string | null => {
  switch (onnEvent.type) {
    case "normal": {
      return onnEvent.assigneeIds[0] ?? null;
    }
    case "briefing_session":
    case "new_interview": {
      return onnEventSlotDate?.assigneeId ?? null;
    }

    default: {
      const _exhaustiveCheck: never = onnEvent.type;
      return null;
    }
  }
};
