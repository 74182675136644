import { NewGraduate, OnnFormTaskAnswer } from "@onn/common";

import {
  CheckBoxAnswer,
  FileAnswer,
  RadioAnswer,
  TextAnswer,
} from "@onn/common/domain/OnnTask/Answer/Answer";
import { format } from "date-fns";

import { SortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

import { removeUnusableCharacters } from "~/util/fileUtil";

export const useCreateCSVData = ({
  onnFormTaskAnswers,
  taskTitle,
  sortedRevisionQuestionDataList,
  targetNewGraduates,
}: {
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  taskTitle: string;
  sortedRevisionQuestionDataList: SortedRevisionQuestionDataList;
  targetNewGraduates: NewGraduate[];
}) => {
  const { answeredCount, notAnsweredCount } = getAnsweredCounts(onnFormTaskAnswers);

  const generateCsvRow = (answer: OnnFormTaskAnswer) => {
    const newGraduate = targetNewGraduates.find(
      (newGraduate) => newGraduate.id === answer.employeeId
    );
    const answerStatus = (() => {
      if (answer.isAnswered()) return "回答済み";
      if (answer.lastReadAt) return format(answer.lastReadAt, "yyyy/MM/dd 既読");
      return "";
    })();

    return [
      `${newGraduate?.uniqueId || ""}`,
      `${newGraduate?.getName() || ""}`,
      `${newGraduate?.email || ""}`,
      `${answerStatus}`,
      `${answer.lastAnsweredAt ? format(answer.lastAnsweredAt, "yyyy/MM/dd HH:mm:ss") : ""}`,
      ...sortedRevisionQuestionDataList.map((data) => {
        if (data.question.type === "TEXT") {
          const a = answer.answers.find((a) => a.questionId === data.question.id);
          if (!a) return "";
          return (a as TextAnswer).value;
        }
        if (data.question.type === "CHECK_BOX") {
          const a = answer.answers.find((a) => a.questionId === data.question.id);
          if (!a) return "";
          const options = data.question.options.filter((option) =>
            (a as CheckBoxAnswer).selectedOptionIds.includes(option.id)
          );
          return options.map((option) => option.text).join("/");
        }
        if (data.question.type === "RADIO") {
          const a = answer.answers.find((a) => a.questionId === data.question.id);
          if (!a) return "";
          const option = data.question.options.find(
            (option) => option.id === (a as RadioAnswer).selectedOptionId
          );
          return option?.text || "";
        }
        if (data.question.type === "FILE") {
          const a = answer.answers.find((a) => a.questionId === data.question.id);
          if (!a) return "";
          return (a as FileAnswer).filePath.split("/").pop() || "";
        }
        return "";
      }),
    ];
  };

  const csvData = [
    [
      `回答数:${answeredCount}/未回答:${notAnsweredCount}`,
      `氏名`,
      `メールアドレス`,
      `回答状況`,
      `回答日時`,
      ...sortedRevisionQuestionDataList.map((data) => `${data.question.title}`),
    ],
    ...onnFormTaskAnswers.map((answer) => generateCsvRow(answer)),
  ];

  const filename = `フォームタスク回答結果_${removeUnusableCharacters(taskTitle)}`;

  return { csvData, filename };
};

const getAnsweredCounts = (onnFormTaskAnswers: OnnFormTaskAnswer[]) => {
  const answeredCount = onnFormTaskAnswers.filter((onnFormTaskAnswer) =>
    onnFormTaskAnswer.isAnswered()
  ).length;
  const notAnsweredCount = onnFormTaskAnswers.length - answeredCount;
  return { answeredCount, notAnsweredCount };
};
