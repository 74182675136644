import React, { FC, ReactNode } from "react";

import { ContactProviderForAdminSite } from "./ContactContextForAdminSite";

import { ContactProviderForPortal } from "./ContactContextForPortal";

import { useCurrentUser } from "~/hooks/employee";

export const ContactProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  if (currentUser.isNewGraduateEmployee()) {
    return <ContactProviderForPortal>{children}</ContactProviderForPortal>;
  } else {
    return <ContactProviderForAdminSite>{children}</ContactProviderForAdminSite>;
  }
};
