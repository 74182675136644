import { Employee } from "@onn/common";
import React, { FC, useEffect, useMemo, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

import { useAcceptanceEmployees } from "~/hooks/employee";

type Props = {
  notAllowEmails: string[];
  selectedEmail?: string;
  onSelectEmail: (email: string) => void;
  isLoading: boolean;
};

export const SelectSingleAcceptanceEmployeeForm: FC<Props> = ({
  notAllowEmails,
  selectedEmail,
  onSelectEmail,
  isLoading,
}) => {
  const { data: acceptanceEmployees } = useAcceptanceEmployees();

  const regularAcceptanceEmployees = useMemo(
    () => (acceptanceEmployees || []).filter((v) => v.isRegularAcceptanceEmployee()),
    [acceptanceEmployees]
  );

  const employeesForSearch = useMemo(() => {
    return regularAcceptanceEmployees ?? [];
  }, [regularAcceptanceEmployees]);

  /**
   * クライアント検索処理で再構成される表示用の配列
   * 新卒向けテナントの場合はMEMBER権限のEmployee(入社者を含まない)、中途向けテナントの場合は全てのEmployee(入社者を含む)を対象とする
   */
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(
    regularAcceptanceEmployees ?? []
  );

  useEffect(() => {
    setDisplayEmployees(regularAcceptanceEmployees ?? []);
  }, [regularAcceptanceEmployees]);

  return (
    <>
      <EmployeeSearchForm
        employees={employeesForSearch}
        onSearch={(results) => setDisplayEmployees(results)}
      />
      <EmployeeList
        employees={displayEmployees.filter((v) => !notAllowEmails.includes(v.email))}
        selectedEmployee={employeesForSearch.find((v) => selectedEmail === v.email)}
        onSelect={(employee) => onSelectEmail(employee.email)}
        isMultiple={false}
        isLoading={isLoading}
      />
    </>
  );
};
