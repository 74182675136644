import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { SelectSingleAcceptanceEmployeeForm } from "../SelectAcceptanceEmployeesForm";

import { Button, Modal, Typography, UserIcon } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  newGraduate: Employee;
  mentor?: Employee;
  onCancel: () => void;
  onSubmit: (newMentorId?: string, emailForInvite?: string) => Promise<void>;
};

export const AssignNewGraduateMentorModal: FC<Props> = ({
  open,
  newGraduate,
  mentor,
  onCancel,
  onSubmit,
}) => {
  const { data: acceptanceEmployees, isLoading: isLoadingAcceptanceEmployees } =
    useAcceptanceEmployees();

  const notAllowEmails = useMemo(() => {
    // 自分自身、フォロワーのemailから成る配列
    return [newGraduate.email, ...(mentor?.email ? [mentor?.email] : [])];
  }, [mentor?.email, newGraduate.email]);

  const [selectedEmail, setSelectedEmail] = useState<string | undefined>();

  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!selectedEmail) return;
    const newMentor = (acceptanceEmployees || []).find((v) => v.email === selectedEmail);
    const emailForInvite = selectedEmail;

    setSending(true);
    await onSubmit(newMentor?.id, emailForInvite)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [acceptanceEmployees, onCancel, onSubmit, selectedEmail]);

  const buildButtonText = () =>
    newGraduate.mentorUserId
      ? sending
        ? "変更中"
        : "担当者を変更する"
      : sending
      ? "設定中"
      : "担当者を設定する";

  return (
    <Modal
      open={open}
      title="担当者設定"
      content={
        <Box>
          <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="16px">
            <Box width="100%">
              <Box textAlign="center">
                <UserIcon
                  username={newGraduate.getName()}
                  profileIconImageUrl={newGraduate.profileIconImageUrl}
                  size="medium"
                  borderColor="primary"
                />
                <Box pb={2} />
                <Typography variant="body2" align="center">
                  {`${newGraduate.getName()}さんの担当者を\n設定してください`}
                </Typography>
              </Box>
            </Box>
            <Box width="100%">
              <SelectSingleAcceptanceEmployeeForm
                notAllowEmails={notAllowEmails}
                selectedEmail={selectedEmail}
                onSelectEmail={setSelectedEmail}
                isLoading={isLoadingAcceptanceEmployees}
              />
            </Box>
          </Box>
          <Typography variant="caption" color="textSecondary" align="center" display="block">
            Onnに登録されていないメンバーを設定したい場合は、
            <StyledPointerTypography
              display="inline"
              variant="caption"
              bold
              color="primary"
              onClick={() => window.open("/settings/admin", "_blank")}
            >
              こちら
            </StyledPointerTypography>
            から招待を行ってください。
          </Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedEmail || sending}
          >
            {buildButtonText()}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
