import { v4 } from "uuid";

import { IOnnEventEvaluationRank, onnEventEvaluationRankSchema } from "./schema";

export class OnnEventEvaluationRank implements IOnnEventEvaluationRank {
  static validator = onnEventEvaluationRankSchema;

  id: string;
  tenantId: string;
  onnEventId: string;
  label: string;
  order: number;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationRank>) {
    const parsedInit = OnnEventEvaluationRank.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventId = parsedInit.onnEventId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<OnnEventEvaluationRank>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluationRank {
    return new OnnEventEvaluationRank({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createDefaultRanks(params: {
    onnEventId: string;
    tenantId: string;
    spaceId: string;
  }): OnnEventEvaluationRank[] {
    const defaultRankLabels = ["A", "B", "C"];
    return defaultRankLabels.map((label, index) =>
      OnnEventEvaluationRank.createNew({
        label,
        order: index + 1,
        ...params,
      })
    );
  }
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationRank>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationRank {
    return new OnnEventEvaluationRank({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(params: Pick<OnnEventEvaluationRank, "label" | "order">): OnnEventEvaluationRank {
    return new OnnEventEvaluationRank({
      ...this,
      label: params.label,
      order: params.order,
      updatedAt: new Date(),
    });
  }
}
