import {
  APISchema,
  instantiateFromAnyOnnEventEvaluationValueExcludeMethods,
  OnnEventEvaluationRank,
} from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-evaluations"]["GET"];

const generateKey = (arg: { tenantId: string; onnEventId: string; targetEmployeeId: string }) => {
  return {
    endpoint: "/api/onn-event-evaluations",
    ...arg,
  } as const;
};

export const useOnnEventEvaluation = ({
  onnEventId,
  targetEmployeeId,
}: {
  onnEventId: string;
  targetEmployeeId: string;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
      targetEmployeeId,
    }),
    async ({ endpoint, onnEventId }) => {
      const query: Endpoint["query"] = { onnEventId, targetEmployeeId };
      const response = await apiClient.get(endpoint, query);

      return {
        onnEventEvaluationRank: response.data.onnEventEvaluationRank
          ? new OnnEventEvaluationRank(response.data.onnEventEvaluationRank)
          : undefined,
        onnEventEvaluationFields: response.data.onnEventEvaluationValues.map((d) =>
          instantiateFromAnyOnnEventEvaluationValueExcludeMethods(d)
        ),
      };
    }
  );
};

export const useMutateOnnEventEvaluation = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventEvaluation = useCallback(
    ({ onnEventId, targetEmployeeId }: { onnEventId: string; targetEmployeeId: string }) => {
      mutate(generateKey({ tenantId: currentUser.tenantId, onnEventId, targetEmployeeId }));
    },
    [currentUser.tenantId]
  );

  return { mutateOnnEventEvaluation };
};
