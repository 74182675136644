import { Employee } from "@onn/common";
import React from "react";

import { Typography, UserIcon } from "~/components/uiParts";

export const MultipleMenuText = ({ selectedEmployees }: { selectedEmployees: Employee[] }) => {
  const firstEmployee = selectedEmployees[0];
  if (!firstEmployee) {
    return <Typography variant="body2">メンバーを選択</Typography>;
  }

  if (selectedEmployees.length > 1) {
    return (
      <Typography variant="body2" noWrap>
        {selectedEmployees.length}名のメンバー
      </Typography>
    );
  }

  return (
    <>
      <UserIcon
        username={firstEmployee.getName()}
        profileIconImageUrl={firstEmployee.profileIconImageUrl}
        size="extraSmall"
        circular
      />
      <Typography variant="body2" noWrap>
        {firstEmployee.getName()}
      </Typography>
    </>
  );
};
