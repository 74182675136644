import {
  AllContactRoom,
  ChangeAuthenticationLog,
  ContactMessage,
  LineUser,
  NewGraduateToDisplayForAdmin,
  SystemMessage,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useMemo } from "react";

import {
  ChangeAuthenticationLogMessage,
  MessageWhenLineBlocked,
  MessageWhenNeverFollowed,
  MessageWhenRoomIsClosed,
  MessageWhenUserDeleted,
  MessageWhenWithdrawalLineGroup,
  NoMessages,
} from "./MessageItems";

import { NewGraduateContactMessageItem } from "~/components/domains/contactMessage/contactMessageItem/NewGraduateContactMessageItem";

import { SystemMessageItem } from "~/components/domains/systemMessage/SystemMessageItem";

export type ContactMessagesForDisplay =
  | (ContactMessage & { creatorName: string; creatorImageUrl: string | undefined })
  | SystemMessage
  | {
      lineUser: LineUser;
      type: "LEAVE";
      lineUserId: string;
      createdAt: Date;
    }
  | ChangeAuthenticationLog;

export const MessageListView: FC<{
  contactMessages: ContactMessagesForDisplay[];
  newGraduate?: NewGraduateToDisplayForAdmin;
  messageId: string | undefined;
  isFileSending: boolean;
  tenantName: string;
  contactRoom: AllContactRoom;
  isRoomTargetEmployeeDeleted?: boolean;
  isContactRoomClosed?: boolean;
}> = ({
  contactMessages,
  newGraduate,
  messageId,
  isFileSending,
  tenantName,
  contactRoom,
  isRoomTargetEmployeeDeleted,
  isContactRoomClosed,
}) => {
  const messageListView = useMemo(() => {
    const baseMessageItems = (
      <BaseMessages
        contactMessages={contactMessages}
        newGraduate={newGraduate || undefined}
        messageId={messageId}
        isFileSending={isFileSending}
        tenantName={tenantName}
      />
    );

    // コンタクトルームが利用終了になっている場合
    if (isContactRoomClosed) {
      return (
        <>
          {baseMessageItems}
          <MessageWhenRoomIsClosed />
        </>
      );
    }
    // ユーザーが削除されている場合
    if (isRoomTargetEmployeeDeleted) {
      return (
        <>
          {baseMessageItems}
          <MessageWhenUserDeleted />
        </>
      );
    }

    // LINEユーザーが一度も公式アカウントをフォローしていない場合
    if ("lineUser" in contactRoom && contactRoom.lineUser.isNeverFollowedLineOfficialAccount()) {
      return (
        <>
          {baseMessageItems}
          <MessageWhenNeverFollowed />
        </>
      );
    }

    // LINEユーザーが公式アカウントをブロックしている場合
    if ("lineUser" in contactRoom && contactRoom.lineUser.isUnFollow) {
      return (
        <>
          {baseMessageItems}
          <MessageWhenLineBlocked />
        </>
      );
    }

    // メッセージが存在しない場合
    if (isEmpty(contactMessages)) {
      return <NoMessages />;
    }

    return baseMessageItems;
  }, [
    contactMessages,
    newGraduate,
    messageId,
    isFileSending,
    tenantName,
    isContactRoomClosed,
    isRoomTargetEmployeeDeleted,
    contactRoom,
  ]);

  return messageListView;
};

const BaseMessages: FC<{
  contactMessages: ContactMessagesForDisplay[];
  newGraduate?: NewGraduateToDisplayForAdmin;
  messageId: string | undefined;
  isFileSending: boolean;
  tenantName: string;
}> = ({ contactMessages, newGraduate, messageId, isFileSending, tenantName }) => {
  return (
    <>
      {contactMessages.map((messageOrLog) => {
        // システムメッセージの場合
        if (messageOrLog instanceof SystemMessage) {
          return (
            <SystemMessageItem
              key={messageOrLog.id}
              systemMessage={messageOrLog}
              tenantName={tenantName}
              isDisabledButton
              variant="right"
            />
          );
        }

        // 認証切り替えログの場合
        if (messageOrLog instanceof ChangeAuthenticationLog) {
          return (
            <ChangeAuthenticationLogMessage toType={messageOrLog.toType} key={messageOrLog.id} />
          );
        }

        // LINEグループ退会ログの場合
        if ("type" in messageOrLog) {
          const log = messageOrLog;
          return (
            <MessageWhenWithdrawalLineGroup
              date={log.createdAt}
              lineUserDisplayName={log.lineUser.displayName}
              key={log.createdAt.toISOString()}
            />
          );
        }

        // 通常のメッセージの場合
        const message: ContactMessage & {
          creatorName: string;
          creatorImageUrl: string | undefined;
        } = messageOrLog;

        const variant = message.isNewHireMessage ? "left" : "right";
        const isSendingItem = messageId === message.id;
        return (
          <NewGraduateContactMessageItem
            key={message.id}
            message={message}
            variant={variant}
            newGraduate={newGraduate || undefined}
            isFileSending={isSendingItem && isFileSending}
            fileSignedUrlMapObj={{}}
          />
        );
      })}
    </>
  );
};
