import { Stack } from "@mui/material";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { ComponentProps, FC } from "react";

import { NewGraduateMemoTextarea } from "../NewGraduateMemoTextarea/NewGraduateMemo";

import { Typography } from "~/components/uiParts";

import { PaperPopover } from "~/components/uiParts/PaperPopover";

import { useUpdateNewGraduateMemo } from "~/hooks/employee";

export const AdminMemoPopover: FC<
  Pick<ComponentProps<typeof PaperPopover>, "anchorEl" | "open" | "onClose"> & {
    newGraduate: NewGraduateToDisplayForAdmin;
  }
> = ({ anchorEl, open, onClose, newGraduate }) => {
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();

  return (
    <PaperPopover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Stack spacing="8px">
        <Typography variant="body2" color="textPrimary">
          社内メモ
        </Typography>
        <NewGraduateMemoTextarea
          memo={newGraduate.employeeNote ?? ""}
          onUpdateMemo={(newText) => updateNewGraduateMemo(newGraduate, newText)}
        />
        <Typography variant="caption" color="textSecondary">
          ※社内メモは候補者には公開されません。
        </Typography>
      </Stack>
    </PaperPopover>
  );
};
