import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";
type Endpoint = APISchema["/api/new-graduates/move-space"]["POST"];

export const useMoveNewGraduateToAnotherSpace = () => {
  const moveNewGraduateToAnotherSpace = useCallback(async (params: Endpoint["body"]) => {
    await apiClient.post("/api/new-graduates/move-space", params);
  }, []);

  return { moveNewGraduateToAnotherSpace };
};
