import React, { FC, useCallback, useMemo } from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { FileQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/FileQuestionFormUIMemo";
import { extractFileNameFromPath } from "../../_share_in_create_edit/extractFileNameFromPath";
import { FIlE_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";
import {
  OnChangeIsRequired,
  OnChangeQuestionOptionType,
  OnChangeTitle,
  OnClickDelete,
} from "../hooks/form/useQuestionFormCommonHandler";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

export type Props = {
  onnFormTaskId: string;
  question: FIlE_QUESTION_FOR_FORM;
  onChangeTitle: OnChangeTitle;
  onChangeQuestionOptionType: OnChangeQuestionOptionType;
  onChangeIsRequired: OnChangeIsRequired;
  onClickDelete: OnClickDelete;
  onClickDuplicationFileQuestion: (question: FIlE_QUESTION_FOR_FORM) => void;
  onChangeFile: ({
    onnFormTaskId,
    questionId,
    payload,
  }: {
    payload: Payload;
    questionId: string;
    onnFormTaskId: string;
  }) => void;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

export const FileQuestionForm: FC<Props> = ({
  onnFormTaskId,
  question,
  onChangeTitle: _onChangeTitle,
  onChangeIsRequired: _onChangeIsRequired,
  onChangeQuestionOptionType: _onChangeQuestionOptionType,
  onClickDelete: _onClickDelete,
  onClickDuplicationFileQuestion: _onClickDuplication,
  onChangeFile: _onChangeFile,
  questionDragHandleProps,
}) => {
  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      _onChangeTitle({ questionId: question.id, title: e.target.value });
    },
    [_onChangeTitle, question.id]
  );

  const onChangeIsRequired = useCallback(() => {
    _onChangeIsRequired({ questionId: question.id, isRequired: !question.isRequired });
  }, [_onChangeIsRequired, question.id, question.isRequired]);

  const onChangeQuestionOptionType = useCallback(
    (
      e: React.ChangeEvent<{
        value: "TEXT" | "RADIO" | "CHECK_BOX" | "FILE";
      }>
    ) => {
      _onChangeQuestionOptionType({
        questionId: question.id,
        isRequired: question.isRequired,
        questionTitle: question.title,
        taskOptionType: e.target.value,
        question: {
          isDirtyTitle: question.isDirtyTitle,
          isTitleError: question.isTitleError,
          titleHelperText: question.titleHelperText,
          disabledOptionType: question.disabledOptionType,
          id: question.id,
          title: question.title,
          isRequired: question.isRequired,
        },
      });
    },
    [
      _onChangeQuestionOptionType,
      question.disabledOptionType,
      question.id,
      question.isDirtyTitle,
      question.isRequired,
      question.isTitleError,
      question.title,
      question.titleHelperText,
    ]
  );

  const onClickDelete = useCallback(() => {
    _onClickDelete({ questionId: question.id });
  }, [_onClickDelete, question.id]);

  const onClickDuplication = useCallback(() => {
    _onClickDuplication(question);
  }, [_onClickDuplication, question]);

  const onChangeFile = useCallback(
    (payload: Payload) => {
      _onChangeFile({
        onnFormTaskId,
        questionId: question.id,
        payload,
      });
    },
    [_onChangeFile, onnFormTaskId, question.id]
  );

  const sampleFileName = useMemo(() => {
    return question.sampleFilePath && extractFileNameFromPath(question.sampleFilePath);
  }, [question.sampleFilePath]);

  return (
    <FileQuestionFormUIMemo
      selectedOptionType="FILE"
      isRequired={question.isRequired}
      onChangeTitle={onChangeTitle}
      onChangeIsRequired={onChangeIsRequired}
      onChangeOptionType={onChangeQuestionOptionType}
      onClickDelete={onClickDelete}
      onClickDuplication={onClickDuplication}
      onChangeFile={onChangeFile}
      sampleFileName={sampleFileName}
      disabledOptionType={question.disabledOptionType}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: question.title,
        helperText: question.titleHelperText,
        error: question.isTitleError,
      }}
    />
  );
};
