import { RecruitmentProcessConditionField, RecruitmentProcessConditionFieldKey } from "@onn/common";

import { GroupKey, MenuItem, SelectedFieldKey } from "./type";

import { EmployeeInformationGroupData } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

// prettier-ignore
const basicFieldsMap: Record<GroupKey, MenuItem[]> = {
  // [FIXME] key は 各グループ、detailItemsで被ってしまうと、項目選択/解除の際に連動してしまう
  NEW_GRADUATE: [
    { key: "name", label: "氏名", sampleValue: "例田太郎" },
    { key: "kana_name", label: "フリガナ", sampleValue: "レイダ タロウ" },
    { key: "gender", label: "性別", sampleValue: "回答なし" },
    { key: "birthday", label: "生年月日", sampleValue: "20011220" },
    { key: "mail_address", label: "メールアドレス", sampleValue: "xxxxxxxxxx@xxxxx.com" },
    { key: "phone_number", label: "電話番号（携帯）", sampleValue: "080-1234-5678" },
    { key: "home_phone_number", label: "電話番号（自宅）", sampleValue: "080-1234-5678" },
    { key: "graduation_year_and_month", label: "卒業年月", sampleValue: "2024年 4月" },
    { key: "affiliation", label: "学校", sampleValue: "〇〇大学□□学部〇〇学科" },
    { key: "address_current", label: "現住所", sampleValue: "〒000-0000 東京都千代田区千代田1-1千代田ビル" },
    { key: "address_hometown", label: "帰省先", sampleValue: "〒000-0000 東京都千代田区千代田1-1千代田ビル" },
    { key: "unique_id", label: "Onn 固定ID", sampleValue: "ONN12345678" },
    { key: "external_id", label: "ID（その他）", sampleValue: "12345678" },
    { key: "offer_acceptance_deadline", label: "内定承諾期限", sampleValue: "20200101" },
  ],
  RECRUITMENT_MANAGEMENT: [
    { key: "assignee", label: "担当者", sampleValue: "末長 紘子" },
    { key: "admin_memo", label: "社内メモ", sampleValue: "メモ" },
    { key: "tags", label: "タグ", sampleValue: "AA大学, 夏インターン参加, エンジニア志望" },
    { key: "account_created_at", label: "システム登録日", sampleValue: "20200101" },
  ],
  RECRUITMENT_INFORMATION: [
    {
      key: "recruitment_status", label: "選考状況", sampleValue: "共通、一次面談、A(志望度:高)",
      detailItems: [
        { key: "scenario_name", label: "シナリオ", sampleValue: "共通" },
        { key: "recruitment_status_name", label: "選考ステータス", sampleValue: "一次面談" },
        { key: "employee_prediction", label: "ヨミ", sampleValue: "A(志望度:高)" },
      ],
    },
    {
      key: "recruitment_process", label: "選考履歴・関連イベント", sampleValue: "共通/書類選考、合格、一次面接、2024/07/20 10:30~11:30、末長 紘子、A、評価メモ、20240720、20240720",
      detailItems: [
        { key: "label", label: "履歴", sampleValue: "共通/書類選考" },
        { key: "status", label: "履歴ステータス", sampleValue: "合格" },
        { key: "event/label", label: "関連イベント名", sampleValue: "一次面接" },
        // { key: "event/status", label: "関連イベントステータス", sampleValue: "合格" }, // TODO[カスタムCSVの高度化]
        { key: "event/schedule", label: "関連イベント日程", sampleValue: "2024/07/20 10:30~11:30" },
        { key: "event/assignee", label: "関連イベント担当者", sampleValue: "末長 紘子" },
        { key: "event/evaluation_rank", label: "関連イベント評価ランク", sampleValue: "A" },
        { key: "event/evaluation_form", label: "関連イベント評価フォーム", sampleValue: "評価メモ" },
        { key: "created_at", label: "履歴作成日時", sampleValue: "20240720" },
        { key: "updated_at", label: "履歴更新日時", sampleValue: "20240720" },
      ],
    },
  ],
};

const generateGroupDataMap = (employeeInformationGroupData: EmployeeInformationGroupData) => {
  const groupDataMap: Record<GroupKey, MenuItem[]> = {};

  employeeInformationGroupData.forEach(
    ({ employeeInformationGroup, employeeInformationFieldWithOptions }) => {
      employeeInformationFieldWithOptions.forEach((employeeInformationField) => {
        const existingGroupData = groupDataMap[employeeInformationGroup.label];
        const item = {
          key: `custom_employee_information_field/${employeeInformationField.id}`,
          label: employeeInformationField.label,
        } as const;

        if (existingGroupData) {
          groupDataMap[employeeInformationGroup.label] = [...existingGroupData, item];
        } else {
          groupDataMap[employeeInformationGroup.label] = [item];
        }
      });
    }
  );

  return groupDataMap;
};

export const isNestedField = (field: MenuItem) => "detailItems" in field;
export const getAllSelectableFieldKeys = (allSelectableFieldsMap: Record<GroupKey, MenuItem[]>) =>
  Object.values(allSelectableFieldsMap)
    .flat()
    .flatMap((field) => (isNestedField(field) ? field.detailItems.map((v) => v.key) : field.key));

export const initSelectableFieldsMap = (
  employeeInformationGroupData: EmployeeInformationGroupData
): Record<GroupKey, MenuItem[]> => {
  // NOTE: 初期値のMapがそのまま出力の順番を決定する
  return {
    NEW_GRADUATE: basicFieldsMap["NEW_GRADUATE"] || [],
    RECRUITMENT_MANAGEMENT: basicFieldsMap["RECRUITMENT_MANAGEMENT"] || [],
    ...generateGroupDataMap(employeeInformationGroupData),
    RECRUITMENT_INFORMATION: basicFieldsMap["RECRUITMENT_INFORMATION"] || [],
  };
};

export const initSelectedFieldsMap = (
  employeeInformationGroupData: EmployeeInformationGroupData
) => {
  const selectableFieldsMap = initSelectableFieldsMap(employeeInformationGroupData);
  const allSelectableFieldKeys = getAllSelectableFieldKeys(selectableFieldsMap);

  return new Map(allSelectableFieldKeys.map((fieldKey) => [fieldKey, true]));
};

const convertRecruitmentProcessFieldToRequest = ({
  selectedFieldsMap,
  recruitmentProcessConditionFieldKeys,
}: {
  selectedFieldsMap: Map<SelectedFieldKey, boolean>;
  recruitmentProcessConditionFieldKeys: RecruitmentProcessConditionFieldKey[];
}) => {
  const value = recruitmentProcessConditionFieldKeys.reduce<RecruitmentProcessConditionField[]>(
    (acc, key) => {
      if (!selectedFieldsMap.get(key)) return acc;

      if (
        key !== "event/assignee" &&
        key !== "event/evaluation_rank" &&
        key !== "event/evaluation_form" &&
        key !== "event/label" &&
        key !== "event/schedule"
      ) {
        return [...acc, key];
      }

      // 型が抜け落ちるのでeventArrayIndexを使って配列から取り出さず別で取得する
      const eventArrayIndex = acc.findIndex(Array.isArray);
      const eventArray = acc.find(Array.isArray);

      const isExistEventArray = !!eventArray;

      if (isExistEventArray) {
        return [
          ...acc.slice(0, eventArrayIndex),
          eventArray.concat(key),
          ...acc.slice(eventArrayIndex + 1),
        ];
      } else {
        return [...acc, [key]];
      }
    },
    []
  );

  return value;
};

// NOTE: apiにリクエストを投げるときは、NestedFieldのものはオブジェクトに変換する必要があるため、変換する
export const convertFieldToRequest = ({
  selectableFieldsMap,
  selectedFieldsMap,
}: {
  selectableFieldsMap: Record<GroupKey, MenuItem[]>;
  selectedFieldsMap: Map<SelectedFieldKey, boolean>;
}) =>
  Object.values(selectableFieldsMap).flatMap((fields) => {
    return fields.flatMap((field) => {
      if (field.key === "recruitment_status") {
        return {
          [field.key]: field.detailItems.flatMap((v) =>
            selectedFieldsMap.get(v.key) ? [v.key] : []
          ),
        };
      }
      if (field.key === "recruitment_process") {
        return {
          [field.key]: convertRecruitmentProcessFieldToRequest({
            selectedFieldsMap,
            recruitmentProcessConditionFieldKeys: field.detailItems.map((v) => v.key),
          }),
        };
      }

      return selectedFieldsMap.get(field.key) ? field.key : [];
    });
  });
