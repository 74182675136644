import { Employee, MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, splitSearchString } from "@onn/common";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { IconButton, TextFieldDeprecated } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";

type Props = {
  showPlaceholder?: boolean;
  employees: Employee[];
  onSearch: (text: string) => void;
  keywordsString: string;
};

export const SearchForm: FC<Props> = ({ showPlaceholder, onSearch, keywordsString }) => {
  const [searchValue, setSearchValue] = useState(keywordsString);
  const handleSearchChange = useDebouncedCallback((callback) => callback(), 200);

  const [keywordsStringSearchErrorText, setKeywordsStringSearchErrorText] = useState<
    string | undefined
  >();

  const handleSearch = useCallback(
    (
      e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
      text: string
    ) => {
      e.preventDefault();

      if (splitSearchString(text).length > MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT) {
        setKeywordsStringSearchErrorText(
          `キーワードは${MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT}個までしか指定できません`
        );
      } else {
        setKeywordsStringSearchErrorText(undefined);
        onSearch(text);
      }
    },
    [onSearch]
  );

  return (
    <StyledForm onSubmit={(e) => handleSearch(e, searchValue)}>
      <TextFieldDeprecated
        autoFocus
        fullWidth
        variant="outlined"
        placeholder={showPlaceholder ? "ユーザー名・メールアドレスで検索" : ""}
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);

          handleSearchChange(() => {
            handleSearch(e, e.target.value);
          });
        }}
        endAdornment={
          <IconButton
            size="sm"
            color="grey"
            icon="search"
            onClick={(e) => handleSearch(e, searchValue)}
          />
        }
        error={!!keywordsStringSearchErrorText}
        helperText={keywordsStringSearchErrorText}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;
