import { Box } from "@mui/material";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC } from "react";

import { RecruitmentProcessArea } from "./components/RecruitmentProcessArea/RecruitmentProcessArea";
import { RecruitmentStatusArea } from "./components/RecruitmentStatusArea/RecruitmentStatusArea";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
};

export const RecruitmentProcessTab: FC<Props> = ({ newGraduate }) => {
  return (
    <Box
      mt="40px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <RecruitmentStatusArea newGraduate={newGraduate} />
      <RecruitmentProcessArea newGraduate={newGraduate} />
    </Box>
  );
};
