import { Employee } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 新卒候補者用のメモを更新する関数を提供するhooks
 */
export const useUpdateNewGraduateMemo = () => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * 新卒候補者用のメモを更新する
   * @param {Employee} employee
   * @param {string} newMemo
   */
  const updateNewGraduateMemo = useCallback(
    async (employee: Employee, newMemo: string) => {
      try {
        await apiClient.patch("/api/employee-note", { value: newMemo, employeeId: employee.id });
      } catch (e) {
        enqueueSnackbar("メモの保存に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useUpdateNewGraduateMemo:updateNewGraduateMemo" },
        });
      }
    },
    [enqueueSnackbar]
  );

  return { updateNewGraduateMemo };
};
