import { v4 } from "uuid";

import { OnnTaskQuestion as Question } from "../Question";

import { IFormRevisionSchema, formRevisionSchema } from "./schema";

export class FormRevision implements IFormRevisionSchema {
  static readonly validator = formRevisionSchema;
  id: string;
  tenantId: string;
  formTaskId: string;
  createdAt: Date;
  createdUserId: string;
  questions: Question[];

  constructor(init: ExcludeMethods<FormRevision>) {
    const parsedInit = FormRevision.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.formTaskId = parsedInit.formTaskId;
    this.createdAt = parsedInit.createdAt;
    this.createdUserId = parsedInit.createdUserId;
    this.questions = parsedInit.questions;
  }

  public static create(params: Optional<ExcludeMethods<FormRevision>, "id" | "createdAt">) {
    return new FormRevision({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
    });
  }

  public clone(): FormRevision {
    return new FormRevision(this);
  }

  public updateQuestions({
    updatedQuestions,
    isAlreadySentToSomeone,
  }: {
    updatedQuestions: Question[];
    isAlreadySentToSomeone: boolean; // 既に誰かに送信済みかどうか
  }) {
    this.validate({ questions: updatedQuestions });

    // 未送信のタスクに対しては制限なしに更新できる
    if (!isAlreadySentToSomeone) {
      this.questions = updatedQuestions;
      return;
    }

    // 送信済みのタスクに対しては、更新に制限がかかる

    // 送信済みのタスクに対して、設問の削除はできる
    this.questions = updatedQuestions.map((updatedQuestion) => {
      const existingQuestion = this.questions.find((q) => q.id === updatedQuestion.id);
      const isNew = !existingQuestion;
      // 送信済みのタスクに対して、設問の追加はできる
      if (isNew) return updatedQuestion;

      // 送信済みのタスクに対して、設問タイプを変更できない
      if (existingQuestion.type !== updatedQuestion.type) return existingQuestion;

      return updatedQuestion;
    });
  }

  public validate(update: Partial<FormRevision>) {
    FormRevision.validator.parse({ ...this, ...update });
  }
}
