import { throttle } from "lodash";
import { useEffect, useMemo } from "react";

import { useContactMessages } from "~/hooks/contactMessage";
import { useContactContextForAdminSite } from "~/hooks/contactMessage/useContactContext";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  contactRoomId: string;
};
export const useContactMessagesAndLogs = ({ contactRoomId }: Props) => {
  const { currentUser } = useCurrentUser();
  const { lastUpdatedAt } = useContactContextForAdminSite();

  const {
    data: contactRoomAndMessages = { contactRoom: undefined, messages: [] },
    isLoading,
    mutate,
  } = useContactMessages({
    tenantId: currentUser.tenantId,
    contactRoomId,
  });

  // NOTE:throttleを使用することで、一気にメッセージの更新が行われても、CFへのリクエストが一気に行われることを防ぐ
  const throttledMutate = useMemo(
    () => throttle(() => mutate(), 5000), // 負荷が高めなので、一旦5秒に設定
    [mutate]
  );

  useEffect(() => {
    throttledMutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedAt]);

  return { contactRoomAndMessages, isLoading };
};
