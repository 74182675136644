import { APISchema } from "@onn/common";

import { useCallback, useState } from "react";

import { useMutateOnnEventEvaluationDraft } from "./useOnnEventEvaluationDraft";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-evaluations/draft"]["POST"];

export const useUpdateOnnEventEvaluationDraft = () => {
  const { mutateOnnEventEvaluationDraft } = useMutateOnnEventEvaluationDraft();

  const [isUpdatingDraft, setIsUpdatingDraft] = useState<boolean | undefined>(undefined);

  const updateOnnEventEvaluationDraft = useCallback(
    async (body: Endpoint["body"]) => {
      setIsUpdatingDraft(true);
      await apiClient.post("/api/onn-event-evaluations/draft", body).finally(() => {
        setIsUpdatingDraft(false);
      });
      await mutateOnnEventEvaluationDraft({
        onnEventId: body.onnEventId,
        targetEmployeeId: body.targetEmployeeId,
      });
    },
    [mutateOnnEventEvaluationDraft]
  );

  return { updateOnnEventEvaluationDraft, isUpdatingDraft };
};
