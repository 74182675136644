import { Box } from "@material-ui/core";
import { ContactMessage, Employee } from "@onn/common";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { FileThumbnails } from "./FileThumbnails";
import { MessageLineStamp } from "./MessageLineStamp";
import { MessageText } from "./MessageText";

import { NewGraduateMessageUserNameAndDate } from "./NewGraduateMessageUserNameAndDate";

import { Typography, UserIcon } from "~/components/uiParts";
import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

type Props = {
  variant: "left" | "right";
  message: ContactMessage & { creatorName: string; creatorImageUrl?: string };
  newGraduate?: Employee;
  isFileSending?: boolean;
  fileSignedUrlMapObj: Record<string, string>;
};

export const NewGraduateContactMessageItem: FC<Props> = ({
  message,
  variant,
  newGraduate,
  isFileSending,
  fileSignedUrlMapObj,
}) => {
  // 添付ファイルの送信中に取得してしまうと、エラーが返ってくるため、送信中のファイルは取得しないようにする
  const shouldFetchMetaData = message.filePaths && !isFileSending;
  const { data: files, isValidating: isValidatingMetadata } = useMetaDataByUrls(
    shouldFetchMetaData ? message.filePaths || [] : []
  );
  const { setFiles, setPreviewFileIndex, handleDownloadFile } = useFileViewer();

  const filesWithSignedUrl = useMemo(() => {
    if (!files) return [];

    return files.map((file) => {
      return {
        ...file,
        signedUrl: fileSignedUrlMapObj[file.fullPath],
      };
    });
  }, [files, fileSignedUrlMapObj]);

  if (message.unsend) {
    return (
      <StyledBox padding="8px 16px" width="350px">
        <Typography
          variant="caption"
          color="textSecondary"
        >{`${message.creatorName}がメッセージの送信を取り消しました`}</Typography>
      </StyledBox>
    );
  }

  return (
    <Box display="flex" flexDirection={variant === "left" ? "row" : "row-reverse"} gridGap="8px">
      <Box display="flex" flexDirection="column">
        <MessageUserIcon
          creatorName={message.creatorName}
          creatorImageUrl={message.creatorImageUrl}
          isCreatedByNewGraduate={message.createdEmployeeId === newGraduate?.id}
          newGraduateId={newGraduate?.id}
        />
      </Box>
      <Box display="flex" flexDirection="column" gridGap="8px" maxWidth="70%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="8px"
          flexDirection={variant === "left" ? "row" : "row-reverse"}
        >
          <NewGraduateMessageUserNameAndDate
            creatorName={message.creatorName}
            messageCreatedAt={message.createdAt}
          />
        </Box>
        {message.stickerId && <MessageLineStamp variant={variant} stickerId={message.stickerId} />}
        {message.text && <MessageText variant={variant} messageText={message.text} />}
        {(isValidatingMetadata || files || isFileSending) && (
          <FileThumbnails
            variant={variant}
            messageText={message.text}
            isValidatingMetadata={isValidatingMetadata}
            isFileSending={isFileSending}
            files={filesWithSignedUrl}
            handleDownloadFile={handleDownloadFile}
            setFiles={setFiles}
            setPreviewFileIndex={setPreviewFileIndex}
          />
        )}
        {message.isFailedDelivery && (
          <Typography variant="caption" color="textSecondary">
            送信に失敗しました
          </Typography>
        )}
      </Box>
    </Box>
  );
};

/**
 * メッセージのユーザーアイコンを表示するコンポーネント
 */
const MessageUserIcon = React.memo<{
  creatorName: string;
  creatorImageUrl?: string;
  isCreatedByNewGraduate: boolean;
  newGraduateId?: string;
}>(({ creatorName, creatorImageUrl, isCreatedByNewGraduate, newGraduateId }) => {
  return (
    <UserIcon
      username={creatorName}
      profileIconImageUrl={creatorImageUrl}
      size="small"
      circular
      hover={false}
      onClick={() =>
        isCreatedByNewGraduate && newGraduateId
          ? window.open(`/employee/${newGraduateId}`, "_blank")
          : void 0
      }
    />
  );
});

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 10px;
`;
