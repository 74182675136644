import { Box } from "@material-ui/core";
import { TaskOptionTypes, TaskOptionTypesKeys } from "@onn/common/domain/OnnTask/Question";
import React, { FC } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styled from "styled-components";

import { ControlMenu } from "./ControlMenu";

import { QuestionTitleAndSelector } from "./QuestionTitleAndSelector";

import { RequiredSwitch } from "./RequiredSwitch";

import { Divider, Paper } from "~/components/uiParts";
import { AbsoluteGripHorizontalBox } from "~/components/uiParts/AbsoluteGripHorizontalBox";

export const QuestionFormPaper: FC<{
  children: React.ReactNode;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedOptionType: TaskOptionTypesKeys;
  onChangeOptionType: (e: React.ChangeEvent<{ value: keyof typeof TaskOptionTypes }>) => void;
  isRequired: boolean;
  onChangeIsRequired: (isRequired: boolean) => void;
  onClickDelete: () => void;
  onClickDuplication: () => void;
  disabledOptionType?: boolean;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  titleTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
  deleteQuestionMenuButtonOption?: {
    isDisabled: true;
    reason: string;
  };
}> = ({
  children,
  onChangeTitle,
  selectedOptionType,
  onChangeOptionType,
  isRequired,
  onChangeIsRequired,
  onClickDelete,
  onClickDuplication,
  disabledOptionType,
  questionDragHandleProps,
  titleTextField,
  deleteQuestionMenuButtonOption,
}) => {
  return (
    <StyledPaper>
      <Box paddingX={5}>
        <Box {...questionDragHandleProps}>
          <AbsoluteGripHorizontalBox />
        </Box>
        <QuestionTitleAndSelector
          onChangeTitle={onChangeTitle}
          onChangeOptionType={onChangeOptionType}
          selectedOptionType={selectedOptionType}
          disabledOptionType={disabledOptionType}
          titleTextField={titleTextField}
        />
      </Box>
      <Box>{children}</Box>
      <Box paddingX={5}>
        <StyledDivider orientation="horizontal" variant="fullWidth" />
        <Box
          width={"100%"}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            mt: "16px",
          }}
        >
          <Box display="flex" flexDirection="rowReverse">
            <RequiredSwitch
              isRequired={isRequired}
              handleOnClick={() => onChangeIsRequired(!isRequired)}
            />
            <ControlMenu
              onClickDuplication={onClickDuplication}
              onClickDelete={onClickDelete}
              deleteMenuOption={deleteQuestionMenuButtonOption}
            />
          </Box>
        </Box>
      </Box>
    </StyledPaper>
  );
};

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: 12px;
  }
  margin-bottom: 12px;
  height: 2px;
`;

const StyledPaper = styled(Paper)`
  padding: 40px 0;
  #gripVerticalIcon {
    display: none;
  }

  &:hover,
  &:active {
    #gripVerticalIcon {
      display: inline-block;
    }
  }
`;
