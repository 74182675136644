import { Employee } from "@onn/common";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { IconButton, TextFieldDeprecated } from "~/components/uiParts";
import { useDebouncedCallback, useFilterObjectByPartialMatch } from "~/hooks/shared";

type Props = {
  employees: Employee[];
  onSearch: (results: Employee[]) => void;
};

export const EmployeeSearchForm: FC<Props> = ({ employees, onSearch }) => {
  const [searchValue, setSearchValue] = useState<string>();
  const handleChange = useDebouncedCallback((callback) => callback(), 200);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const handleSearch = useCallback(
    (
      e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
      query?: string
    ) => {
      e.preventDefault();

      const results = filterObjectByPartialMatch<Employee>({
        objects: employees,
        searchText: query || "",
        getProperties: [
          (employee: Employee) => employee.getName(),
          (employee: Employee) => employee.email,
        ],
      });

      onSearch(results);
    },
    [filterObjectByPartialMatch, employees, onSearch]
  );

  return (
    <StyledForm onSubmit={(e) => handleSearch(e, searchValue)}>
      <TextFieldDeprecated
        autoFocus
        fullWidth
        variant="standard"
        placeholder={"ユーザー名・メールアドレスで検索"}
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);

          handleChange(() => {
            handleSearch(e, e.target.value);
          });
        }}
        endAdornment={
          <IconButton
            size="sm"
            color="grey"
            icon="search"
            onClick={(e) => handleSearch(e, searchValue)}
          />
        }
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;
