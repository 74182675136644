import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Divider,
  Modal,
  RadioButtonGroup,
  SearchForm,
  Typography,
  UserIconWithLabel,
} from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";

import { useFilterObjectByPartialMatch } from "~/hooks/shared";

type Props = {
  open: boolean;
  existingAssigneeIds: string[];
  onSubmit: (employeeIds: string[]) => Promise<void>;
  onCancel: () => void;
};

/**
 * イベントに紐づく担当者を変更するモーダル
 * @param existingAssigneeIds 変更前の担当者ID
 * @returns
 */
export const UpdateEventAssigneeModal: FC<Props> = ({
  open,
  existingAssigneeIds,
  onSubmit,
  onCancel,
}) => {
  const { data: acceptanceEmployees } = useAcceptanceEmployees();
  // acceptanceEmployeesには面接官アカウントも含まれるため除外している
  const assigneeCandidates = useMemo(
    () => (acceptanceEmployees || []).filter((employee) => employee.isRegularAcceptanceEmployee()),
    [acceptanceEmployees]
  );
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const [sending, setSending] = useState(false);
  // 変更前の担当者を一番上に持ってくるので初期値は常に0
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // 検索窓の文字を入力・消したらラジオボタンの選択を初期値に戻す
    setSelectedIndex(0);
  }, [searchValue]);

  const results = useMemo(() => {
    const filteredAssigneeCandidates = filterObjectByPartialMatch({
      objects: assigneeCandidates,
      searchText: searchValue,
      getProperties: [
        (employee: Employee) => employee.getName(),
        (employee: Employee) => employee.email,
        (employee: Employee) => employee.profileIconImageUrl ?? "",
      ],
    });
    if (filteredAssigneeCandidates.length === 0) return filteredAssigneeCandidates;

    // 変更前の担当者を一番上に持ってくる
    const targetIndex = filteredAssigneeCandidates.findIndex(
      (candidate) => candidate.id === existingAssigneeIds[0]
    );
    const target = filteredAssigneeCandidates.splice(
      targetIndex,
      1
    )[0] as (typeof filteredAssigneeCandidates)[number];
    filteredAssigneeCandidates.unshift(target);

    return filteredAssigneeCandidates;
  }, [filterObjectByPartialMatch, assigneeCandidates, searchValue, existingAssigneeIds]);

  const handleSubmit = useCallback(async () => {
    setSending(true);

    const employeeId = (results[selectedIndex] as (typeof results)[number]).id;

    await onSubmit([employeeId]).finally(() => {
      onCancel();
      setSending(false);
    });
  }, [results, selectedIndex, onCancel, onSubmit]);

  return (
    <StyledModal
      open={open}
      title="担当者変更"
      onCancel={onCancel}
      content={
        <>
          <SearchForm
            searchValue={searchValue}
            placeholder="ユーザー名・メールアドレスで検索"
            variant="outlined"
            onSearchValue={setSearchValue}
            fullWidth
          />
          <Divider margin={24} orientation="horizontal" />
          <Box overflow="auto" mb={3}>
            {results.length === 0 ? (
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" color="textSecondary">
                  結果が見つかりませんでした
                </Typography>
              </Box>
            ) : (
              <StyledRadioButton
                options={results.map((employee, index) => ({
                  index,
                  option: (
                    <UserIconWithLabel
                      name={employee.getName()}
                      iconPath={employee.profileIconImageUrl}
                      secondaryText={employee.email}
                      iconCircular
                    />
                  ),
                  isOther: false,
                }))}
                name="assignee"
                defaultColor="default"
                value={selectedIndex}
                onChange={(value) => setSelectedIndex(value)}
                onChangeFreeAnswerText={() => undefined}
              />
            )}
          </Box>
          <Divider />
        </>
      }
      footer={
        <Box display="flex" justifyContent="end">
          <Button variant="text" color="default" borderRadius="regular" onClick={onCancel}>
            キャンセル
          </Button>
          <Box width={16} />
          <Button
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={sending || !results.length}
            onClick={handleSubmit}
          >
            変更
          </Button>
        </Box>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
    height: 100%;

    & > .MuiBox-root {
      height: 100%;

      /* content */
      & > .MuiBox-root:nth-child(2) {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
`;

const StyledRadioButton = styled(RadioButtonGroup)`
  &.MuiFormGroup-root {
    gap: 16px;
  }
`;
