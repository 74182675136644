import { Table, TableBody, TableHead } from "@mui/material";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC, useContext } from "react";
import styled from "styled-components";

import { Row } from "./Body/Row";
import { TableHeaderRow } from "./Header/TableHeaderRow";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { useNewGraduateNextPlan } from "~/hooks/employee/useNewGraduateNextPlan";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
};

export const RecruitmentStatusTable: FC<Props> = ({ newGraduate }) => {
  const { scenarios } = useContext(ScenarioContext);
  const scenarioMap = new Map((scenarios || []).map((scenario) => [scenario.id, scenario]));
  const { data: nextPlans } = useNewGraduateNextPlan({ newGraduateId: newGraduate.id });
  const scenarioIdToNextPlanMap = new Map(
    (nextPlans || []).map((nextPlan) => [nextPlan.scenarioId, nextPlan])
  );

  return (
    <StyledTable
      sx={{
        tableLayout: "fixed",
      }}
    >
      <TableHead>
        <TableHeaderRow />
      </TableHead>
      <TableBody>
        {newGraduate.scenarios.map((scenario) => (
          <Row
            key={scenario.scenarioId}
            newGraduate={newGraduate}
            scenario={scenario}
            scenarioMap={scenarioMap}
            scenarioIdToNextPlanMap={scenarioIdToNextPlanMap}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    table-layout: fixed;
  }

  .MuiTableRow-root {
    .MuiTableCell-body,
    .MuiTableCell-head {
      border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
    }
  }
`;
