import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { Button, Icon, IconButton, TextFieldDeprecated, Typography } from "~/components/uiParts";

type UserDataType = { email?: string; role?: Role };

type Props = {
  isAdmin: boolean;
  userData: UserDataType;
  existedEmails: string[];
  onSelectEmail: (email: string) => void;
  onClickRole: (role: Role) => void;
  onClickDeleteFormButton: () => void;
  displayDeleteFormIcon: boolean;
};

export const AdminForm: FC<Props> = ({
  isAdmin,
  userData,
  existedEmails,
  onSelectEmail,
  onClickRole,
  onClickDeleteFormButton,
  displayDeleteFormIcon,
}) => {
  const [anchorElForRole, setAnchorElForRole] = useState<HTMLElement | null>(null);

  return (
    <>
      <Box display="flex" alignItems="flex-start" marginTop="16px" gridColumnGap="16px">
        <Box flex={1}>
          <StyledTextField
            name="email"
            type="email"
            value={userData.email}
            fullWidth
            variant="outlined"
            placeholder="name@example.com"
            error={Boolean(userData.email && existedEmails.includes(userData.email))}
            helperText={
              userData.email && existedEmails.includes(userData.email) ? "既に登録されています" : ""
            }
            onChange={(event) => onSelectEmail(event.target.value)}
          />
        </Box>
        <Box flex={1}>
          <StyledButtonWrapper>
            <Button
              onClick={(e) => setAnchorElForRole(e.currentTarget)}
              borderRadius="regular"
              variant="outlined"
              color="default"
              fullWidth
            >
              <Box width="100%" display="flex" alignItems="center" gridGap="4px">
                {userData.role ? (
                  <StyledTypography variant="body2" noWrap>
                    {Employee.displayRoleMap[userData.role]}
                  </StyledTypography>
                ) : (
                  <StyledTypography variant="body2" noWrap>
                    権限を選択
                  </StyledTypography>
                )}
                <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
              </Box>
            </Button>
          </StyledButtonWrapper>
        </Box>
        <Box width="40px" height="40px">
          {displayDeleteFormIcon && (
            <IconButton icon="trash" color="grey" onClick={onClickDeleteFormButton} size="md" />
          )}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorElForRole}
        open={Boolean(anchorElForRole)}
        onClose={() => setAnchorElForRole(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <Box width="236px">
          {/* NOTE: 面接官・メンバーは管理者を招待することはできない */}
          {(isAdmin ? [Role.ADMIN, Role.MEMBER, Role.ONLY_INTERVIEWER] : [Role.MEMBER]).map(
            (role, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setAnchorElForRole(null);
                    onClickRole(role);
                  }}
                >
                  <Typography variant="body2">{Employee.displayRoleMap[role]}</Typography>
                </MenuItem>
              );
            }
          )}
        </Box>
      </Menu>
    </>
  );
};

const StyledButtonWrapper = styled(Box)`
  /* TODO: buttonコンポーネントを修正してネスト指定が必要ないようにする */
  .MuiButtonBase-root > div {
    padding: 16px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    padding: 16px;
  }
`;
