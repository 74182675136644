import { APISchema, OnnEventEvaluationRank } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/onn-events/onn-event-evaluation-ranks"]["GET"];

const generateKey = (arg: { tenantId: string; onnEventId: string }) =>
  ["/api/onn-events/onn-event-evaluation-ranks", arg.tenantId, arg.onnEventId] as const;

export const useOnnEventEvaluationRanksPerOnnEvent = ({
  onnEventId,
  includeUndeletableRankIds,
}: {
  onnEventId: string;
  includeUndeletableRankIds?: boolean;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
    }),
    async ([endpoint]) => {
      const query: EndPoint["query"] = {
        onnEventId,
        includeUndeletableRankIds: includeUndeletableRankIds,
      };
      const response = await apiClient.get(endpoint, query);
      return {
        onnEventEvaluationRanks: response.onnEventEvaluationRanks.map(
          (d) => new OnnEventEvaluationRank(d)
        ),
        undeletableOnnEventEvaluationRankIds: response.undeletableOnnEventEvaluationRankIds,
      };
    }
  );
};

export const useMutateOnnEventEvaluationRanksPerOnnEvent = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventEvaluationRanksPerOnnEvent = useCallback(
    async (args: { onnEventId: string }) => {
      await mutate(generateKey({ tenantId: currentUser.tenantId, onnEventId: args.onnEventId }));
    },
    [currentUser.tenantId]
  );

  return { mutateOnnEventEvaluationRanksPerOnnEvent };
};
