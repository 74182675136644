import { v4 } from "uuid";

import { RecruitmentProcessRelationSetting } from "./RecruitmentProcessRelationSetting";

import { IRecruitmentStatusSchema, RecruitmentStatusType, recruitmentStatusSchema } from "./schema";

/**
 * 選考ステータスのマスターデータ
 * - space ごとに作成される
 */
export class RecruitmentStatus implements IRecruitmentStatusSchema {
  static readonly validator = recruitmentStatusSchema;
  static readonly standardRecruitmentStatusType: Array<
    Exclude<RecruitmentStatusType, "screening">
  > = ["pre_entry", "job_offer", "offer_accepted", "rejected", "withdrew"];
  static readonly typeToDefaultLabelMap: Record<
    Exclude<RecruitmentStatusType, "screening">,
    string
  > = {
    pre_entry: "プレエントリー",
    job_offer: "内定(承諾待ち)",
    offer_accepted: "入社承諾",
    rejected: "不採用",
    withdrew: "辞退",
  };

  id: string;
  tenantId: string;
  spaceId: string;
  scenarioId: string;
  deleted: boolean;
  type: RecruitmentStatusType;
  label: string;

  /**
   * 選考ステータスを設定した際に発行される選考プロセスの設定値
   * - targetId は、type に応じたエンティティの id
   * シナリオ適応前の候補者はこのプロパティをもとに選考プロセスを生成する
   */
  recruitmentProcessRelationSetting?: RecruitmentProcessRelationSetting;

  createdAt: Date;
  updatedAt: Date;
  order: number;
  constructor(init: ExcludeMethods<RecruitmentStatus>) {
    const parsedInit = RecruitmentStatus.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.scenarioId = parsedInit.scenarioId;
    this.deleted = parsedInit.deleted;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.type = parsedInit.type;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.recruitmentProcessRelationSetting = parsedInit.recruitmentProcessRelationSetting;
  }

  public static create(
    params: Omit<ExcludeMethods<RecruitmentStatus>, "id" | "deleted" | "createdAt" | "updatedAt">
  ): RecruitmentStatus {
    return new RecruitmentStatus({
      ...params,
      id: v4(),
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public logicallyDelete() {
    return new RecruitmentStatus({ ...this, deleted: true, updatedAt: new Date() });
  }

  public getLabel(): string {
    return `${this.deleted ? "【削除済み】" : ""}${this.label}`;
  }

  isPreEntry(): this is RecruitmentStatus & { type: "pre_entry" } {
    return this.type === "pre_entry";
  }

  isJobOffer(): this is RecruitmentStatus & { type: "job_offer" } {
    return this.type === "job_offer";
  }

  isSelectableWhenCreateNewGraduate(): boolean {
    return this.type !== "rejected" && this.type !== "withdrew";
  }

  isRejectedOrWithdrew(): boolean {
    return isRejectedOrWithdrew(this.type);
  }

  updateOrderAndLabel({ order, label }: { order: number; label: string }): RecruitmentStatus {
    return new RecruitmentStatus({
      ...this,
      order,
      label,
    });
  }
}

export const isRejectedOrWithdrew = (status: RecruitmentStatusType): boolean => {
  return status === "rejected" || status === "withdrew";
};

export const isRecruitmentStatusType = (status: string): status is RecruitmentStatusType => {
  return Object.keys(RecruitmentStatus.typeToDefaultLabelMap).includes(status);
};
