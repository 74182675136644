import { ListItem, Typography } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { Icon, UserIconWithLabel } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";

type NextNotice = "NextNotice";
type Props = ListRowProps & {
  employeesOrNotice: Array<Employee | NextNotice>;
  selectedEmployeeIds: string[];
  onSelect: (employee: Employee) => void;
};

export const DefaultRowRenderer: FC<Props> = ({
  employeesOrNotice,
  selectedEmployeeIds,
  onSelect,
  ...props
}) => {
  const employeeOrNotice = employeesOrNotice[props.index] as (typeof employeesOrNotice)[number];

  if (employeeOrNotice === "NextNotice") {
    return (
      <StyledDiv
        key={props.key}
        style={props.style} // 動的に計算されるtopなどのプロパティが入る
        $isOpacity={false}
      >
        <CenterBox style={{ margin: "16px", width: "100%", textAlign: "center" }}>
          <Typography variant="caption" color="textSecondary">
            これ以上の候補を表示できません。
            <br />
            検索条件をより具体的にしてください。
          </Typography>
        </CenterBox>
      </StyledDiv>
    );
  }

  const employee = employeeOrNotice;
  const isSelectedAny = selectedEmployeeIds.length > 0;
  const isSelectedThis = selectedEmployeeIds.includes(employee.id);

  return (
    <StyledDiv
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
      $isOpacity={!isSelectedAny || isSelectedThis} // 何も選択されていない or 自身が選択されている ときに不透明
      $canPoint={true}
    >
      <ListItem
        className={isSelectedThis ? "selected" : ""}
        alignItems="flex-start"
        selected={isSelectedThis}
        onClick={() => onSelect(employee)}
        data-testid={isSelectedThis ? "SlackUserList_StyledListItem_selected" : undefined}
      >
        <UserIconWithLabel
          name={employee.getName()}
          iconPath={employee.profileIconImageUrl}
          iconCircular
        />
        {isSelectedThis && <StyledIcon icon="check" size="sm" color="primary" />}
      </ListItem>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{
  /**
   * trueのとき、不透明な要素として表示する
   */
  $isOpacity: boolean;
  $canPoint?: boolean;
}>`
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.$canPoint ? "pointer" : "default")};

  ${(props) =>
    props.$isOpacity &&
    `
      opacity: 1;
      background-color: inherit;
      :hover,
      :focus {
        background-color: inherit;
      }
    `}
`;

const StyledIcon = styled(Icon)`
  opacity: 1;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
