import { ContactRoom, ContactRoomUnreadCountInfo } from "@onn/common";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useContactRoomForPortal } from "~/hooks/contactRoom/useContactRoomForPortal";
import { useCurrentUser } from "~/hooks/employee";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

const unreadCountInfoRepository = new UnreadCountInfoRepository();

export const ContactContextForPortal = createContext<{
  isLoadingContactRoom: boolean;
  unReadCount: number;
  contactRoom: ContactRoom | undefined;
  registerOnChangeUnreadCountInfoHandler: ((handler: () => void) => void) | undefined;
}>({
  isLoadingContactRoom: true,
  unReadCount: 0,
  contactRoom: undefined,
  registerOnChangeUnreadCountInfoHandler: undefined,
});

export const ContactProviderForPortal: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { data: contactRoom, isLoading } = useContactRoomForPortal();

  const { unReadCount, registerOnChangeUnreadCountInfoHandler } = useUnreadCount();

  return (
    <ContactContextForPortal.Provider
      value={{
        contactRoom: contactRoom || undefined,
        unReadCount,
        registerOnChangeUnreadCountInfoHandler,
        isLoadingContactRoom: isLoading,
      }}
    >
      {children}
    </ContactContextForPortal.Provider>
  );
};

const useUnreadCount = () => {
  const [unReadCount, setUnReadCount] = useState<number>(0);

  // NOTE: swrを使っているのでuseUnreadCount内でもuseContactRoomForPortalを使っている
  const { data: contactRoom } = useContactRoomForPortal();
  const { currentUser } = useCurrentUser();

  const onChangeUnreadCountInfoHandler =
    useRef<(updatedInfo: ContactRoomUnreadCountInfo) => void>(undefined);

  const registerOnChangeUnreadCountInfoHandler = useCallback((handler: () => void) => {
    onChangeUnreadCountInfoHandler.current = handler;
  }, []);

  useEffect(() => {
    if (!contactRoom) return;
    const unsubscribeFunctions = unreadCountInfoRepository.listenUnreadCountInfo({
      contactRoomIds: [contactRoom.id],
      employeeId: currentUser.id,
      tenantId: currentUser.tenantId,
      onChangedUnreadCountInfo: (updatedInfo) => {
        setUnReadCount(updatedInfo.unreadCount);
        onChangeUnreadCountInfoHandler.current?.(updatedInfo);
      },
      // NOTE: 初回取得も兼ねているため、 added の際にも setUnReadCount を行う
      onAddedUnreadCountInfo: (updatedInfo) => {
        setUnReadCount(updatedInfo.unreadCount);
      },
    });
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
  }, [contactRoom, currentUser.id, currentUser.tenantId, onChangeUnreadCountInfoHandler]);

  return {
    unReadCount,
    registerOnChangeUnreadCountInfoHandler,
  };
};
