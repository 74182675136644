import { Tooltip } from "@material-ui/core";
import { Box, TableCell, TableRow } from "@mui/material";
import { Employee, NewGraduateToDisplayForAdmin, NextPlan, Scenario } from "@onn/common";
import React, { FC, useMemo } from "react";

import styled from "styled-components";

import { headers } from "../Header/TableHeaderRow";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { NewGraduateNextPlan } from "~/components/domains/employees/NewGraduateTable/TableRowItems/NewGraduateNextPlan";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { TooltipWhenTextTruncatedOneLine } from "~/components/uiParts";
import theme from "~/config/theme";
import {
  useAcceptanceEmployees,
  useCurrentUser,
  useGenerateMutateNewGraduate,
} from "~/hooks/employee";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  scenario: NewGraduateToDisplayForAdmin["scenarios"][number];
  scenarioMap: Map<string, Scenario>;
  scenarioIdToNextPlanMap: Map<string, NextPlan>;
};

export const Row: FC<Props> = ({ newGraduate, scenario, scenarioMap, scenarioIdToNextPlanMap }) => {
  const { data: acceptanceEmployees } = useAcceptanceEmployees();
  const acceptanceEmployeeIdToEmployeeMap = useMemo(() => {
    return new Map<string, Employee>(
      (acceptanceEmployees || []).map((employee) => [employee.id, employee])
    );
  }, [acceptanceEmployees]);
  const { currentUser } = useCurrentUser();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const mutate = generateMutateNewGraduate(newGraduate.id);

  const cells = useMemo(
    () => [
      <ScenarioName key={0} name={scenarioMap.get(scenario.scenarioId)?.name} />,
      <Tooltip
        title={currentUser.isOnlyInterviewer() ? "権限がないため編集できません" : ""}
        key={1}
      >
        <span>
          <ChangeRecruitmentStatusSelector
            scenarioId={scenario.scenarioId}
            newGraduate={newGraduate}
            selectedRecruitmentStatusId={scenario.recruitmentStatusId}
            disabled={currentUser.isOnlyInterviewer()}
          />
        </span>
      </Tooltip>,
      <Tooltip
        key={2}
        title={currentUser.isOnlyInterviewer() ? "権限がないため編集できません" : ""}
      >
        <span>
          <ChangeEmployeePredictionSelector
            scenarioId={scenario.scenarioId}
            currentPredictionId={scenario.predictionId}
            newGraduate={newGraduate}
            disabled={currentUser.isOnlyInterviewer()}
          />
        </span>
      </Tooltip>,
      <NewGraduateNextPlan
        key={3}
        newGraduate={newGraduate}
        nextPlan={scenarioIdToNextPlanMap.get(scenario.scenarioId)}
        acceptanceEmployeeIdToEmployeeMap={acceptanceEmployeeIdToEmployeeMap}
        onSaveNextPlan={mutate}
      />,
    ],
    [
      scenarioMap,
      scenario.scenarioId,
      scenario.recruitmentStatusId,
      scenario.predictionId,
      currentUser,
      newGraduate,
      scenarioIdToNextPlanMap,
      acceptanceEmployeeIdToEmployeeMap,
      mutate,
    ]
  );
  return (
    <TableRow>
      {headers.map((v, index) => {
        return <Cell key={index}> {cells[index]}</Cell>;
      })}
    </TableRow>
  );
};

const ScenarioName: FC<{ name?: string }> = ({ name }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        borderColor: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[50],
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px",
      }}
    >
      <TooltipWhenTextTruncatedOneLine
        typographyProps={{
          variant: "body2",
          color: "textPrimary",
        }}
        text={name ?? ""}
      />
    </Box>
  );
};

export const Cell = styled(TableCell)`
  &.MuiTableCell-root {
    height: 80px;
    padding: 0px 16px 0px 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
