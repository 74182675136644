import { Employee } from "../../Employee";
import { ContactRoom } from "../ContactRoom";

export abstract class ContactRoomWithEmployee extends ContactRoom {
  abstract kind: string;
  employee: Employee; // contactRoomのemployeeIdに紐づくEmployee

  constructor(init: { contactRoom: ContactRoom; employee: Employee }) {
    super(init.contactRoom);
    this.employee = init.employee;
  }

  abstract getRoomName(): string;

  abstract getHonorificRoomName(): string;
}
