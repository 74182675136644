import { v4 } from "uuid";

import { State } from "../../_share_in_create_edit/reducer";
import { TEXT_QUESTION } from "../../_share_in_create_edit/types";
export const createInitialState = (): State => {
  const defaultQuestion: TEXT_QUESTION = {
    id: v4(),
    type: "TEXT",
    title: "",
    isRequired: true,
    disabledOptionType: false,
    isWordLimit: false,
  };
  return {
    selectedOnnTaskTypes: "FORM_TASK",
    isAllowEditAnswer: false,
    questions: [
      {
        ...defaultQuestion,
        isTitleError: false,
        titleHelperText: "",
        isDirtyTitle: false,
      },
    ],
    onnTaskTitleTextField: {
      value: "",
      error: false,
      helperText: "",
      isDirty: false,
    },
    onnTaskDescriptionTextField: {
      value: "",
      error: false,
      helperText: "",
      isDirty: false,
    },
  };
};
