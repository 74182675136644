import { Box, Tooltip } from "@mui/material";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC } from "react";

import { AdminMemoPopover } from "../../AdminMemoPopover";

import { IconButton } from "~/components/uiParts";
import { usePaperPopover } from "~/components/uiParts/PaperPopover";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  onUpdateMemo: () => void;
};

export const NewGraduateMemo: FC<Props> = ({ newGraduate, onUpdateMemo }) => {
  const popoverManger = usePaperPopover();
  const isExistAdminMemo = !!newGraduate.employeeNote;

  // NOTE: 社内メモが存在する場合は、ツールチップのテキストを「社内メモ」として表示する
  const tooltipText = isExistAdminMemo ? newGraduate.employeeNote : "社内メモ";

  return (
    <>
      <Tooltip title={tooltipText} placement="top-end">
        {/* ~/components/uiParts の Tooltip を利用すると、なぜかちらつきが発生してしまうため利用しない */}
        {/* NOTE: Boxで囲まないと、一度ボタンを押さないとtooltipが表示されない */}
        <Box>
          <IconButton
            buttonRef={popoverManger.anchorEl}
            icon={isExistAdminMemo ? "memo" : "add"}
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              popoverManger.openMenu();
            }}
          />
        </Box>
      </Tooltip>
      <AdminMemoPopover
        anchorEl={popoverManger.anchorEl.current}
        open={popoverManger.isOpen}
        onClose={() => {
          onUpdateMemo();
          popoverManger.closeMenu();
        }}
        newGraduate={newGraduate}
      />
    </>
  );
};
