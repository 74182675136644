import { Box, FormControl, FormHelperText, MenuItem, Select, Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

// NOTE: 必要になったら渡せる項目を増やす
type Props = {
  valueToDisplay: string;
  errorText?: string;
  fullWidth?: boolean;
  labelWhenNoSelected?: string;
  onClick: () => void;
  className?: string; // NOTE: styled-components でスタイルを上書きするためのクラス名
};

export const SelectFormForOnClick = ({
  valueToDisplay,
  errorText,
  fullWidth,
  onClick,
  className,
}: Props) => {
  return (
    <StyledFormControl
      variant="outlined"
      error={Boolean(errorText)}
      fullWidth={fullWidth}
      className={className}
    >
      <Select<string>
        displayEmpty
        fullWidth
        value={valueToDisplay}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: "8px",
            },
          },
        }}
        sx={{
          "& .MuiSelect-icon": {
            color: theme.palette.grey[400], // NOTE: 逆三角形のアイコンの色を設定
          },
        }}
      >
        <MenuItem
          value={valueToDisplay}
          key={0}
          style={{
            height: "40px",
          }}
        >
          <StyledTypography variant="body2" color="textPrimary">
            {valueToDisplay}
          </StyledTypography>
        </MenuItem>
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
      <Tooltip title={valueToDisplay} arrow>
        <StyledOnClickTransparentBox
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        />
      </Tooltip>
    </StyledFormControl>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiInputBase-formControl {
    height: 40px;
  }
  .MuiSelect-select {
    height: 24px;
    padding: 8px 16px;
    text-align: left;
  }
  .MuiFormHelperText-contained {
    font-size: 10px;
  }
`;

const StyledOnClickTransparentBox = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
