import { Menu, MenuItem } from "@material-ui/core";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC, MouseEvent, memo, useCallback, useMemo, useState } from "react";

import { IconButton, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  newHire: NewGraduateToDisplayForAdmin;
  onClickDeleteAccountButton: () => void;
  onClickInvitationButton: () => void;
  onClickSwitchMentorButton: () => void;
  onClickAddTag: () => void;
  onClickAddScenario: () => void;
  onClickDisableScenario: () => void;
  onClickMoveSpace: () => void;
};

export const NewGraduateManageMenu: FC<Props> = memo(
  ({
    newHire,
    onClickDeleteAccountButton,
    onClickInvitationButton,
    onClickSwitchMentorButton,
    onClickAddTag,
    onClickAddScenario,
    onClickDisableScenario,
    onClickMoveSpace,
  }) => {
    const { currentUser } = useCurrentUser();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
    const handleClickHF = (e: MouseEvent<HTMLElement>, callback: () => void) => {
      e.stopPropagation();
      e.preventDefault();
      setAnchorEl(null);
      callback();
    };

    const menuOptions = useMemo(() => {
      const optionsForUnregisteredNewHire = [];

      // アカウント未登録の場合は招待モーダルを表示する
      if (newHire.canInvite()) {
        optionsForUnregisteredNewHire.push({
          title: "招待",
          func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickInvitationButton()),
        });
      }

      return [
        ...optionsForUnregisteredNewHire,

        {
          title: "タグ追加",
          func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, onClickAddTag),
        },
        {
          title: "受け入れチーム設定",
          func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickSwitchMentorButton()),
        },
        newHire.isUpperActiveScenarioLimit()
          ? []
          : {
              title: "シナリオ追加",
              func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickAddScenario()),
            },
        !newHire.canDisableScenario()
          ? []
          : {
              title: "シナリオ中断",
              func: (e: MouseEvent<HTMLElement>) =>
                handleClickHF(e, () => onClickDisableScenario()),
            },
        currentUser.isAdmin()
          ? {
              title: "年次移動",
              func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => onClickMoveSpace()),
            }
          : [],
        {
          title: "アカウント削除",
          func: (e: MouseEvent<HTMLElement>) =>
            handleClickHF(e, () => onClickDeleteAccountButton()),
        },
      ].flat();
    }, [
      newHire,
      currentUser,
      onClickInvitationButton,
      onClickAddTag,
      onClickSwitchMentorButton,
      onClickAddScenario,
      onClickDisableScenario,
      onClickMoveSpace,
      onClickDeleteAccountButton,
    ]);

    const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(null);
    }, []);

    return (
      <>
        <IconButton icon="menuVert" onClick={handleOpenMenu} />
        <Menu
          key={newHire.id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {menuOptions.map((option) => (
            <MenuItem key={option.title} onClick={option.func}>
              <Typography variant="body2">{option.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);
