import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE NOTE SCHEMA
/////////////////////////////////////////

export const EmployeeNoteSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  value: z.string(),
  employeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeNote = z.infer<typeof EmployeeNoteSchema>;

/////////////////////////////////////////
// EMPLOYEE NOTE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeNoteOptionalDefaultsSchema = EmployeeNoteSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type EmployeeNoteOptionalDefaults = z.infer<typeof EmployeeNoteOptionalDefaultsSchema>;

export default EmployeeNoteSchema;
