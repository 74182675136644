import { generateAllContactRoomFromExcludeMethods } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

/**
 * contentProviderの解体に伴いuseContactRoomsV2を作成
 * 今後はこちらを使用する。
 * ログインユーザー紐づくコンタクトルームの一覧を取得する
 */
export const useContactRoomsV2 = ({
  tenantId,
  isIncludeCurrentUserContactRoom,
  limit,
  contactRoomIds,
}: {
  tenantId: string;
  isIncludeCurrentUserContactRoom?: boolean;
  limit?: number;
  contactRoomIds?: string[];
}) => {
  const { currentUser } = useCurrentUser();
  // NOTE: 同一PCで別テナントにログインした際にキャッシュが残ってしまうので、tenantIdをキーにしている
  const keyObj = {
    endpoint: `/get_contact_rooms`,
    tenantId,
    isIncludeCurrentUserContactRoom,
    requestUserId: currentUser.id,
    limit,
    contactRoomIds,
  } as const;

  return useSWR(
    keyObj,
    async ({ endpoint, isIncludeCurrentUserContactRoom, limit, contactRoomIds }) => {
      const response = await apiClient.get(endpoint, {
        "include-current-user-contact-room": isIncludeCurrentUserContactRoom,
        limit,
        "contact-room-ids": contactRoomIds,
      });

      return response.data.map((d) => generateAllContactRoomFromExcludeMethods(d));
    }
  );
};
