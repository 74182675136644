import { OnnTaskQuestion } from "@onn/common";

import { State } from "../../_share_in_create_edit/reducer";

export const useCreateInitialState = () => {
  const createInitialState = ({
    onnTaskTitle,
    onnTaskDescription,
    questions,
    isAllowEditAnswer,
    isAlreadySentToSomeone,
  }: {
    onnTaskTitle: string;
    onnTaskDescription: string;
    questions: OnnTaskQuestion[];
    isAllowEditAnswer: boolean;
    isAlreadySentToSomeone: boolean;
  }): State => {
    return {
      onnTaskTitleTextField: {
        value: onnTaskTitle,
        error: false,
        helperText: "",
        isDirty: false,
      },
      onnTaskDescriptionTextField: {
        value: onnTaskDescription,
        error: false,
        helperText: "",
        isDirty: false,
      },
      selectedOnnTaskTypes: "FORM_TASK",
      isAllowEditAnswer,
      questions: questions.map((question) => {
        // 既に配信済みのタスクの場合
        if (isAlreadySentToSomeone) {
          if (question.type === "CHECK_BOX" || question.type === "RADIO") {
            return {
              ...question,
              options: question.options,
              disabledOptionType: true,
              isDirtyTitle: false,
              isTitleError: false,
              titleHelperText: "",
            };
          } else if (question.type === "FILE") {
            return {
              ...question,
              disabledOptionType: true,
              sampleFilePathForPreview: question.sampleFilePath.replace(
                "/sample/",
                "/sampleForPreview/"
              ),
              file: undefined,
              isDirtyTitle: false,
              isTitleError: false,
              titleHelperText: "",
            };
          } else {
            return {
              ...question,
              disabledOptionType: true,
              isDirtyTitle: false,
              isTitleError: false,
              titleHelperText: "",
              wordLimit: question.wordLimit ? String(question.wordLimit) : "",
            };
          }
        }

        // 未配信のタスクの場合
        if (question.type === "TEXT") {
          return {
            ...question,
            disabledOptionType: false,
            isDirtyTitle: false,
            isTitleError: false,
            titleHelperText: "",
            wordLimit: question.wordLimit ? String(question.wordLimit) : "",
          };
        }
        if (question.type !== "FILE") {
          return {
            ...question,
            disabledOptionType: false,
            isDirtyTitle: false,
            isTitleError: false,
            titleHelperText: "",
          };
        }
        return {
          ...question,
          disabledOptionType: false,
          sampleFilePathForPreview: question.sampleFilePath.replace(
            "/sample/",
            "/sampleForPreview/"
          ),
          file: undefined,
          isDirtyTitle: false,
          isTitleError: false,
          titleHelperText: "",
        };
      }),
    };
  };

  return { createInitialState };
};
