import { TaskOptionTypes } from "@onn/common/domain/OnnTask/Question";
import React, { useCallback } from "react";

import { v4 } from "uuid";

import { Action } from "../../_share_in_create_edit/reducer";
import {
  CHECK_BOX_QUESTION_FOR_FORM,
  FIlE_QUESTION_FOR_FORM,
  QuestionForState,
  RADIO_QUESTION_FOR_FORM,
  TEXT_QUESTION_FOR_FORM,
} from "../../_share_in_create_edit/types";

// NOTE:リファクタリングの余地あり
export const useQuestionFormBase = ({
  dispatch,
  question,
}: {
  dispatch: React.Dispatch<Action>;
  question: Pick<
    QuestionForState,
    | "id"
    | "title"
    | "isRequired"
    | "isDirtyTitle"
    | "isTitleError"
    | "titleHelperText"
    | "disabledOptionType"
  >;
}) => {
  const onChangeQuestionOptionType = (
    e: React.ChangeEvent<{ value: keyof typeof TaskOptionTypes }>
  ) => {
    const taskOptionType = e.target.value;

    dispatch({
      type: "CHANGE_QUESTION_OPTION_TYPE",
      payload: {
        taskOptionType,
        questionId: question.id,
        question: (() => {
          switch (taskOptionType) {
            case "CHECK_BOX":
              // eslint-disable-next-line no-case-declarations
              const _question: CHECK_BOX_QUESTION_FOR_FORM = {
                id: question.id,
                type: "CHECK_BOX",
                isRequired: question.isRequired,
                options: [
                  {
                    id: v4(),
                    text: "選択肢 1",
                  },
                  {
                    id: v4(),
                    text: "選択肢 2",
                  },
                ],
                title: question.title,
                allowFreeText: false,
                disabledOptionType: question.disabledOptionType,
                isDirtyTitle: question.isRequired,
                titleHelperText: question.titleHelperText,
                isTitleError: question.isTitleError,
              };
              return _question;
            case "TEXT":
              // eslint-disable-next-line no-case-declarations
              const textQuestion: TEXT_QUESTION_FOR_FORM = {
                id: question.id,
                type: "TEXT",
                isRequired: question.isRequired,
                title: question.title,
                disabledOptionType: question.disabledOptionType,
                isDirtyTitle: question.isRequired,
                titleHelperText: question.titleHelperText,
                isTitleError: question.isTitleError,
                isWordLimit: false,
              };
              return textQuestion;
            case "FILE":
              return {
                id: question.id,
                type: "FILE",
                isRequired: question.isRequired,
                title: question.title,
                sampleFilePath: "",
                sampleFile: undefined,
                disabledOptionType: question.disabledOptionType,
                isDirtyTitle: question.isRequired,
                titleHelperText: question.titleHelperText,
                isTitleError: question.isTitleError,
              } as FIlE_QUESTION_FOR_FORM;
            case "RADIO":
              return {
                id: question.id,
                type: "RADIO",
                isRequired: question.isRequired,
                options: [
                  {
                    id: v4(),
                    text: "選択肢 1",
                  },
                  {
                    id: v4(),
                    text: "選択肢 2",
                  },
                ],
                title: question.title,
                disabledOptionType: question.disabledOptionType,
                isDirtyTitle: question.isRequired,
                titleHelperText: question.titleHelperText,
                isTitleError: question.isTitleError,
              } as RADIO_QUESTION_FOR_FORM;
          }
        })(),
      },
    });
  };

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value;
      const isEmpty = title === "";
      const isError = isEmpty && question.isRequired && question.isDirtyTitle;
      dispatch({
        type: "CHANGE_QUESTION_TITLE",
        payload: {
          title: e.target.value,
          questionId: question.id,
          isError,
          helperText: isError ? "設問名を入力してください" : "",
        },
      });
    },
    [dispatch, question.id, question.isDirtyTitle, question.isRequired]
  );

  const onChangeIsRequired = () => {
    dispatch({
      type: "CHANGE_ONN_TASK_REQUIRED",
      payload: {
        questionId: question.id,
        isRequired: !question.isRequired,
      },
    });
  };

  const deleteQuestion = () => {
    dispatch({
      type: "DELETE_QUESTION",
      payload: {
        questionId: question.id,
      },
    });
  };

  const onChangeOptionOrder = useCallback(
    ({ sourceIndex, destinationIndex }: { sourceIndex: number; destinationIndex: number }) => {
      dispatch({
        type: "CHANGE_OPTION_ORDER",
        payload: {
          questionId: question.id,
          sourceIndex,
          destinationIndex,
        },
      });
    },
    [dispatch, question.id]
  );

  return {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    onChangeOptionOrder,
  };
};
