import { Box, Stack } from "@mui/material";
import React, { useState } from "react";

import { SelectConditionMenuItem } from "./SelectConditionMenuItem";

import {
  Checkbox,
  IconButton,
  TooltipWhenTextTruncatedOneLine,
  Typography,
} from "~/components/uiParts";

export type MenuItem<T> = {
  key: string;
  label: string;
  sampleValue?: string;
  detailItems: {
    key: T;
    label: string;
    sampleValue?: string;
    isSelected: boolean;
  }[];
};

type Props<T> = {
  toggleIsSelectedDetailsItem: (key: T) => void;
  selectAllDetailItems: (detailItemKeys: T[]) => void;
  unselectAllDetailItems: (detailItemKeys: T[]) => void;
  menuItem: MenuItem<T>;
};

export const SelectConditionMenuItemWithDetailItems = <T extends string>({
  selectAllDetailItems,
  unselectAllDetailItems,
  toggleIsSelectedDetailsItem,
  menuItem,
}: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const selectableKeys = menuItem.detailItems.map((detailItem) => detailItem.key);
  const isSelectedAll = menuItem.detailItems.every((detailItem) => detailItem.isSelected);
  const selectedCount = menuItem.detailItems.filter((detailItem) => detailItem.isSelected).length;

  return (
    <>
      <SelectConditionMenuItem
        toggleIsSelected={
          isSelectedAll
            ? () => unselectAllDetailItems(selectableKeys)
            : () => selectAllDetailItems(selectableKeys)
        }
        menuItem={{
          key: menuItem.key,
          label: menuItem.label,
          isSelected: selectedCount > 0,
          indeterminate: selectedCount > 0 && !isSelectedAll,
          sampleValue: menuItem.sampleValue,
          selectedCount,
        }}
        bottomNode={
          <Box display="flex" justifyContent="end" columnGap="8px" alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded((prev) => !prev);
              }}
            >
              <Typography variant="body2" color="primary">
                {isExpanded ? "詳細項目を隠す" : "詳細項目を表示"}
              </Typography>
              <IconButton size="md" icon={isExpanded ? "chevronUp" : "chevronDown"} color="grey" />
            </Stack>
          </Box>
        }
      />
      {isExpanded &&
        menuItem.detailItems?.map((detailItem) => (
          <Box
            key={detailItem.key}
            display="flex"
            columnGap="8px"
            py="24px"
            pr="16px"
            pl="80px"
            alignItems="center"
            width="100%"
            onClick={() => toggleIsSelectedDetailsItem(detailItem.key)}
            sx={{
              cursor: "pointer",
              borderTop: ({ palette }) => `1px solid ${palette.grey[100]}`,
            }}
          >
            <Checkbox checked={detailItem.isSelected} style={{ padding: 0 }} />
            <Box
              display="flex"
              alignItems="center"
              flex={1}
              width="calc(100% - 20px)" // checkboxの分を引く
              position="relative"
            >
              <Typography variant="body2" style={{ width: "50%" }}>
                {detailItem.label}
              </Typography>
              {detailItem.sampleValue && (
                <TooltipWhenTextTruncatedOneLine
                  typographyProps={{
                    variant: "body2",
                    color: "textSecondary",
                    style: { width: "50%" },
                  }}
                  text={`例) ${detailItem.sampleValue}`}
                />
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};
