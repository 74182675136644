import { APISchema, generateAllContactRoomFromExcludeMethods } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";
import { getSelectedSpaceId } from "~/libs/spaceIdFromStorage";

type EndPoint = APISchema["/api/contact-room/with-limit"]["GET"];

const generateKey = ({
  spaceId,
  requestUserId,
  limit,
}: {
  spaceId: string;
  requestUserId: string;
  limit: number;
}) => {
  return {
    path: "/api/contact-room/with-limit",
    spaceId,
    requestUserId,
    limit,
  } as const;
};

/**
 * WARN: 管理画面でのみ使用可能
 */
export const useContactRoomsWithLimit = () => {
  const { currentUser } = useCurrentUser();
  const spaceId = getSelectedSpaceId(currentUser.tenantId);

  // NOTE: ポータルから呼ばれる場合、/api/contact-room/with-limit からルームは取得できないため、keyをnullにする
  return useSWR(
    spaceId
      ? generateKey({
          spaceId,
          requestUserId: currentUser.id,
          limit: 100,
        })
      : null,
    async ({ path, limit }) => {
      const queryParam: EndPoint["query"] = {
        limit,
      };
      const response = await apiClient.get(path, queryParam);

      return response.data.map((d) => generateAllContactRoomFromExcludeMethods(d));
    }
  );
};
