import { ISpace, Phase, spaceSchema } from "./schema";

export class Space implements ISpace {
  static validator = spaceSchema;

  id: string;
  name: string; // 3文字が上限
  tenantId: string;
  order: number; // 表示順を自由にコントロールするため
  phase: Phase; // あくまでメインの活動を表現するフェーズで、選考フェーズでも内定者フォローの活動なども存在する
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<Space>) {
    const parsedInit = Space.validator.parse(init);

    this.id = parsedInit.id;
    this.name = parsedInit.name;
    this.tenantId = parsedInit.tenantId;
    this.order = parsedInit.order;
    this.phase = parsedInit.phase;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    init: Pick<ExcludeMethods<Space>, "name" | "tenantId" | "order" | "phase">,
    id?: string
  ) {
    const createdAt = new Date();
    const updatedAt = new Date();
    const parsedInit = Space.validator.parse({ ...init, id, createdAt, updatedAt });

    return new Space(parsedInit);
  }
}
