import { Box, Stack } from "@mui/material";
import { Employee, RecruitmentStatus, Scenario, Space } from "@onn/common";
import React, { ComponentProps, FC } from "react";
import { FormProvider, useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { FieldValues } from "./hooks/FieldValues";
import { useForm } from "./hooks/useForm";

import { Button, Loading, Modal, Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useSpacesAndScenariosAndRecruitmentStatuses } from "~/hooks/space/useSpacesAndScenariosAndRecruitmentStatuses";
import { mixin } from "~/util";

type SpaceData = {
  space: Space;
  scenariosAndRecruitmentStatuses: {
    scenario: Scenario;
    recruitmentStatuses: RecruitmentStatus[];
  }[];
}[];

type Props = {
  open: boolean;
  onCancel: () => void;
  onMoveSpace?: () => void | Promise<void>;
  newGraduate: Employee;
};
export const MoveSpaceModal: FC<Props> = ({ open, onCancel, newGraduate, onMoveSpace }) => {
  const { data: spaceData = [], isLoading } = useSpacesAndScenariosAndRecruitmentStatuses();
  return (
    <Modal
      open={open}
      title="年次移動"
      content={
        isLoading ? (
          <Loading color="primary" size="large" />
        ) : (
          <ModalContent
            employeeId={newGraduate.id}
            employeeName={newGraduate.getName()}
            spaceData={spaceData}
            onCancel={onCancel}
            onMoveSpace={onMoveSpace}
          />
        )
      }
      onCancel={onCancel}
    />
  );
};

const ModalContent: FC<{
  employeeId: string;
  employeeName: string;
  spaceData: SpaceData;
  onCancel: () => void;
  onMoveSpace?: () => void | Promise<void>;
}> = ({ employeeId, employeeName, spaceData, onCancel }) => {
  const { form, handleSubmit } = useForm({
    newGraduateId: employeeId,
  });
  const { watch } = form;
  const { isDirty, isLoading, isSubmitting, isValid } = form.formState;
  const selectedSpaceId = watch("toSpaceId");
  const selectedScenarioId = watch("toScenarioId");

  const { spaceSelectOptions, scenarioSelectOptions, recruitmentStatusSelectOptions } =
    useSelectorOptions({
      spaceData,
      selectedSpaceId,
      selectedScenarioId,
    });

  return (
    <FormProvider {...form}>
      <Stack rowGap={"24px"}>
        <Stack alignItems="center">
          <Box mb="24px">
            <Typography variant="body1" align="center">
              {employeeName}さんの年次移動を行います。
              <br />
              年次移動を行うと移動元の候補者に関するデータは全て削除されます。
            </Typography>
          </Box>

          <Stack width="60%" rowGap="16px" mb="32px">
            <SpaceForm menuOptions={spaceSelectOptions} />
            {selectedSpaceId && <ScenarioForm menuItems={scenarioSelectOptions} />}
            {selectedScenarioId && (
              <RecruitmentStatusForm menuItems={recruitmentStatusSelectOptions} />
            )}
          </Stack>

          <Box display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。
            </Typography>
          </Box>
        </Stack>
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={!isValid || !isDirty || isLoading || isSubmitting}
            isLoading={isLoading || isSubmitting}
            onClick={async () => {
              await handleSubmit();
              onCancel();
            }}
          >
            移動
          </Button>
        </StyledButtonContainer>
      </Stack>
    </FormProvider>
  );
};

const useSelectorOptions = ({
  spaceData,
  selectedSpaceId,
  selectedScenarioId,
}: {
  spaceData: SpaceData;
  selectedSpaceId: string;
  selectedScenarioId: string;
}) => {
  const { currentSpace } = useCurrentSpace();
  const selectableSpaces = spaceData.map((spaceData) => spaceData.space);
  const spaceSelectOptions = selectableSpaces.map((space) => ({
    value: space.id,
    name: space.name,
    disabled: space.id === currentSpace.id,
  }));
  const selectableScenarios = spaceData.flatMap(({ space, scenariosAndRecruitmentStatuses }) => {
    if (space.id !== selectedSpaceId) return [];
    return scenariosAndRecruitmentStatuses.map(({ scenario }) => scenario);
  });
  const scenarioSelectOptions = selectableScenarios.map((scenario) => ({
    value: scenario.id,
    name: scenario.name,
  }));

  const selectableRecruitmentStatuses = spaceData.flatMap(
    ({ space, scenariosAndRecruitmentStatuses }) => {
      if (space.id !== selectedSpaceId) return [];
      return scenariosAndRecruitmentStatuses.flatMap(({ recruitmentStatuses, scenario }) => {
        if (scenario.id !== selectedScenarioId) return [];
        return recruitmentStatuses;
      });
    }
  );
  const recruitmentStatusSelectOptions = selectableRecruitmentStatuses.map((recruitmentStatus) => ({
    value: recruitmentStatus.id,
    name: recruitmentStatus.label,
  }));

  return {
    spaceSelectOptions,
    scenarioSelectOptions,
    recruitmentStatusSelectOptions,
  };
};

const SpaceForm: FC<{
  menuOptions: {
    value: string;
    name: string;
    disabled?: boolean;
  }[];
}> = ({ menuOptions }) => {
  const { field, fieldState } = useController<FieldValues>({
    name: "toSpaceId",
  });
  const { resetField } = useFormContext<FieldValues>();
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        年次
      </Typography>
      <SelectFormV2
        labelWhenNoSelected="選択してください"
        selected={field.value}
        onChange={(e) => {
          field.onChange(e.target.value);
          resetField("toScenarioId");
          resetField("toRecruitmentStatusId");
        }}
        errorText={fieldState.error?.message}
        menuItems={menuOptions}
        fullWidth
      />
    </Stack>
  );
};

const ScenarioForm: FC<{ menuItems: ComponentProps<typeof SelectFormV2>["menuItems"] }> = ({
  menuItems,
}) => {
  const { field, fieldState } = useController<FieldValues>({
    name: "toScenarioId",
  });
  const { resetField } = useFormContext<FieldValues>();
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        シナリオ
      </Typography>
      <SelectFormV2
        labelWhenNoSelected="選択してください"
        selected={field.value}
        onChange={(e) => {
          field.onChange(e.target.value);
          resetField("toRecruitmentStatusId");
        }}
        errorText={fieldState.error?.message}
        menuItems={menuItems}
        fullWidth
      />
    </Stack>
  );
};

const RecruitmentStatusForm: FC<{
  menuItems: ComponentProps<typeof SelectFormV2>["menuItems"];
}> = ({ menuItems }) => {
  const { field, fieldState } = useController<FieldValues>({
    name: "toRecruitmentStatusId",
  });
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        選考ステータス
      </Typography>
      <SelectFormV2
        labelWhenNoSelected="選択してください"
        selected={field.value}
        onChange={(e) => {
          field.onChange(e.target.value);
        }}
        errorText={fieldState.error?.message}
        menuItems={menuItems}
        fullWidth
      />
    </Stack>
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
