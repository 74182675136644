import { Box, Menu } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { EmployeeList, MultipleMenuText, SearchForm, SingleMenuText } from "./parts";

import { Button, Divider, Icon, Typography } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";

type Props = {
  employees: Employee[];
  width?: string;
  onSearch: (query: string) => void;
  keywordsString: string;
  isLoading: boolean;
  hasNextSelectableNewGraduates: boolean;
} & (
  | {
      selectedEmployees: Employee[];
      selectEmployees: (employees: Employee[]) => void;
      isMultiple: true;
    }
  | {
      selectedEmployee?: Employee;
      selectEmployee: (employee?: Employee) => void;
      isMultiple: false;
    }
);

export const NewGraduateSelectMenuWithSearch: FC<Props> = ({
  width = "240px",
  employees,
  onSearch,
  keywordsString,
  isLoading,
  hasNextSelectableNewGraduates,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isEmptyList = !isLoading && employees.length === 0;
  const employeeList = props.isMultiple ? (
    <EmployeeList
      employees={employees}
      selectedEmployees={props.selectedEmployees}
      onSelect={(employees) => props.selectEmployees(employees)}
      isMultiple={props.isMultiple}
      hasNext={hasNextSelectableNewGraduates}
    />
  ) : (
    <EmployeeList
      employees={employees}
      selectedEmployee={props.selectedEmployee}
      onSelect={(employee) => {
        props.selectEmployee(employee);
        setAnchorEl(null);
      }}
      isMultiple={props.isMultiple}
      hasNext={hasNextSelectableNewGraduates}
    />
  );

  return (
    <>
      <StyledBox $width={width}>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" alignItems="center" gridGap="4px">
            {props.isMultiple ? (
              <MultipleMenuText selectedEmployees={props.selectedEmployees} />
            ) : (
              <SingleMenuText selectedEmployee={props.selectedEmployee} />
            )}
            <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
          </Box>
        </Button>
      </StyledBox>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <Box>
          <Box p="16px">
            <SearchForm employees={employees} onSearch={onSearch} keywordsString={keywordsString} />
          </Box>
          <Divider />
          {
            // noRowsRenderer がなぜか表示されないため、ここで表示する
            // FIXME: noRowsRenderer で表示できるようにする
            isEmptyList ? (
              <CenterBox style={{ margin: "16px" }}>
                <Typography variant="body2" color="textSecondary">
                  結果が見つかりません
                </Typography>
              </CenterBox>
            ) : (
              employeeList
            )
          }
        </Box>
      </Menu>
    </>
  );
};

const StyledBox = styled(Box)<{ $width: string }>`
  width: ${(props) => props.$width};
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
