import {
  Employee,
  NewGraduateToDisplayForAdmin,
  OnnFormTaskAnswer,
  OnnTask,
  sleep,
} from "@onn/common";
import { endOfDay } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCanAnswerAfterDeadlineInput } from "./input/useCanAnswerAfterDeadlineInput";
import { useDeadlineDateInput } from "./input/useDeadlineDateInput";

import { useScheduledDateInput } from "./input/useScheduledDateInput";

import { useEnqueueSuccessSnackbar } from "./snackbar/useEnqueueSuccessSnackbar";
import { useRegisteredNewGraduates } from "./useRegisteredNewGraduates";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";
import { useModal } from "~/hooks/modal";
import { useUpdateDeliverySettings } from "~/hooks/onnTask";
import { useSnackbar } from "~/hooks/shared";
import { usePrompt, usePromptWithoutNavigate } from "~/hooks/shared/usePrompt";
import { captureException } from "~/util";

export const usePage = ({
  onnTask,
  onnFormTaskAnswers,
  fromPage,
}: {
  onnTask: OnnTask;
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  fromPage: string;
}) => {
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const [isChanged, setIsChanged] = useState(false);

  // 新規作成画面から遷移してきた時のみブラウザバック無効ダイアログを表示する
  const [isDisplayPromptForBrowserBack, setIsDisplayPromptForBrowserBack] = useState(
    fromPage === "create"
  );

  const [deadlineDate, setDeadlineDate] = useState<Date | null>(onnTask.deadlineDate ?? null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(onnTask.scheduledDate ?? null);
  const [canAnswerAfterDeadline, setCanAnswerAfterDeadline] = useState(
    onnTask.canAnswerAfterDeadline ?? false
  );

  const isAlreadyDelivered = !!onnTask.firstDeliveredAt;

  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);
  const { updateDeliverySettings } = useUpdateDeliverySettings();

  // 新規追加された候補者
  const [newSelectedNewGraduates, setNewSelectedNewGraduates] = useState<Employee[]>([]);

  const { enqueueSuccessSnackbar } = useEnqueueSuccessSnackbar({
    fromPage,
    newSelectedNewGraduates,
    isAlreadyDelivered,
  });

  const updateDeadlineDate = useCallback(
    (date: Date | null) => {
      if (!isChanged) setIsChanged(true);
      // deadlineDateは当日の23:59:59として扱うのでendOfDayを使う
      setDeadlineDate(date ? endOfDay(date) : null);
    },
    [isChanged]
  );

  const updateScheduledDate = useCallback(
    (date: Date | null) => {
      if (!isChanged) setIsChanged(true);
      setScheduledDate(date);
    },
    [isChanged]
  );

  const updateCanAnswerAfterDeadline = useCallback(
    (bool: boolean) => {
      if (!isChanged) setIsChanged(true);
      setCanAnswerAfterDeadline(bool);
    },
    [isChanged]
  );

  // ブラウザバック無効ダイアログと重なるのでisDisplayPromptForBrowserBackを確認する
  usePrompt("変更内容を破棄しますか？", !isDisplayPromptForBrowserBack && isChanged);
  usePromptWithoutNavigate(
    "このページではブラウザバックは無効になっています。画面上の「戻る」ボタンから操作してください。",
    isDisplayPromptForBrowserBack
  );

  const scheduledDateInput = useScheduledDateInput({
    deadlineDate,
    scheduledDate,
    initialValue: onnTask.scheduledDate,
    isFixed: isAlreadyDelivered,
  });
  const deadlineDateInput = useDeadlineDateInput({
    deadlineDate,
    scheduledDate,
    initialValue: onnTask.deadlineDate,
  });
  const canAnswerAfterDeadlineInput = useCanAnswerAfterDeadlineInput({
    canAnswerAfterDeadline,
    initialValue: onnTask.canAnswerAfterDeadline,
  });

  const { keptNewGraduateIds, isDeleted, deleteFromRegisteredNewGraduates } =
    useRegisteredNewGraduates(onnFormTaskAnswers);

  const newGraduateIdsToSave = useMemo(
    () =>
      Array.from(
        new Set(
          // 重複削除している
          [...keptNewGraduateIds, ...newSelectedNewGraduates.map((v) => v.id)]
        )
      ),
    [newSelectedNewGraduates, keptNewGraduateIds]
  );

  const _onClickConfirmSave = useCallback(
    async (isForce?: boolean) => {
      if (deadlineDateInput.error || scheduledDateInput.error) {
        enqueueSnackbar(
          "予約配信の設定で不適切な値が入力されている、または未入力項目があります。",
          {
            variant: "error",
          }
        );

        return;
      }
      try {
        setIsDisplayPromptForBrowserBack(false);
        setIsLoadingSaveButton(true);
        setIsChanged(false);
        await updateDeliverySettings({
          onnFormTaskId: onnTask.id,
          scheduledDate: scheduledDateInput.isChanged ? scheduledDate : undefined,
          deadlineDate: deadlineDateInput.isChanged ? deadlineDate : undefined,
          canAnswerAfterDeadline: canAnswerAfterDeadlineInput.canAnswerAfterDeadline,
          newGraduateIds: newGraduateIdsToSave,
          forceNotifyImmediately: isForce,
        });
        enqueueSuccessSnackbar();
        navigate(`/onn_tasks/${onnTask.id}`);
      } catch (e) {
        enqueueSnackbar("配信設定の更新に失敗しました。管理者より連絡がくるまで、お待ちください", {
          variant: "error",
        });
        navigate(`/onn_tasks/${onnTask.id}`);
        captureException({
          error: e as Error,
          tags: { type: "useHooks:onClickConfirmSave" },
        });
      } finally {
        setIsLoadingSaveButton(false);
      }
    },
    [
      deadlineDateInput.error,
      deadlineDateInput.isChanged,
      scheduledDateInput.error,
      scheduledDateInput.isChanged,
      enqueueSnackbar,
      updateDeliverySettings,
      onnTask.id,
      scheduledDate,
      deadlineDate,
      canAnswerAfterDeadlineInput.canAnswerAfterDeadline,
      newGraduateIdsToSave,
      enqueueSuccessSnackbar,
      navigate,
    ]
  );

  const onClickConfirmSave = useCallback(async () => {
    if (!isAlreadyDelivered) {
      await _onClickConfirmSave();
    } else {
      withBusinessHours(_onClickConfirmSave);
    }
  }, [_onClickConfirmSave, isAlreadyDelivered, withBusinessHours]);

  const onClickGoBack = useCallback(async () => {
    setIsDisplayPromptForBrowserBack(false);
    // 即時に画面遷移すると、usePromptWithoutNavigateのポップアップが表示されてしまうため、少し待つ
    await sleep(10);
    navigate(`/onn_tasks/${onnTask.id}/edit?from_page=delivery_setting`);
  }, [navigate, onnTask.id]);

  const onClickSetLater = useCallback(async () => {
    setIsDisplayPromptForBrowserBack(false);
    // 即時に画面遷移すると、usePromptWithoutNavigateのポップアップが表示されてしまうため、少し待つ
    await sleep(10);
    navigate(`/onn_tasks/${onnTask.id}`);
  }, [navigate, onnTask.id]);

  const _onClickCancel = useCallback(async () => {
    setIsDisplayPromptForBrowserBack(false);
    // 即時に画面遷移すると、usePromptWithoutNavigateのポップアップが表示されてしまうため、少し待つ
    await sleep(10);
    if (fromPage === "detail") {
      navigate(`/onn_tasks/${onnTask.id}`);
    } else {
      navigate("/onn_tasks");
    }
  }, [fromPage, navigate, onnTask.id]);

  const onClickUploadCSVButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: newSelectedNewGraduates,
        registeredEmployeeIds: keptNewGraduateIds,
        onSubmit(newTargets) {
          setNewSelectedNewGraduates((prev) => {
            const prevIds = prev.map((v) => v.id);
            const newSelectedNewGraduates = [
              ...prev,
              ...newTargets.filter((v) => !prevIds.includes(v.id)),
            ];
            return newSelectedNewGraduates;
          });
        },
      },
    });
  }, [handleModal, keptNewGraduateIds, newSelectedNewGraduates]);

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToOnnEventButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        filter: (employee: NewGraduateToDisplayForAdmin) => {
          if (!employee.canUseNotificationFeatures()) return false;

          const isAlreadySelected = newSelectedNewGraduates.map((e) => e.id).includes(employee.id);
          const isRegisteredAndKept = keptNewGraduateIds.some((id) => id === employee.id);

          return !isAlreadySelected && !isRegisteredAndKept;
        },
        onSubmit: async (modalSelectedNewGraduates: Employee[]) => {
          setNewSelectedNewGraduates([...modalSelectedNewGraduates, ...newSelectedNewGraduates]);
          if (!isChanged) setIsChanged(true);
        },
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [handleModal, onClickUploadCSVButton, newSelectedNewGraduates, keptNewGraduateIds, isChanged]);

  // 新規に追加された候補者を削除する
  const deleteFromNewSelectedNewGraduates = useCallback(
    (target: Employee) => {
      const updatedNewSelectedNewGraduates = newSelectedNewGraduates.filter(
        (v) => v.id !== target.id
      );
      setNewSelectedNewGraduates(updatedNewSelectedNewGraduates);
      if (updatedNewSelectedNewGraduates.length === 0) setIsChanged(false);
    },
    [newSelectedNewGraduates]
  );

  const isChangedSome =
    deadlineDateInput.isChanged ||
    scheduledDateInput.isChanged ||
    canAnswerAfterDeadlineInput.isChanged ||
    newSelectedNewGraduates.length > 0 ||
    isDeleted;
  const isErrorSome =
    deadlineDateInput.error || scheduledDateInput.error || canAnswerAfterDeadlineInput.error;

  const isDisabledSaveButton = !isChangedSome || isErrorSome || isLoadingSaveButton;

  const cancelButtonText = useMemo(() => {
    const isFromCreateOrEdit = fromPage === "create" || fromPage === "edit";
    return isFromCreateOrEdit ? "あとで設定" : "キャンセル";
  }, [fromPage]);

  const onClickCancel = useCallback(() => {
    const isFromCreateOrEdit = fromPage === "create" || fromPage === "edit";
    return isFromCreateOrEdit ? onClickSetLater() : _onClickCancel();
  }, [_onClickCancel, fromPage, onClickSetLater]);

  const submitButtonText = useMemo(() => {
    return isAlreadyDelivered ? "保存して配信" : "配信予約";
  }, [isAlreadyDelivered]);

  const isShowGoBackButton = useMemo(() => {
    const isFromCreateOrEdit = fromPage === "create" || fromPage === "edit";
    return isFromCreateOrEdit;
  }, [fromPage]);

  return {
    isAlreadyDelivered,
    // Pageから受け取ったprops
    fromPage,
    // state
    onnTask,
    onnFormTaskAnswers,
    deadlineDate,
    scheduledDate,
    canAnswerAfterDeadline,

    // 更新用関数
    updateDeadlineDate,
    updateScheduledDate,
    updateCanAnswerAfterDeadline,

    // フォームのエラー表示用のオブジェクト
    scheduledDateInput,
    deadlineDateInput,
    canAnswerAfterDeadlineInput,

    newSelectedNewGraduates,
    keptNewGraduateIds,
    deleteFromNewSelectedNewGraduates,
    deleteFromRegisteredNewGraduates,

    onClickAddNewGraduateToOnnEventButton,
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    onClickUploadCSVButton,

    isDisabledSaveButton,
    isLoadingSaveButton,

    cancelButtonText,
    submitButtonText,

    isShowGoBackButton,
  };
};
