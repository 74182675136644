import { MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, NewGraduate, splitSearchString } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "./useCurrentUser";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";

import { apiClient } from "~/libs";

/**
 * 候補者を検索する
 *
 * - 数件の候補者を取得するためのもので、1ページのみ取得する
 */
export const useSearchNewGraduates = ({
  conditions,
  logicType,
  keywordsString: _keywordsString,
}: SearchArgs) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    getKey({
      tenantId: currentUser.tenantId,
      type: logicType,
      conditions,
      keywordsString: _keywordsString,
    }),
    async ({ endpoint, type, conditions, keywordsString }) => {
      const response = await apiClient.post(endpoint, {
        type,
        conditions,
        keywordsString,
        cursor: null,
      });

      return {
        newGraduates: response.newGraduates.map((n) => new NewGraduate(n)),
        hasNext: !!response.nextCursor,
      };
    }
  );
};

const endpoint = "/api/new-graduates/search" as const;

const getKey = ({
  tenantId,
  type,
  conditions,
  keywordsString: _keywordsString,
}: {
  tenantId: string;
  type: LogicType;
  conditions: AnyValidCondition[];
  keywordsString: string;
}) => {
  // 検索キーワードは5つまでに制限する
  // SWR のキーを変えないようにするため、5つまでのキーワードを使ったキーワード文字列を生成してキーに含める
  const firstFiveKeywords = splitSearchString(_keywordsString).slice(
    0,
    MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT
  );
  const keywordsString = firstFiveKeywords.join(" ");

  return {
    endpoint,
    tenantId,
    type,
    conditions,
    keywordsString,
  } as const;
};

type SearchArgs = {
  conditions: AnyValidCondition[];
  logicType: LogicType;
  keywordsString: string;
};
