import { zodResolver } from "@hookform/resolvers/zod";
import { useForm as useRHF } from "react-hook-form";

import { FieldValues } from "./FieldValues";
import { generateFieldValueSchema } from "./fieldValueSchema";

import { useMoveNewGraduateToAnotherSpace } from "~/hooks/employee/useMoveNewGraduateToAnotherSpace";
import { useSnackbar } from "~/hooks/shared";
import { clearAllSwrCache } from "~/libs";

export const useForm = ({ newGraduateId }: { newGraduateId: string }) => {
  const { moveNewGraduateToAnotherSpace } = useMoveNewGraduateToAnotherSpace();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRHF<FieldValues>({
    defaultValues: {
      toSpaceId: "",
      toScenarioId: "",
      toRecruitmentStatusId: "",
    },
    resolver: zodResolver(generateFieldValueSchema()),
    mode: "all",
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      await moveNewGraduateToAnotherSpace({
        newGraduateId,
        toSpaceId: data.toSpaceId,
        toScenarioId: data.toScenarioId,
        toRecruitmentStatusId: data.toRecruitmentStatusId,
      });
      // NOTE: mutateが終了する前にモーダルが閉じるとmutateが正常に動作しないため、mutateが終了するまでawaitする
      // スペース移動は頻繁に起きる処理ではないため、許容範囲と判断
      await clearAllSwrCache();
      enqueueSnackbar("候補者のスペースを移動しました", { variant: "success" });
    } catch (_) {
      enqueueSnackbar("候補者のスペースの移動に失敗しました", { variant: "error" });
    }
  });

  return { form, handleSubmit };
};
