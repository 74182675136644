import React, { useCallback } from "react";

import { v4 } from "uuid";

import { Action } from "../../_share_in_create_edit/reducer";

import {
  CHECK_BOX_QUESTION_FOR_FORM,
  FIlE_QUESTION_FOR_FORM,
  RADIO_QUESTION_FOR_FORM,
  TEXT_QUESTION_FOR_FORM,
} from "../../_share_in_create_edit/types";

export const useDuplicationHandler = (dispatch: React.Dispatch<Action>) => {
  const onClickDuplicationTextQuestion = useCallback(
    (question: TEXT_QUESTION_FOR_FORM) => {
      dispatch({
        type: "DUPLICATION_QUESTION",
        payload: {
          question: {
            type: "TEXT",
            title: question.title,
            isRequired: question.isRequired,
            id: v4(),
            disabledOptionType: false,
            isDirtyTitle: question.isDirtyTitle,
            isTitleError: question.isTitleError,
            titleHelperText: question.titleHelperText,
            isWordLimit: question.isWordLimit,
            wordLimit: question.wordLimit,
          },
          fromQuestionId: question.id,
        },
      });
    },
    [dispatch]
  );

  const onClickDuplicationFileQuestion = useCallback(
    (question: FIlE_QUESTION_FOR_FORM) => {
      const questionId = v4();
      dispatch({
        type: "DUPLICATION_QUESTION",
        payload: {
          question: {
            type: "FILE",
            title: question.title,
            isRequired: question.isRequired,
            id: questionId,
            sampleFile: question.sampleFile,
            sampleFilePath: question.sampleFilePath,
            sampleFilePathForPreview: question.sampleFilePathForPreview,
            disabledOptionType: false,
            isDirtyTitle: question.isDirtyTitle,
            isTitleError: question.isTitleError,
            titleHelperText: question.titleHelperText,
          },
          fromQuestionId: question.id,
        },
      });
    },
    [dispatch]
  );

  const onClickDuplicationRadioQuestion = useCallback(
    (question: RADIO_QUESTION_FOR_FORM) => {
      dispatch({
        type: "DUPLICATION_QUESTION",
        payload: {
          question: {
            type: "RADIO",
            title: question.title,
            isRequired: question.isRequired,
            id: v4(),
            options: question.options.map((option) => ({
              ...option,
              id: v4(),
            })),
            disabledOptionType: false,
            isDirtyTitle: question.isDirtyTitle,
            isTitleError: question.isTitleError,
            titleHelperText: question.titleHelperText,
          },
          fromQuestionId: question.id,
        },
      });
    },
    [dispatch]
  );

  const onClickDuplicationCheckBoxQuestion = useCallback(
    (question: CHECK_BOX_QUESTION_FOR_FORM) => {
      dispatch({
        type: "DUPLICATION_QUESTION",
        payload: {
          question: {
            type: "CHECK_BOX",
            title: question.title,
            isRequired: question.isRequired,
            id: v4(),
            options: question.options.map((option) => ({
              ...option,
              id: v4(),
            })),
            allowFreeText: question.allowFreeText,
            disabledOptionType: false,
            isDirtyTitle: question.isDirtyTitle,
            isTitleError: question.isTitleError,
            titleHelperText: question.titleHelperText,
          },
          fromQuestionId: question.id,
        },
      });
    },
    [dispatch]
  );

  return {
    onClickDuplicationTextQuestion,
    onClickDuplicationFileQuestion,
    onClickDuplicationRadioQuestion,
    onClickDuplicationCheckBoxQuestion,
  };
};
