import { Employee, IndividualInvitationSetting, NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { useAddConditionModeModal } from "../_share_InvitationQR/AddConditionMode/useAddConditionModeModal";

import { useSelectCondition } from "../_share_InvitationQR/useSelectCondition";

import { useShowQRModeModal } from "./ShowQRMode/useShowQRModeModal";

import { useOnAddConditions } from "./useOnAddConditions";

import { Loading, ScrollableBodyModal } from "~/components/uiParts";
import { useIndividualInvitationSetting } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

/**
 * SHOW_QR: 個別QRを表示する画面
 * ADD_CONDITION: 条件付与画面
 */
type Mode = "SHOW_QR" | "ADD_CONDITION";

type Props = {
  open: boolean;
  onCancel: () => void;
  currentUser: Employee;
  newGraduate: NewGraduateToDisplayForAdmin;
  onSendInvitationNotification?: () => void | Promise<void>;
  onUpdateConditions?: () => void;
};

const useInviteNewGraduateModal = ({
  newGraduate,
  onCancel,
  individualInvitationSetting,
  onSendInvitationNotification,
  onUpdateConditions,
}: {
  newGraduate: NewGraduateToDisplayForAdmin;
  onCancel: () => void;
  individualInvitationSetting: IndividualInvitationSetting;
  onSendInvitationNotification?: () => void | Promise<void>;
  onUpdateConditions?: () => void;
}) => {
  // 個別QR表示画面と条件付与画面とで、付与された条件（イベントやタグなど）の状態を共有するため、
  // このようにステートを用いて画面遷移を行なっています
  const [mode, setMode] = useState<Mode>("SHOW_QR");
  const toAddConditionMode = () => setMode("ADD_CONDITION");
  const toShowQRMode = () => setMode("SHOW_QR");

  // NOTE: 共通QRの条件付与管理Hooks
  const {
    selectedCondition,
    addSelectedAuthenticationFlowTypes,
    removeSelectedAuthenticationFlowTypes,
    toggleScenarioSelection,
    changeSelectedRecruitmentStatusId,
    addSelectedOnnEventIds,
    removeSelectedOnnEventIds,
    addSelectedOnnTaskIds,
    removeSelectedOnnTaskIds,
    addSelectedTagIds,
    removeSelectedTagIds,
  } = useSelectCondition({
    defaultCondition: {
      authenticationFlowTypes: newGraduate.selectableAuthenticationFlowTypes.includes("email")
        ? ["line", "email"]
        : ["line"],
      scenarios: newGraduate.scenarios,
      tagIds: newGraduate.employeeTagIds,
      onnEventIds: individualInvitationSetting?.onnEventIds || [],
      onnTaskIds: individualInvitationSetting?.onnTaskIds || [],
    },
  });

  // NOTE: 条件付与画面での「条件を付与」ボタン押下時の処理
  const { onAddConditions, isUpdating } = useOnAddConditions({
    toShowQRMode,
    selectedCondition,
    newGraduate,
    individualInvitationSettingId: individualInvitationSetting.id,
    onUpdateConditions,
  });
  // NOTE: 条件付与画面用のモーダルプロパティ
  const addConditionModeModal = useAddConditionModeModal({
    selectedCondition,
    addSelectedAuthenticationFlowTypes,
    removeSelectedAuthenticationFlowTypes,
    toggleScenarioSelection,
    changeSelectedRecruitmentStatusId,
    addSelectedOnnEventIds,
    removeSelectedOnnEventIds,
    addSelectedOnnTaskIds,
    removeSelectedOnnTaskIds,
    addSelectedTagIds,
    removeSelectedTagIds,
    isLoadingFooterButton: isUpdating,
    onAddConditions,
    onCancelAddConditions: toShowQRMode,
    currentScenarios: newGraduate.scenarios,
  });

  const showQRModeModal = useShowQRModeModal({
    newGraduate,
    onCancel,
    toAddConditionMode,
    isAddedCondition: individualInvitationSetting.isEqualCreatedAtAndUpdatedAt(),
    onSendInvitationNotification,
  });

  if (mode === "ADD_CONDITION") {
    return { ...addConditionModeModal, isShowFooterDivider: true };
  }
  return {
    ...showQRModeModal,
    isShowFooterDivider: false,
  };
};
export const InviteNewGraduateModal: FC<Props> = (props) => {
  const { data: individualInvitationSetting, isLoading } = useIndividualInvitationSetting({
    employeeId: props.newGraduate.id,
  });
  const { enqueueSnackbar } = useSnackbar();

  if (isLoading) {
    return (
      <StyledModal
        open={true}
        content={<Loading size="large" fullHeight />}
        fullWidth
        disableBackdropModal
      />
    );
  }
  if (!individualInvitationSetting) {
    enqueueSnackbar("個別招待設定の取得に失敗しました。担当者までお問い合わせください。", {
      variant: "error",
    });
    captureException({
      error: new Error("個別招待設定（individualInvitationSetting）の取得に失敗しました。"),
      tags: { type: "InviteNewGraduateModal" },
      extras: {
        employeeId: props.newGraduate.id,
      },
    });
    props.onCancel();
    return <Loading fullHeight size="large" />;
  }

  return (
    <InviteNewGraduateModalCore
      {...{ ...props, individualInvitationSetting: individualInvitationSetting || null }}
    />
  );
};

export const InviteNewGraduateModalCore: FC<
  Props & { individualInvitationSetting: IndividualInvitationSetting }
> = ({
  open,
  onCancel,
  newGraduate,
  individualInvitationSetting,
  onSendInvitationNotification,
  onUpdateConditions,
}) => {
  const { title, content, footer, isShowFooterDivider } = useInviteNewGraduateModal({
    newGraduate,
    onCancel,
    individualInvitationSetting,
    onSendInvitationNotification,
    onUpdateConditions,
  });

  return (
    <StyledModal
      open={open}
      title={title}
      content={content}
      footer={footer}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
      isShowFooterDivider={isShowFooterDivider}
    />
  );
};

const StyledModal = styled(ScrollableBodyModal)`
  .MuiDialog-paper {
    padding-bottom: 24px;
    row-gap: 24px;
  }
  footer {
    margin-top: 24px;
  }
`;
