import { useCallback } from "react";

import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const useGenerateSettingCSV = () => {
  const { data: onnEventPlaces = [], isLoading: isLoadingPlaces } = useOnnEventPlaces();
  const generateSettingCSV = useCallback(() => {
    const length = onnEventPlaces.length || 0;
    return [
      ["「会場名」コピー欄"],
      ...Array.from({ length }).map((_, i) => [onnEventPlaces[i]?.name || ""]),
    ];
  }, [onnEventPlaces]);

  const isLoading = isLoadingPlaces;
  return { generateSettingCSV, isLoading };
};
