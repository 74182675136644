import { Box, Menu } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { EmployeeList, SearchForm } from "./parts";

import { Button, Divider, Icon, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  employees: Employee[];
  width?: string;
} & (
  | {
      selectedEmployees: Employee[];
      selectEmployees: (employees: Employee[]) => void;
      isMultiple: true;
    }
  | {
      selectedEmployee?: Employee;
      selectEmployee: (employee?: Employee) => void;
      isMultiple: false;
    }
);

const MultipleMenuText = ({ selectedEmployees }: { selectedEmployees: Employee[] }) => {
  const firstEmployee = selectedEmployees[0];
  if (!firstEmployee) {
    return <Typography variant="body2">メンバーを選択</Typography>;
  }

  if (selectedEmployees.length > 1) {
    return (
      <Typography variant="body2" noWrap>
        {selectedEmployees.length}名のメンバー
      </Typography>
    );
  }

  return (
    <>
      <UserIcon
        username={firstEmployee.getName()}
        profileIconImageUrl={firstEmployee.profileIconImageUrl}
        size="extraSmall"
        circular
      />
      <Typography variant="body2" noWrap>
        {firstEmployee.getName()}
      </Typography>
    </>
  );
};

const SingleMenuText = ({ selectedEmployee }: { selectedEmployee?: Employee }) => {
  if (!selectedEmployee) {
    return <Typography variant="body2">メンバーを選択</Typography>;
  }

  return (
    <>
      <UserIcon
        username={selectedEmployee.getName()}
        profileIconImageUrl={selectedEmployee.profileIconImageUrl}
        size="extraSmall"
        circular
      />
      <Typography variant="body2" noWrap>
        {selectedEmployee.getName()}
      </Typography>
    </>
  );
};

/**
 * employeeを検索・選択するためのセレクトメニュー
 *
 * - [ALERT] フロントエンド上での絞り込みとなるため、効率が悪い
 * - [NOTE] 絞り込みをNewGraduateSelectMenuWithSearchで行う場合、NewGraduateSelectMenuWithSearchを使用する
 */
export const SelectMenuWithSearch: FC<Props> = ({ width = "240px", employees, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { currentUser } = useCurrentUser();
  const employeesWithCurrentUserFirst = useMemo(() => {
    const employeesWithoutCurrentUser = employees.filter(
      (employee) => employee.id !== currentUser?.id
    );
    return [currentUser, ...employeesWithoutCurrentUser];
  }, [employees, currentUser]);
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(
    employeesWithCurrentUserFirst
  );

  return (
    <>
      <StyledBox $width={width}>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" alignItems="center" gridGap="4px">
            {props.isMultiple ? (
              <MultipleMenuText selectedEmployees={props.selectedEmployees} />
            ) : (
              <SingleMenuText selectedEmployee={props.selectedEmployee} />
            )}
            <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
          </Box>
        </Button>
      </StyledBox>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <Box>
          <Box p="16px">
            <SearchForm
              employees={employeesWithCurrentUserFirst}
              onSearch={(results) => setDisplayEmployees(results)}
            />
          </Box>
          <Divider />
          {props.isMultiple ? (
            <EmployeeList
              employees={displayEmployees}
              selectedEmployees={props.selectedEmployees}
              onSelect={(employees) => props.selectEmployees(employees)}
              isMultiple={props.isMultiple}
            />
          ) : (
            <EmployeeList
              employees={displayEmployees}
              selectedEmployee={props.selectedEmployee}
              onSelect={(employee) => {
                props.selectEmployee(employee);
                setAnchorEl(null);
              }}
              isMultiple={props.isMultiple}
            />
          )}
        </Box>
      </Menu>
    </>
  );
};

const StyledBox = styled(Box)<{ $width: string }>`
  width: ${(props) => props.$width};
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
