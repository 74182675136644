import { Box, ListItem } from "@material-ui/core";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { NewGraduateAlreadyInvitedChip } from "../NewGraduateAlreadyInvitedChip";

import { NewGraduateNotInvitedChip } from "../NewGraduateNotInvitedChip";

import {
  Checkbox,
  FormControlLabel,
  Icon,
  Tooltip,
  Typography,
  UserIcon,
} from "~/components/uiParts";

type RowProps = ListRowProps & {
  employees: NewGraduateToDisplayForAdmin[];
  selectedEmployees: NewGraduateToDisplayForAdmin[];
  onSelect: (employees: NewGraduateToDisplayForAdmin) => void;
};

export const Row = ({
  employees,
  selectedEmployees,
  onSelect,
  ...props
}: RowProps): JSX.Element => {
  const employee = employees[props.index] as (typeof employees)[number];

  return (
    <Box
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
    >
      <StyledListItem alignItems="flex-start">
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={selectedEmployees.includes(employee)}
              onChange={() => onSelect(employee)}
              name={employee.id}
              value={employee.id}
            />
          }
          label={
            <>
              <Box width="100%" display="flex" alignItems="center" gridGap="16px">
                <Box display="flex" alignItems="center" gridGap="8px" overflow="hidden">
                  <UserIcon
                    username={employee.getName()}
                    profileIconImageUrl={employee.profileIconImageUrl}
                    size="small"
                    circular
                  />
                  <Box>
                    <Box display="flex" alignItems="center" gridGap="8px">
                      <StyledBody1TypographyLineHeight1
                        variant="body1"
                        color="textPrimary"
                        bold
                        noWrap
                      >
                        {employee.getName()}
                      </StyledBody1TypographyLineHeight1>
                      {!employee.isRegistered() && employee.isInvited() && (
                        <NewGraduateAlreadyInvitedChip />
                      )}
                      {employee.isNotRegisteredAndInvited() && <NewGraduateNotInvitedChip />}
                    </Box>
                    <StyledTypography variant="caption" color="textSecondary" noWrap>
                      {employee.email}
                    </StyledTypography>
                  </Box>
                </Box>

                {employee.isSomeScenarioRejectedOrWithdrew() && (
                  <Tooltip
                    title="この候補者に設定されたいずれかのシナリオの選考ステータスが「不採用」または「辞退」になっています。招待を送る前に必ず確認を行ってください。"
                    placement="top-start"
                  >
                    <Icon icon="error" color="secondary" size="ssm" />
                  </Tooltip>
                )}
              </Box>
            </>
          }
        />
      </StyledListItem>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 16px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 11.5px 0px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;

const StyledBody1TypographyLineHeight1 = styled(Typography)`
  &.MuiTypography-body1 {
    line-height: 1;
  }
`;

const StyledListItem: typeof ListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding: 8px 0;
  }
`;
