import { RecruitmentStatus, Scenario, Space } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = ({ tenantId }: { tenantId: string }) => {
  return {
    endpoint: "/space_api/scenarios-and-recruitment-status",
    tenantId,
  } as const;
};
export const useSpacesAndScenariosAndRecruitmentStatuses = () => {
  const { currentUser } = useCurrentUser();
  const key = generateKey({
    tenantId: currentUser.tenantId,
  });

  return useSWR(key, async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);

    return response.data.map((d) => {
      return {
        space: new Space(d.space),
        scenariosAndRecruitmentStatuses: d.scenariosAndRecruitmentStatuses.map(
          ({ recruitmentStatuses, scenario }) => {
            return {
              scenario: new Scenario(scenario),
              recruitmentStatuses: recruitmentStatuses.map(
                (recruitmentStatus) => new RecruitmentStatus(recruitmentStatus)
              ),
            };
          }
        ),
      };
    });
  });
};
