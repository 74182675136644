import { z } from "zod";

import { AuthenticationTypeForNewGraduateSchema } from "../inputTypeSchemas/AuthenticationTypeForNewGraduateSchema";
import { AuthenticationTypeSchema } from "../inputTypeSchemas/AuthenticationTypeSchema";
import { LangTypeSchema } from "../inputTypeSchemas/LangTypeSchema";
import { RoleSchema } from "../inputTypeSchemas/RoleSchema";

/////////////////////////////////////////
// EMPLOYEE SCHEMA
/////////////////////////////////////////

export const EmployeeSchema = z.object({
  role: RoleSchema,
  selectableAuthenticationFlowTypes: AuthenticationTypeForNewGraduateSchema.array(),
  selectedAuthenticationFlowType: AuthenticationTypeForNewGraduateSchema,
  currentAuthenticationType: AuthenticationTypeSchema.nullish(),
  lang: LangTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string().nullish(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  invitedAt: z.date().nullish(),
  lastInvitedAt: z.date().nullish(),
  invitationToken: z.string(),
  accountCreateAt: z.string(),
  createdAt: z.date(),
  assignedAsNewcomer: z.boolean(),
  uid: z.string().nullish(),
  profileIconImageUrl: z.string().nullish(),
  supportMemberEmployeeIds: z.string().array(),
  deleted: z.boolean(),
  lastRefreshTime: z.date().nullish(),
  lastActiveTime: z.date().nullish(),
  isNewGraduate: z.boolean().nullish(),
  isFollowedLineOfficialAccount: z.boolean().nullish(),
  admin_memo: z.string().nullish(),
  uniqueId: z.string().nullish(),
  joinAt: z.string().nullish(),
  lineUserId: z.string().nullish(),
  mentorUserId: z.string().nullish(),
  deletedAt: z.date().nullish(),
});

export type Employee = z.infer<typeof EmployeeSchema>;

/////////////////////////////////////////
// EMPLOYEE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeOptionalDefaultsSchema = EmployeeSchema.merge(z.object({}));

export type EmployeeOptionalDefaults = z.infer<typeof EmployeeOptionalDefaultsSchema>;

export default EmployeeSchema;
