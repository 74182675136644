import { Box, Stack } from "@mui/material";

import {
  EmployeeInformationGroupWithFieldAndOptions,
  NewGraduateToDisplayForAdmin,
} from "@onn/common";
import React, { FC } from "react";

import { CustomInformationSettingTables } from "./CustomInformationSettingTables";
import { EmployeeInformationTable } from "./EmployeeInformationTable";

import {
  Icon,
  IconButton,
  Loading,
  Paper,
  Tooltip,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { useOpenEditEmployeeInformationGroupModal } from "~/hooks/employeeInformationGroup";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";
import { useEmployeeInformationValues } from "~/hooks/employeeInformationValue";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
};

export const InformationTab: FC<Props> = ({ newGraduate }) => {
  const { data: employeeInformation, isLoading } = useEmployeeInformation(newGraduate.id);
  const { data: exitingGroups, isLoading: isLoadingEmployeeInformationGroups } =
    useEmployeeInformationGroups();
  const { openEditEmployeeInformationGroupModal } = useOpenEditEmployeeInformationGroupModal(
    exitingGroups || []
  );
  const { currentUser } = useCurrentUser();

  return (
    <Box pt={5} pb={5}>
      <Stack rowGap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" bold color="textPrimary">
            候補者設定
          </Typography>
          {currentUser.isAdmin() && (
            <UncontrolledMenu
              renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
              menuItemOptions={[
                {
                  text: "グループ追加",
                  onClick: () => openEditEmployeeInformationGroupModal({ type: "add" }),
                },
                {
                  text: "グループ編集",
                  onClick: () => openEditEmployeeInformationGroupModal({ type: "edit" }),
                },
              ]}
            />
          )}
        </Stack>
        <Paper style={{ borderRadius: 10 }}>
          <Stack rowGap={4}>
            <Stack rowGap={2}>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <Typography variant="body2" bold color="textPrimary" style={{ lineHeight: "21px" }}>
                  基本情報
                </Typography>
                <Tooltip
                  title="基本情報の項目は、システムで固定されているため追加・編集・削除ができません。"
                  placement="top"
                >
                  <Box p="4px" height={24} display="flex" alignItems="center">
                    <Icon icon="help" size="sm" color="grey" />
                  </Box>
                </Tooltip>
              </Box>
              {isLoading ? (
                <Loading size="large" />
              ) : (
                <EmployeeInformationTable
                  newGraduate={newGraduate}
                  employeeInformation={employeeInformation}
                />
              )}
            </Stack>
            <EmployeeInformationSettingPage
              employeeId={newGraduate.id}
              exitingGroups={exitingGroups || []}
              isLoadingEmployeeInformationGroups={isLoadingEmployeeInformationGroups}
            />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};

export const EmployeeInformationSettingPage: FC<{
  employeeId: string;
  exitingGroups: Array<ExcludeMethods<EmployeeInformationGroupWithFieldAndOptions>>;
  isLoadingEmployeeInformationGroups: boolean;
}> = ({ employeeId, exitingGroups, isLoadingEmployeeInformationGroups }) => {
  const { data: values, isLoading: isLoadingEmployeeInformationValue } =
    useEmployeeInformationValues({
      employeeId,
    });

  return (
    <CustomInformationSettingTables
      groups={exitingGroups}
      values={values || []}
      employeeId={employeeId}
      isLoadingEmployeeInformationGroupsOrValues={
        isLoadingEmployeeInformationGroups || isLoadingEmployeeInformationValue
      }
    />
  );
};
