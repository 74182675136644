import { APISchema, OnnEventEvaluationDraft } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-evaluations/draft"]["GET"];

const generateKey = (arg: { tenantId: string; onnEventId: string; targetEmployeeId: string }) => {
  return {
    endpoint: "/api/onn-event-evaluations/draft",
    ...arg,
  } as const;
};

export const useOnnEventEvaluationDraft = ({
  onnEventId,
  targetEmployeeId,
}: {
  onnEventId: string;
  targetEmployeeId: string;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
      targetEmployeeId,
    }),
    async ({ endpoint, onnEventId, targetEmployeeId }) => {
      const query: Endpoint["query"] = { onnEventId, employeeId: targetEmployeeId };
      const response = await apiClient.get(endpoint, query);

      return response.data.onnEventEvaluationDraft
        ? new OnnEventEvaluationDraft(response.data.onnEventEvaluationDraft)
        : null;
    }
  );
};

export const useMutateOnnEventEvaluationDraft = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventEvaluationDraft = useCallback(
    async ({ onnEventId, targetEmployeeId }: { onnEventId: string; targetEmployeeId: string }) => {
      await mutate(generateKey({ tenantId: currentUser.tenantId, onnEventId, targetEmployeeId }));
    },
    [currentUser.tenantId]
  );

  return { mutateOnnEventEvaluationDraft };
};
