import { Employee, EmployeeInformation } from "@onn/common";
import { employeeInformationValueSchema } from "@onn/common/domain/EmployeeInformation/schema";
import { differenceInYears, format } from "date-fns";
import React, { FC, useCallback } from "react";

import { mutate } from "swr";
import { z } from "zod";

import { AddressEditor } from "./Address/AddressEditor";
import { AddressViewer } from "./Address/AddressViewer";
import { AffiliationEditor } from "./Affiliation/AffiliationEditor";
import { AffiliationViewer } from "./Affiliation/AffiliationViewer";
import { AttachmentFileEditor } from "./AttachmentFile/AttachmentFileEditor";
import { AttachmentFileViewer } from "./AttachmentFile/AttachmentFileViewer";
import { DateOfBirthEditor } from "./DateOfBirth/DateOfBirthEditor";
import { DateOfBirthViewer } from "./DateOfBirth/DateOfBirthViewer";
import { EmailEditor } from "./Email/EmailEditor";
import { EmailViewer } from "./Email/EmailViewer";
import { ExternalIdEditor } from "./ExternalId/ExternalIdEditor";
import { ExternalIdViewer } from "./ExternalId/ExternalIdViewer";
import { GenderEditor } from "./Gender/GenderEditor";
import { GenderViewer } from "./Gender/GenderViewer";
import { GraduationYearAndMonthViewer } from "./GraduationYearAndMonth/GraduationYearAndMonthViewer";
import { GraduationYearAndMonthEditor } from "./GraduationYearAndMonth/GraduationYearEditor";
import { HomePhoneNumberEditor } from "./HomePhoneNumber/HomePhoneNumberEditor";
import { HomePhoneNumberViewer } from "./HomePhoneNumber/HomePhoneNumberViewer";
import { KanaNameEditor } from "./KanaName/KanaNameEditor";
import { KanaNameViewer } from "./KanaName/KanaNameViewer";
import { NameEditor } from "./Name/NameEditor";
import { NameViewer } from "./Name/NameViewer";
import { OfferAcceptanceDeadlineEditor } from "./OfferAcceptanceDeadline/OfferAcceptanceDeadlineEditor";
import { OfferAcceptanceDeadlineViewer } from "./OfferAcceptanceDeadline/OfferAcceptanceDeadlineViewer";
import { PhoneNumberEditor } from "./PhoneNumber/PhoneNumberEditor";
import { PhoneNumberViewer } from "./PhoneNumber/PhoneNumberViewer";
import { ViewerAndEditorManager } from "./ViewerAndEditorManager";

import { generateUseNewGraduateKey } from "~/hooks/employee";
import { useMutateAllNewcomers } from "~/hooks/employee/useAllNewcomers";
import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { useGenerateMutateNewGraduate } from "~/hooks/employee/useNewGraduate";
import { useUpdateEmployeeEmail } from "~/hooks/employee/useUpdateEmployeeEmail/useUpdateEmployeeEmail";
import { useUpdateEmployeeInformation } from "~/hooks/employeeInformation";
import { mutateEmployeeInformation } from "~/hooks/employeeInformation/useEmployeeInformation";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

type Props = { employeeId: string; text: string } & (
  | {
      type: "name";
      data?: Employee;
    }
  | {
      type: "kanaName";
      data?: EmployeeInformation["value"]["kanaName"];
    }
  | {
      type: "gender";
      data?: EmployeeInformation["value"]["gender"];
    }
  | {
      type: "dateOfBirth";
      data?: EmployeeInformation["value"]["dateOfBirth"];
    }
  | {
      type: "email";
      data?: Employee["email"];
    }
  | {
      type: "phoneNumber";
      data?: EmployeeInformation["value"]["phoneNumber"];
    }
  | {
      type: "homePhoneNumber";
      data?: EmployeeInformation["value"]["homePhoneNumber"];
    }
  | {
      type: "affiliation";
      data?: EmployeeInformation["value"]["affiliation"];
    }
  | {
      type: "address";
      data?: EmployeeInformation["value"]["address"];
    }
  | {
      type: "hometownAddress";
      data?: EmployeeInformation["value"]["hometownAddress"];
    }
  | {
      type: "graduationYearAndMonth";
      data?: EmployeeInformation["value"]["graduationYearAndMonth"];
    }
  | {
      type: "externalId";
      data?: EmployeeInformation["value"]["externalId"];
    }
  | {
      type: "resumeFilePaths";
      data?: EmployeeInformation["value"]["resumeFilePaths"];
    }
  | {
      type: "entrySheetFilePaths";
      data?: EmployeeInformation["value"]["entrySheetFilePaths"];
    }
  | {
      type: "spiFilePaths";
      data?: EmployeeInformation["value"]["spiFilePaths"];
    }
  | {
      type: "offerAcceptanceDeadline";
      data?: EmployeeInformation["value"]["offerAcceptanceDeadline"];
    }
);

export const ViewerAndEditorManagerRenderer: FC<Props> = ({ employeeId, type, text, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { execUpdateEmployeeInformation } = useUpdateEmployeeInformation();
  const { execUpdateEmployeeEmail } = useUpdateEmployeeEmail();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();

  const handleSubmitForName = useCallback(
    async (data: { lastName?: string; firstName?: string }) => {
      await EmployeeUseCase.update(employeeId, {
        lastName: data.lastName,
        firstName: data.firstName,
      })
        .then(async () => {
          enqueueSnackbar("氏名を変更しました", { variant: "success" });
          mutate(generateUseEmployeeKeys(employeeId));
          mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
          mutateAllNewcomers();
        })
        .catch((e) => {
          enqueueSnackbar("氏名の変更に失敗しました", { variant: "error" });
          // NOTE: EmployeeUseCase.updateはフロントでデータの更新をしているので例外的にSentryにエラーを送信する
          captureException({
            error: e as Error,
            tags: { type: "ViewerAndEditorManagerRenderer:handleSubmitForName" },
          });
        });
    },
    [employeeId, enqueueSnackbar, mutateAllNewcomers]
  );

  const handleSubmitForEmployeeInformation = useCallback(
    async (data: z.infer<typeof employeeInformationValueSchema>) => {
      await execUpdateEmployeeInformation({
        employeeId,
        value: data,
      });
      mutateEmployeeInformation(employeeId);
      enqueueSnackbar(`${text}が更新されました`, { variant: "success" });
    },
    [employeeId, enqueueSnackbar, execUpdateEmployeeInformation, text]
  );

  // メールアドレスの更新
  const handleSubmitForEmployeeEmail = useCallback(
    async (data: { email: string }) => {
      try {
        await execUpdateEmployeeEmail({
          employeeId,
          value: { email: data.email },
        });
        await generateMutateNewGraduate(employeeId)();
        enqueueSnackbar(`${text}が更新されました`, { variant: "success" });
      } catch (e) {
        enqueueSnackbar(`${(e as Error).message}`, { variant: "error" });
      }
    },
    [employeeId, enqueueSnackbar, execUpdateEmployeeEmail, text, generateMutateNewGraduate]
  );

  switch (type) {
    case "name": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <NameViewer
              value={data?.getName()}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data?.getName(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <NameEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForName(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "kanaName":
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <KanaNameViewer
              value={data?.getFullKanaName()}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data.getFullKanaName(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <KanaNameEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation({ kanaName: data });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    case "gender":
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <GenderViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data, text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <GenderEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    case "dateOfBirth": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <DateOfBirthViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                data
                  ? () =>
                      onClickCopy(
                        format(data, `yyyy年MM月dd日  満${differenceInYears(new Date(), data)}歳`),
                        text
                      )
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <DateOfBirthEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "email": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <EmailViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data, text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <EmailEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeEmail(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "phoneNumber": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <PhoneNumberViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data, text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <PhoneNumberEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "homePhoneNumber": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <HomePhoneNumberViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data, text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <HomePhoneNumberEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "affiliation": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <AffiliationViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data.getFullText(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AffiliationEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation({ affiliation: data });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "address": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <AddressViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data.getFullAddress(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AddressEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation({ address: data });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "hometownAddress": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <AddressViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data.getFullAddress(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AddressEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation({ hometownAddress: data });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "graduationYearAndMonth": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <GraduationYearAndMonthViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data.getFullText(), text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <GraduationYearAndMonthEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation({ graduationYearAndMonth: data });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "externalId": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <ExternalIdViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={data ? () => onClickCopy(data, text) : undefined}
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <ExternalIdEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }

    case "resumeFilePaths": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit }) => (
            <AttachmentFileViewer value={data} onChangeModeToEdit={onChangeModeToEdit} />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AttachmentFileEditor
              data={data}
              onCancel={onCancel}
              onSave={({ filePaths }) => {
                handleSubmitForEmployeeInformation({ resumeFilePaths: filePaths });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }

    case "entrySheetFilePaths": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit }) => (
            <AttachmentFileViewer value={data} onChangeModeToEdit={onChangeModeToEdit} />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AttachmentFileEditor
              data={data}
              onCancel={onCancel}
              onSave={({ filePaths }) => {
                handleSubmitForEmployeeInformation({ entrySheetFilePaths: filePaths });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }

    case "spiFilePaths": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit }) => (
            <AttachmentFileViewer value={data} onChangeModeToEdit={onChangeModeToEdit} />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <AttachmentFileEditor
              data={data}
              onCancel={onCancel}
              onSave={({ filePaths }) => {
                handleSubmitForEmployeeInformation({ spiFilePaths: filePaths });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }

    case "offerAcceptanceDeadline": {
      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <OfferAcceptanceDeadlineViewer
              value={data}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                data ? () => onClickCopy(format(data, `yyyy年MM月dd日`), text) : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <OfferAcceptanceDeadlineEditor
              data={data}
              onCancel={onCancel}
              onSave={(data) => {
                handleSubmitForEmployeeInformation(data);
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }

    default:
      break;
  }
};
