import { zodResolver } from "@hookform/resolvers/zod";
import { NewGraduateToDisplayForAdmin, RecruitmentStatus, Scenario } from "@onn/common";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  scenarioId: z.string(),
});

export const useDisableScenarioToEmployeeForm = ({
  newGraduate,
  scenariosWithRecruitmentStatuses,
  onSubmit,
}: {
  newGraduate: NewGraduateToDisplayForAdmin;
  scenariosWithRecruitmentStatuses: {
    scenario: Scenario;
    recruitmentStatuses: RecruitmentStatus[];
  }[];

  onSubmit: (args: { scenarioId: string }) => Promise<void>;
}) => {
  const form = useForm<{
    scenarioId: string;
  }>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const scenarioOptions = useMemo(() => {
    return scenariosWithRecruitmentStatuses
      .filter(({ scenario }) =>
        // 候補者に設定しているシナリオが選択肢となる
        newGraduate.scenarios.find(
          (scenarioOfNewGraduate) => scenario.id === scenarioOfNewGraduate.scenarioId
        )
      )
      .map(({ scenario }) => {
        return {
          value: scenario.id,
          name: scenario.name,
        };
      });
  }, [newGraduate.scenarios, scenariosWithRecruitmentStatuses]);

  return {
    ...form,
    scenarioOptions,
    handleSubmit: form.handleSubmit(async ({ scenarioId }) => {
      await onSubmit({ scenarioId });
    }),
  };
};
