import { z } from "zod";

import { ContactRoomTypeSchema } from "../inputTypeSchemas/ContactRoomTypeSchema";
import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// CONTACT ROOM SCHEMA
/////////////////////////////////////////

export const ContactRoomSchema = z.object({
  type: ContactRoomTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string().nullish(),
  employeeId: z.string(),
  title: z.string(),
  readLogs: JsonValueSchema.array(),
  targetId: z.string().nullish(),
  isClosed: z.boolean(),
  createdEmployeeId: z.string().nullish(),
  updatedEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  messageUpdatedAt: z.date(),
});

export type ContactRoom = z.infer<typeof ContactRoomSchema>;

/////////////////////////////////////////
// CONTACT ROOM OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ContactRoomOptionalDefaultsSchema = ContactRoomSchema.merge(
  z.object({
    isClosed: z.boolean().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    messageUpdatedAt: z.date().optional(),
  })
);

export type ContactRoomOptionalDefaults = z.infer<typeof ContactRoomOptionalDefaultsSchema>;

export default ContactRoomSchema;
