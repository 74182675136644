import { AllContactRoom } from "@onn/common";
import { isAfter, isEqual } from "date-fns";
import { useCallback } from "react";

import { useContactRoomPins } from "~/hooks/contactRoomPin/useContactRoomPins";

export const useSortContactRoomsV2 = () => {
  const { data: contactRoomPins = [] } = useContactRoomPins();

  /**
   * ContactRoomに紐づく最新メッセージの作成日時でソートする関数
   * - メッセージが存在する場合: 最新メッセージの作成日時が現在と近いものから降順
   *
   * @param {AllContactRoom} contactRooms
   */
  const sortContactRooms = useCallback(
    (contactRooms: AllContactRoom[]) => {
      const sortBySecond = (contactRoomA: AllContactRoom, contactRoomB: AllContactRoom) => {
        if (contactRoomA.id < contactRoomB.id) return 1;
        if (contactRoomA.id > contactRoomB.id) return -1;
        return 0;
      };

      return contactRooms.sort((a, b) => {
        // ピン留めしているものを上に表示する
        const aIsPinned = contactRoomPins.some((v) => v.contactRoomId === a.id);
        const bIsPinned = contactRoomPins.some((v) => v.contactRoomId === b.id);
        if (aIsPinned && !bIsPinned) return -1;
        if (!aIsPinned && bIsPinned) return 1;

        if (isEqual(a.messageUpdatedAt, b.messageUpdatedAt)) {
          return sortBySecond(a, b);
        }

        // 日時が現在と近いものを上にするように
        return isAfter(a.messageUpdatedAt, b.messageUpdatedAt) ? -1 : 1;
      });
    },
    [contactRoomPins]
  );

  return { sortContactRooms };
};
